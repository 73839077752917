import type { SharedUser } from '../components/ShareReport/ShareReport';
import {
  CHANGE_FILTER,
  RESET_FILTERS,
  RESET_TAB_FILTERS,
  SET_INITIAL_FILTER_STATE,
  UPDATE_USERS_LIST,
} from '../constants/reducers';

export type SharingType = 'my-shares' | 'shared-with-me';

export interface FilterState {
  report_name: string;
  report_type: string[];
  start_date: Date[];
  end_date: Date[];
  user_name: string[];
  status: string[];
  sharing?: SharingType;
}

export interface ReportFilterState {
  users: SharedUser[];
  tabs: {
    [key: string]: FilterState;
  };
}

export type UpdateUsersList = {
  type: typeof UPDATE_USERS_LIST;
  users: SharedUser[];
};

export type ChangeFilter<T extends keyof FilterState> = {
  type: typeof CHANGE_FILTER;
  tab: string;
  option: keyof FilterState;
  value: FilterState[T];
};

export interface SetTabInitialState {
  type: typeof SET_INITIAL_FILTER_STATE;
  tab: string;
  initialState: FilterState;
}

export interface ResetFilters {
  type: typeof RESET_FILTERS;
}

export interface ResetTabFilters {
  type: typeof RESET_TAB_FILTERS;
  tab: string;
}

export type OptionAction =
  | ChangeFilter<keyof FilterState>
  | SetTabInitialState
  | ResetFilters
  | ResetTabFilters
  | UpdateUsersList;

export const tabInitialState: FilterState = {
  report_name: '',
  report_type: [],
  end_date: [],
  start_date: [],
  user_name: [],
  status: [],
};

export const reportFilterInitialState: ReportFilterState = {
  users: [],
  tabs: {
    'my-reports': tabInitialState,
    favourites: tabInitialState,
    scheduled: tabInitialState,
    shared: { ...tabInitialState, sharing: 'my-shares' },
    archived: tabInitialState,
  },
};

export const reportFilterReducer = (
  state: ReportFilterState,
  action: OptionAction
): ReportFilterState => {
  switch (action.type) {
    case CHANGE_FILTER: {
      const tabs = {
        ...state.tabs,
        [action.tab]: {
          ...state.tabs[action.tab],
          [action.option]: action.value,
        },
      };
      return { ...state, tabs };
    }

    case RESET_FILTERS:
      return {
        ...state,
        tabs: reportFilterInitialState.tabs,
      };

    case RESET_TAB_FILTERS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.tab]: reportFilterInitialState.tabs[action.tab],
        },
      };

    case SET_INITIAL_FILTER_STATE:
      return {
        ...state,
        tabs: { ...state.tabs, [action.tab]: action.initialState },
      };

    case UPDATE_USERS_LIST:
      return { ...state, users: action.users };
  }
};
