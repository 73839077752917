import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { InlineNotification, InlineLoading } from '@carbon/react';
import { AppContext } from '../../providers/AppProvider';
import { isRuleSetValid } from '../../utils/RuleValidation';
import {
  MIN_REQUIREMENTS_MET,
  MIN_REQUIREMENTS_TITLE,
} from '../../constants/messages';

interface ReportValidationProps {
  minRequirements: RuleSet | null | undefined;
}

const ReportValidation: FunctionComponent<ReportValidationProps> = ({
  minRequirements,
}) => {
  const { reportParameters } = useContext(AppContext);
  const isValid =
    minRequirements && isRuleSetValid(minRequirements, reportParameters);

  return (
    <>
      {minRequirements ? (
        <InlineNotification
          title={!isValid ? MIN_REQUIREMENTS_TITLE : ''}
          subtitle={isValid ? MIN_REQUIREMENTS_MET : ''}
          kind={isValid ? 'success' : 'warning'}
          hideCloseButton
          lowContrast
          style={{ lineHeight: '20px' }}
        >
          {!isValid && (
            <div
              dangerouslySetInnerHTML={{
                __html: String(minRequirements?.requirements),
              }}
            />
          )}
        </InlineNotification>
      ) : (
        <InlineLoading description="Loading minimum requirements..." />
      )}
    </>
  );
};

export default ReportValidation;
