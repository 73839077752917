import type { FunctionComponent } from 'react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { ChevronDown } from '@carbon/icons-react';

interface CellDropdownProps {
  selectedValue: string;
  options: string[] | EditableDropdownOption[];
  editable: boolean;
  onSelectValue: (newSelection: string) => void;
}

const CellDropdown: FunctionComponent<CellDropdownProps> = ({
  selectedValue,
  options,
  editable,
  onSelectValue,
}) => {
  return (
    <div className="flex align-center justify-space-between">
      {selectedValue}
      {editable && (
        <OverflowMenu
          flipped
          renderIcon={ChevronDown}
          size="sm"
          data-testid="cell-dropdown-menu"
          ariaLabel="Dropdown menu"
        >
          {options.map((option) => {
            const value = typeof option === 'string' ? option : option.value;

            return (
              <OverflowMenuItem
                key={value}
                itemText={value}
                onClick={() => onSelectValue(option)}
              />
            );
          })}
        </OverflowMenu>
      )}
    </div>
  );
};

export default CellDropdown;
