import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../providers/AppProvider';
import { ProductFruits } from 'react-product-fruits';
import { DateTime } from 'luxon';
import { getProductFruitsHMAC } from '../utils/appSetup';
import { useAuth0 } from '@auth0/auth0-react';
import usePosthog from '../utils/posthog';
import { productFruitsTokenError } from '../constants/posthog';
import {
  getBannerNameById,
  getFormattedUserRole,
  getFormattedUserType,
} from '../utils/adminPanelUtils';

interface ProductFruitsHMAC {
  hash: string;
  expiration: string;
}

interface ProductFruitsContainerProps {
  setProductFruitsUserData: (encodedData: string | null) => void;
}

const ProductFruitsContainer: FunctionComponent<
  ProductFruitsContainerProps
> = ({ setProductFruitsUserData }) => {
  const { user, bannerId, bannersAndUserGroups } = useContext(AppContext);
  const [hmac, setHmac] = useState<ProductFruitsHMAC | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const [timeToRefresh, setTimeToRefresh] = useState(0);
  const posthogEvent = usePosthog();
  const workspaceCode = process.env.REACT_APP_PRODUCT_FRUITS_KEY;
  const ONE_MINUTE_IN_MS = 60000;

  const getHMACData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const hmacData = await getProductFruitsHMAC(accessToken);
      if (hmacData) {
        const { token, expires } = hmacData;
        setHmac({
          hash: token,
          expiration: expires,
        });
        const userData = {
          username: user?.name,
          hmac: {
            hash: token,
            expiration: expires,
          },
        };
        const encodedData = encodeURIComponent(JSON.stringify(userData));
        setProductFruitsUserData(encodedData);
      }
    } catch (error) {
      setProductFruitsUserData(null);
      posthogEvent(productFruitsTokenError);
    }
  };

  useEffect(() => {
    let interval;
    if (hmac?.expiration) {
      const expiringInMS =
        DateTime.fromJSDate(new Date(hmac.expiration)).toMillis() -
        DateTime.fromMillis(Date.now()).toMillis();

      interval = setInterval(
        () => setTimeToRefresh(expiringInMS),
        ONE_MINUTE_IN_MS
      );
    }

    return () => clearInterval(interval);
  }, [timeToRefresh, hmac?.expiration]);

  useEffect(() => {
    if (hmac) {
      const currentTime = DateTime.now().toMillis();
      const expiry = DateTime.fromJSDate(new Date(hmac.expiration)).toMillis();
      if (expiry < currentTime - ONE_MINUTE_IN_MS) {
        getHMACData();
      }
    }
  }, [timeToRefresh, hmac]);

  useEffect(() => {
    if (!hmac && user?.name) {
      getHMACData();
    }
  }, [hmac, user?.name]);

  return user && workspaceCode && hmac ? (
    <ProductFruits
      workspaceCode={workspaceCode}
      language="en"
      user={{
        username: user.name,
        hmac,
        props: {
          user_type: user.user_type ? getFormattedUserType(user.user_type) : '',
          user_role: user.user_role ? getFormattedUserRole(user.user_role) : '',
          banner: bannerId
            ? getBannerNameById(bannerId, bannersAndUserGroups) ?? ''
            : '',
          job_title: user.job_title ?? '',
          department: user.department ?? '',
          supplier_name: user.supplier_name ?? '',
        },
      }}
    />
  ) : null;
};

export default ProductFruitsContainer;
