import type { FunctionComponent } from 'react';
import { formatNumber } from '../../utils/reportUtils';
import { getIndicatorStyleFromIndex, getTextStyleFromIndex } from '../../utils';
import Indicator from '../Cards/Indicator';

interface TreeNodeProps {
  node: TreeChartData;
}

const TreeNode: FunctionComponent<TreeNodeProps> = ({ node }) => {
  const { index, title, headline, body, indicator, benchmarkIndex } = node;

  return (
    <div className="tree-node" data-testid={`tree-node-${node.id}`}>
      <div className="node-container">
        <div className="node-interior">
          <div className="node-headline">
            <div className="node-title-label">
              <span>{title}</span>
              {benchmarkIndex && (
                <div
                  className="node-benchmark-indicator"
                  style={{
                    background: getIndicatorStyleFromIndex(benchmarkIndex),
                  }}
                />
              )}
            </div>
            <div className="node-body">
              <div className="node-title-value">{formatNumber(headline)}</div>
              {indicator && index && (
                <Indicator
                  value={indicator.value}
                  format={indicator.format}
                  index={index}
                  showArrow={true}
                  size="lg"
                />
              )}
            </div>
          </div>
          {body && (
            <div
              className="node-body"
              data-testid={`tree-node-body-${node.id}`}
              style={getTextStyleFromIndex(index)}
            >
              <div>{formatNumber(body as NumberPoint)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeNode;
