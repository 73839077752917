import type { FunctionComponent, ReactNode } from 'react';
import Tooltip from '../Tooltip';

interface SidePanelSectionProps {
  sectionHeader: string;
  sectionDescription?: string;
  showDivider?: boolean;
  children?: ReactNode | ReactNode[];
}

const SidePanelSection: FunctionComponent<SidePanelSectionProps> = ({
  sectionHeader,
  sectionDescription,
  showDivider = true,
  children,
}) => {
  return (
    <>
      {sectionDescription ? (
        <Tooltip label={sectionDescription} align="top" useButtonWrapper>
          <div className="SidePanel__title">{sectionHeader}</div>
        </Tooltip>
      ) : (
        <div className="SidePanel__title">{sectionHeader}</div>
      )}

      <div className="SidePanelSection__content">{children}</div>
      {showDivider && (
        <div
          data-testid="side-panel-section-divider"
          className="SidePanelSection__divider"
        />
      )}
    </>
  );
};

export default SidePanelSection;
