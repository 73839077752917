export enum WebsocketAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Refresh = 'REFRESH',
  Update = 'UPDATE',
  Notify = 'NOTIFY',
}

export interface GenericWebsocketMessage {
  readonly action: WebsocketAction;
  readonly data?: Record<string, unknown>;
}
