import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import type {
  FilterState,
  ReportFilterState,
} from '../reducers/ReportFilterReducer';
import {
  reportFilterInitialState,
  reportFilterReducer,
} from '../reducers/ReportFilterReducer';
import {
  CHANGE_FILTER,
  RESET_FILTERS,
  RESET_TAB_FILTERS,
  SET_INITIAL_FILTER_STATE,
  UPDATE_USERS_LIST,
} from '../constants/reducers';
import type { SharedUser } from '../components/ShareReport/ShareReport';

interface ReportFilterProviderProps {
  children: ReactNode;
}

export interface ReportFilterContextType {
  tabs: ReportFilterState['tabs'];
  users: ReportFilterState['users'];
  changeTabFilter: <T extends keyof FilterState>(
    tab: string,
    option: T,
    value: FilterState[T]
  ) => void;
  setTabInitialState: (tab: string, initialState: FilterState) => void;
  resetFilters: () => void;
  resetTabFilters: (tab: string) => void;
  updateUserList: (users: SharedUser[]) => void;
}

export const ReportFilterContext = createContext<ReportFilterContextType>(
  {} as ReportFilterContextType
);

const ReportFilterProvider: FunctionComponent<ReportFilterProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    reportFilterReducer,
    reportFilterInitialState
  );

  const changeTabFilter = <T extends keyof FilterState>(
    tab: string,
    option: T,
    value: FilterState[T]
  ) => {
    dispatch({
      type: CHANGE_FILTER,
      tab,
      value,
      option,
    });
  };

  const setTabInitialState = (tab: string, initialState: FilterState) => {
    dispatch({
      type: SET_INITIAL_FILTER_STATE,
      tab,
      initialState,
    });
  };

  const resetFilters = (): void => {
    dispatch({ type: RESET_FILTERS });
  };

  const resetTabFilters = (tab: string): void => {
    dispatch({
      type: RESET_TAB_FILTERS,
      tab,
    });
  };

  const updateUserList = (users: SharedUser[]) => {
    dispatch({
      type: UPDATE_USERS_LIST,
      users,
    });
  };

  const initialReportFilterContext: ReportFilterContextType = useMemo(() => {
    const { tabs, users } = state;
    return {
      tabs,
      users,
      changeTabFilter,
      setTabInitialState,
      resetFilters,
      resetTabFilters,
      updateUserList,
    };
  }, [state]);

  return (
    <ReportFilterContext.Provider value={initialReportFilterContext}>
      {children}
    </ReportFilterContext.Provider>
  );
};

export default ReportFilterProvider;
