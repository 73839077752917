import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button } from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import { AppContext } from '../../providers/AppProvider';
import { getScheduleReportOptions } from '../ReportBuilder/utils/reportBuilderUtils';
import { CACHE_KEY, type ReportConfigCacheKey } from '../../constants/api';
import { formatUTCDate } from '../../utils/DateUtils';
import Fetch from '../Fetch';
import { StatusCell } from './StatusCell';
import type { TableRow } from './ReportTable';
import type { REPORT_STATUS } from '../Workspace';
import type { DeleteHandlerParameters } from './MyReportsTable';

interface ExpandedRowContentProps {
  row: CarbonSelectedRow;
  isDeleting: boolean;
  isAuthor: boolean;
  hasId?: (rowId: string) => boolean;
  handleRowClick: (row: TableRow) => void;
  handleStatusTooltip: (status: string) => string | undefined;
  handleDelete?: (parameters: DeleteHandlerParameters) => void;
  getStatusText: () => string;
}

interface ScheduledReport {
  id: string;
  report_name: string;
  end_time: string;
  status: string;
}

const ExpandedRowContent: FunctionComponent<ExpandedRowContentProps> = ({
  row,
  isDeleting,
  isAuthor,
  hasId,
  handleStatusTooltip,
  handleRowClick,
  handleDelete,
  getStatusText,
}) => {
  const { bannerId, groupId, clearCacheForKey } = useContext(AppContext);
  const [scheduleOptions, setScheduleOptions] = useState<
    ScheduleReportOptions | undefined
  >();
  const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>(
    []
  );
  const cacheKey: ReportConfigCacheKey = `${CACHE_KEY.REPORT}-${row.id}`;

  const removeReportCache = () => {
    clearCacheForKey(cacheKey);
  };

  useEffect(() => {
    return () => {
      removeReportCache();
    };
  }, []);

  const toRowFormat = (scheduledReport: ScheduledReport) => ({
    id: scheduledReport.id,
    cells: [
      { id: 'report_name', value: scheduledReport.report_name },
      { id: 'status', value: scheduledReport.status },
    ],
  });

  if (!row.isExpanded) {
    return null;
  }

  return (
    <Fetch
      apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?run_id=${row.id}`}
      cacheKey={cacheKey}
      initialData={null}
      loadingMessage="Loading report metadata..."
      hideChildrenUntilFetched
      alwaysFetchOnMount={!scheduleOptions && scheduledReports.length === 0}
      onReceiveData={(data) => {
        if (data) {
          setScheduleOptions(
            getScheduleReportOptions(data[0].schedule_info?.schedule)
          );
          if (data[0].schedule_history) {
            setScheduledReports(
              data[0].schedule_history.map((report) => {
                const endDate = formatUTCDate(report.end_time, false);
                return {
                  id: report.run_id,
                  report_name: report.report_name,
                  end_time: report.end_time ? endDate : 'Pending',
                  status: report.status,
                };
              })
            );
          }
        }
      }}
    >
      <div className="expanded-row-container">
        {scheduledReports.length > 0 && (
          <div className="scheduled-reports-container">
            <div>
              <strong>Previously executed reports:</strong>
            </div>
            <div className="report-list">
              {scheduledReports.map((report, index) => (
                <div className="report-row" key={report.id}>
                  <div>
                    <span
                      onClick={() =>
                        handleRowClick(toRowFormat(report) as TableRow)
                      }
                    >
                      {report.report_name}
                    </span>
                  </div>
                  <div>{report.end_time}</div>
                  <div className="status-cell">
                    <StatusCell
                      row={{ id: report.id } as CarbonSelectedRow}
                      cell={
                        { value: report.status } as CarbonCell<REPORT_STATUS>
                      }
                      handleStatusTooltip={handleStatusTooltip}
                      hasId={hasId}
                      getStatusText={getStatusText}
                    />
                  </div>
                  <div>
                    {isAuthor && (
                      <Button
                        kind="ghost"
                        data-testid={`delete-${report.id}`}
                        disabled={isDeleting}
                        onClick={() =>
                          handleDelete?.({
                            reports: [toRowFormat(report) as CarbonSelectedRow],
                            callback: removeReportCache,
                            isFirstHistoricScheduledReport:
                              index + 1 === scheduledReports.length,
                          })
                        }
                      >
                        <TrashCan size={14} />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!scheduleOptions && scheduledReports.length === 0 && (
          <div>
            There are no historical reports for this schedule. This may be
            because the scheduled report was cancelled.
          </div>
        )}
      </div>
    </Fetch>
  );
};

export default ExpandedRowContent;
