import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, ModalHeader, ModalBody } from '@carbon/react';
import { Column as ColumnIcon, View, ViewOff, Undo } from '@carbon/icons-react';
import IconTitle from './IconTitle';
import '../styles/components/columnOrder.scss';
import type { DraggableItemType, List } from './ItemOrder/ItemOrder';
import ItemOrder from './ItemOrder/ItemOrder';
import { ModalContext } from '../providers/ModalProvider';
import { MODAL_ID } from './Modal';
import InfoOverlay from './InfoOverlay';
import { REPORT_OUTPUT_CHARACTER_LIMIT } from '../constants/values';

interface Column extends DraggableItemType {
  visible?: boolean;
}

interface ColumnOrderProps {
  columns: Column[];
  frozenColumns: Column[];
  isButtonsHidden?: boolean;
  updateVisibleColumns: (columns: number[]) => void;
}

const VISIBLE_COLUMNS = 'visible-columns';
const HIDDEN_COLUMNS = 'hidden-columns';
const getInitialLists = (columns: Column[]): List[] => {
  return [
    {
      title: 'Visible columns',
      listId: VISIBLE_COLUMNS,
      icon: <View size={20} />,
      kind: 'primary',
      items: columns.filter((c) => c.visible),
      searchable: false,
      dropDescription: 'Drop here to make column visible',
    },
    {
      title: 'Hidden columns',
      listId: HIDDEN_COLUMNS,
      icon: <ViewOff size={20} />,
      kind: 'secondary',
      items: columns.filter((c) => !c.visible),
      searchable: true,
      dropDescription: 'Drop here to hide column',
    },
  ];
};

export const getInitialSearchState = (
  lists: List[]
): { [key: string]: string } => {
  const searchState = {};
  lists.forEach((list) => {
    searchState[list.listId] = '';
  });
  return searchState;
};

const ColumnOrder: FunctionComponent<ColumnOrderProps> = ({
  columns,
  frozenColumns,
  isButtonsHidden,
  updateVisibleColumns,
}) => {
  const { toggleModal, open } = useContext(ModalContext);
  const [lists, setLists] = useState<List[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [searchState, setSearchState] = useState(getInitialSearchState(lists));

  const resetSearch = () => setSearchState(getInitialSearchState(lists));

  const frozenColumnList = frozenColumns.map((h) =>
    h.name.length > REPORT_OUTPUT_CHARACTER_LIMIT
      ? h.name.substring(0, 30) + '...'
      : h.name
  );

  useEffect(() => {
    setLists(getInitialLists(columns));
  }, [columns]);

  useEffect(() => {
    if (lists.length > 0) {
      setUnsavedChanges(
        JSON.stringify(lists[0].items) !==
          JSON.stringify(getInitialLists(columns)[0].items)
      );
    }
  }, [lists]);

  useEffect(() => {
    resetSearch();
  }, [open]);

  return (
    <>
      <ModalHeader
        buttonOnClick={() => {
          toggleModal(false);
        }}
      >
        <div className="column-order-header">
          <IconTitle
            icon={<ColumnIcon size={16} />}
            title="Configure Table Columns"
            description="Drag and drop between the “Visible Columns” and “Hidden Column” panel to configure the columns on the data table."
            kind="primary"
            titleSize="02"
          />
        </div>

        {frozenColumns.length > 0 && (
          <InfoOverlay
            title="See fixed columns"
            overlayContent={frozenColumnList}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <ItemOrder
          lists={lists}
          searchState={searchState}
          onListUpdate={(lists: List[]) => setLists(lists)}
          onUpdateSearch={setSearchState}
          moveAll={{
            label: 'Show all columns',
            description: 'Make all hidden columns visible',
          }}
          portalId={MODAL_ID}
        />
      </ModalBody>
      {!isButtonsHidden && (
        <div className="column-order-actions">
          <Button
            renderIcon={Undo}
            kind="ghost"
            disabled={!unsavedChanges}
            onClick={() => {
              setLists(getInitialLists(columns));
              resetSearch();
              setUnsavedChanges(false);
            }}
          >
            Reset changes
          </Button>
          <Button
            onClick={() => {
              const visibleColumns = lists
                .find((l) => l.listId === VISIBLE_COLUMNS)
                ?.items.map((i) => i.id);

              if (visibleColumns) {
                updateVisibleColumns(visibleColumns);
              }
              toggleModal(false);
            }}
          >
            Apply
          </Button>
        </div>
      )}
    </>
  );
};

export default ColumnOrder;
