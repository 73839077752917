import type { FunctionComponent } from 'react';
import { useState, useEffect, Fragment } from 'react';
import { getSelectedTabs } from '../utils/filterUtils';
import '../styles/components/refresh.scss';
import '../styles/components/quickfilter.scss';

interface QuickFilterProps {
  options: string[];
  selectAllIndex: number;
  size: string;
  hasDividers?: boolean;
  filterData: (selectedTabs: number[]) => void;
  presetIndexes?: number[];
  onChange?: (indexes: number[]) => void;
}

const QuickFilter: FunctionComponent<QuickFilterProps> = ({
  options,
  hasDividers,
  selectAllIndex,
  filterData,
  size,
  onChange,
  presetIndexes,
}) => {
  const [selectedTabs, setSelectedTabs] = useState<number[]>([selectAllIndex]);

  const handleClick = (index: number) => {
    const selections = getSelectedTabs(
      index,
      options,
      selectedTabs,
      selectAllIndex
    );
    setSelectedTabs(selections);
    onChange?.(selections);
  };

  useEffect(() => {
    if (presetIndexes && presetIndexes.length > 0) {
      setSelectedTabs(presetIndexes);
    }
  }, [presetIndexes]);

  useEffect(() => {
    filterData(selectedTabs);
  }, [selectedTabs, options]);

  return (
    <div className="quickfilter">
      {options.map((option, index, arr) => {
        return (
          <Fragment key={option}>
            <div
              data-testid={`quickfilter-btn-${option}`}
              className={`quickfilter__button ${
                selectedTabs.includes(index) && 'active'
              } ${size}`}
              tabIndex={0}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick(index);
                }
              }}
            >
              {option}
            </div>
            {hasDividers && arr.length - 1 !== index && (
              <div className="quickfilter__divider" />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
export default QuickFilter;
