import {
  RadioButtonGroup,
  RadioButton,
  NumberInput,
  FormGroup,
  Checkbox,
  InlineNotification,
} from '@carbon/react';
import type { SelectionValue } from './ReportSettings';

const normalizeOption = (
  option: AdvanceSettingsOption['option']
): AdvanceOptionWithMessage => {
  if (typeof option === 'string') {
    return { name: option, value: option };
  }
  return option;
};

export const getSettingsType = (
  setting: AdvancedSettings,
  analyticEngineParams: AnalyticEngineParams,
  handleOnChange: (
    selectionValue: SelectionValue,
    name: string,
    type?: string
  ) => void
) => {
  const { name, text } = setting;

  const selectedMessage = setting.options
    ?.map((option) => normalizeOption(option.option))
    .find((opt) => opt.value === analyticEngineParams[name])?.message;

  switch (setting.type) {
    case 'radio':
      return (
        <>
          <RadioButtonGroup
            legendText={setting.text}
            name={setting.name}
            onChange={(e) => handleOnChange(e, name)}
            className="advanced-settings-radio-group"
          >
            {setting.options.map((option) => {
              const normalizedOption = normalizeOption(option.option);
              return (
                <RadioButton
                  className="advanced-settings-radio-buttons"
                  data-testid={`settings-radio-${option.id}`}
                  key={`settings-radio-${option.id}`}
                  id={`settings-radio-${option.id}`}
                  labelText={normalizedOption.name}
                  value={normalizedOption.value}
                  disabled={option.disabled}
                  checked={
                    normalizedOption.value === analyticEngineParams[name]
                  }
                />
              );
            })}
          </RadioButtonGroup>

          {selectedMessage && (
            <InlineNotification
              className="advanced-settings-radio-message"
              kind="warning"
              lowContrast
              subtitle={selectedMessage}
            />
          )}
        </>
      );
    case 'input-number':
      return (
        <div className="report-setting-inputs-wrapper">
          <NumberInput
            id="input-number"
            invalidText="Number is not valid"
            label={text}
            max={setting.restriction ? setting.restriction.max : 100000000}
            min={0}
            onChange={(_, { value }) => handleOnChange(Number(value), name)}
            value={analyticEngineParams[setting.name]}
          />
        </div>
      );
    case 'checkbox':
      return (
        <FormGroup
          legendText={setting.text}
          className="report-setting-checkbox-title"
        >
          <div className="report-setting-checkbox-wrapper">
            {setting.options.map((item) => {
              const normalizedOption = normalizeOption(item.option);
              return (
                <Checkbox
                  key={`settings-checkbox-${item.id}`}
                  id={`settings-checkbox-${item.id}`}
                  labelText={normalizedOption.name}
                  value={normalizedOption.value}
                  disabled={item.disabled}
                  data-testid={`settings-checkbox-${item.id}`}
                  checked={(analyticEngineParams[name] as string[]).includes(
                    normalizedOption.value
                  )}
                  onChange={({ target: { value } }, { checked }) => {
                    return handleOnChange(
                      {
                        selection: value,
                        checked,
                        defaultValue: setting.default as string,
                      },
                      setting.name,
                      setting.type
                    );
                  }}
                />
              );
            })}
          </div>
        </FormGroup>
      );
  }
};
