import type { FunctionComponent, FormEvent } from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalHeader,
  TextInput,
  InlineLoading,
} from '@carbon/react';
import { TaskAdd } from '@carbon/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../providers/ModalProvider';
import apiRequest from '../../api';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/reportCopyModal.scss';

interface ReportCopyModalProps {
  readonly reportName: string;
  readonly runId: string;
}

const ReportCopyModal: FunctionComponent<ReportCopyModalProps> = ({
  reportName,
  runId,
}) => {
  const DEFAULT_REPORT_NAME = `${reportName} - Copy`;
  const [reportCopyName, setReportCopyName] = useState(DEFAULT_REPORT_NAME);
  const [isLoading, setIsLoading] = useState(false);
  const { toggleModal, updateModal } = useContext(ModalContext);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleReportCopy = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const payload = {
        run_id: runId,
        report_name: reportCopyName.trim(),
      };
      await apiRequest('/reports/copy', 'POST', token, payload);
      toggleModal(false);
      navigate('/workspace/my-reports');
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue creating a copy, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    setReportCopyName(DEFAULT_REPORT_NAME);
  };

  const isNameValid = reportCopyName.trim().length > 0;
  const canSubmit = isNameValid && !isLoading;

  return (
    <>
      <ModalHeader>
        <h5 className="ReportCopyModal__title">Save Report as a New Copy</h5>
        <p className="body-03">
          This report will be saved as a new copy with the same inputs and
          changes you made to the original one.
        </p>
        <div className="ReportCopyModal__divider" />
      </ModalHeader>
      <ModalBody>
        <TextInput
          id="report-copy-name"
          type="text"
          disabled={isLoading}
          labelText="Report name"
          invalid={!isNameValid}
          invalidText="Report must have a name"
          value={reportCopyName}
          placeholder="Report copy name"
          autoFocus
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setReportCopyName(e.currentTarget.value)
          }
          onKeyDown={({ code }) => {
            if (code === 'Enter' && canSubmit) {
              handleReportCopy();
            }
          }}
        />
      </ModalBody>
      <div className="ReportCopyModal__actions">
        <Button
          kind="secondary"
          size="md"
          disabled={isLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          size="md"
          className="has-icon"
          disabled={!canSubmit}
          onClick={handleReportCopy}
          renderIcon={isLoading ? InlineLoading : TaskAdd}
        >
          Create Report Copy
        </Button>
      </div>
    </>
  );
};

export default ReportCopyModal;
