/* eslint-disable indent */
import { utils, writeFile } from 'xlsx-js-style';
import apiRequest from '../api';
import type {
  MeasureAccessOptions,
  NewUserOptions,
  UploadedUserData,
} from '../components/AdminPanel/UsersPanel/BulkUserInviteModal';
import {
  AuthenticationType,
  USER_DATA_OPTIONAL_FIELDS,
  USER_DATA_REQUIRED_FIELDS,
  USER_ROLE,
  USER_TYPE,
} from '../constants/metadata';
import type {
  BannerData,
  MeasureAccessValue,
  PayloadUserData,
  UserData,
  UserDataConfig,
  UsersTableFilters,
} from '../reducers/AdminPanelReducer';
import { USER_STATUS } from '../reducers/AdminPanelReducer';
import { omit, pick } from './object';
import type { ResponsePayload } from '../components/Fetch';
import type { UserUploadResponse } from '../types/apiTypes';
import type {
  BannerFormData,
  UserForm,
  UserFormWithError,
} from '../reducers/UserFormReducer';
import { userFormInitState } from '../reducers/UserFormReducer';

export const getFormattedUserRole = (userRole: USER_ROLE) => {
  const roleMap = {
    [USER_ROLE.SUPER_ADMIN]: 'Super Admin',
    [USER_ROLE.STANDARD_USER]: 'Standard User',
    [USER_ROLE.SUPER_USER]: 'Super User',
  };
  return roleMap[userRole];
};

export const getFormattedUserType = (userType: USER_TYPE) => {
  const typeMap = {
    [USER_TYPE.CUSTOMER]: 'Customer',
    [USER_TYPE.SUPPLIER]: 'Supplier',
    [USER_TYPE.SUPPORT]: 'Support',
  };
  return typeMap[userType];
};

export const getFormattedUserMeasureAccess = (
  measureAccess: MeasureAccessValue
) => {
  const measureAccessMap = {
    0: 'No Margin Access',
    1: 'Full Access',
  };
  return measureAccessMap[measureAccess];
};

export const getFormattedUserStatus = (status: USER_STATUS) => {
  const statusMap = {
    [USER_STATUS.ACTIVE]: 'Active',
    [USER_STATUS.PENDING]: 'Pending',
    [USER_STATUS.DISABLED]: 'Disabled',
  };
  return statusMap[status];
};

export const getFormattedAuthType = (authType: AuthenticationType) => {
  const authTypeMap = {
    [AuthenticationType.EMAIL_PASSWORD]: 'Email/PW',
    [AuthenticationType.EMAIL_OTP]: 'Email-OTP',
    [AuthenticationType.SSO]: 'SSO',
  };
  return authTypeMap[authType];
};

export const validateEmail = (email: string | undefined): boolean => {
  return !!email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(email.toLowerCase());
};

interface CompletedBannerFormData extends BannerFormData {
  banner: NonNullable<BannerFormData['banner']>;
}

interface CompletedUserFormData extends UserForm {
  userRole: NonNullable<UserForm['userRole']>;
  userType: NonNullable<UserForm['userType']>;
  authType: NonNullable<UserForm['authType']>;
  measureAccess: NonNullable<UserForm['measureAccess']>;
  banners: CompletedBannerFormData[];
}

export const getUserDataPayload = (formState: CompletedUserFormData) => {
  const {
    name,
    email,
    authType,
    userRole,
    measureAccess,
    banners,
    userType,
    supplierName,
    jobTitle,
    department,
  } = formState;
  const bannerData = banners.filter(({ banner }) => banner);
  const conditionalData =
    formState.userType?.type === USER_TYPE.SUPPLIER
      ? {
          supplier_name: supplierName,
        }
      : {
          job_title: jobTitle || undefined,
          department: department || undefined,
        };
  const payload: PayloadUserData = {
    name,
    email,
    authentication: authType?.type,
    user_role: userRole.role,
    user_type: userType.type,
    measure_access: measureAccess.id,
    banners: bannerData.map(({ banner, userGroups }) => ({
      id: banner.id,
      user_groups: userGroups.map(({ id }) => ({ id })),
    })),
    ...conditionalData,
  };
  return payload;
};

export const inviteNewUser = async (formState: UserForm, token: string) => {
  return apiRequest<ResponsePayload<UserUploadResponse>>(
    '/admin/users',
    'POST',
    token,
    [getUserDataPayload(formState as CompletedUserFormData)]
  );
};

export const editExistingUser = async (
  formState: UserForm,
  userId: string,
  token: string
) => {
  const payload = {
    id: userId,
    ...getUserDataPayload(formState as CompletedUserFormData),
  };

  return apiRequest<ResponsePayload<UserUploadResponse>>(
    '/admin/users',
    'PATCH',
    token,
    payload
  );
};

export interface FilterData {
  key: keyof UsersTableFilters;
  value: string | number;
  label: string;
}

export const getFilterList = (
  usersTableFilters: UsersTableFilters,
  banners: BannerData[]
): FilterData[] => {
  const filterOptions = pick(usersTableFilters, [
    'user_type',
    'user_role',
    'banners',
    'measure_access',
    'status',
  ]);
  const labelFormatter = {
    user_type: getFormattedUserType,
    user_role: getFormattedUserRole,
    banners: (bannerId: number) => getBannerNameById(bannerId, banners),
    measure_access: getFormattedUserMeasureAccess,
    status: getFormattedUserStatus,
  };

  return Object.entries(filterOptions).reduce((acc, [key, filterValue]) => {
    const options: FilterData[] = (filterValue as string[]).map((value) => {
      const formatter = labelFormatter[key];
      return {
        key: key as keyof UsersTableFilters,
        value,
        label: formatter(value),
      };
    });
    acc.push(...options);
    return acc;
  }, [] as FilterData[]);
};

export const isRowMatchCondition = (
  row: UserData,
  usersTableFilters: UsersTableFilters
) => {
  const rowEntries = Object.entries(row);
  return rowEntries.every(([key, cellValue]) => {
    const filterValue = usersTableFilters[key];
    if (!filterValue || filterValue.length === 0) {
      return true;
    }
    if (Array.isArray(filterValue)) {
      return key === 'banners'
        ? filterValue.some((item) => cellValue.find(({ id }) => id === item))
        : filterValue.includes(cellValue);
    }
    if (typeof cellValue === 'string') {
      return cellValue.toLowerCase().includes(filterValue.toLowerCase());
    }
  });
};

export const getMeasureAccessValue = (
  value: MeasureAccessOptions
): MeasureAccessValue => {
  const measureAccess: Record<MeasureAccessOptions, MeasureAccessValue> = {
    Y: 1,
    N: 0,
  };

  return measureAccess[value];
};

export const parseNewUserValue = (value: NewUserOptions): boolean => {
  const newUserMap: Record<NewUserOptions, boolean> = {
    Y: true,
    N: false,
  };
  return newUserMap[value];
};

export const parseNumberFieldString = (combinedValue: string | number) =>
  combinedValue
    .toString()
    .split(',')
    .map((value) => Number(value.trim()));

export const isUploadedUserRowValid = (
  row: UploadedUserData,
  dataConfig: UserDataConfig
): boolean => {
  const doesRowHaveRequiredFields = USER_DATA_REQUIRED_FIELDS.every(
    (key) => !!row[key]
  );
  if (!doesRowHaveRequiredFields) {
    return false;
  }

  const { userTypes, userRoles, authTypes, measureRestrictions, banners } =
    dataConfig;

  const {
    email,
    banner,
    user_group,
    measure_access,
    user_type,
    user_role,
    supplier_name,
    authentication,
    new_user,
  } = row;
  const isEmailValid = validateEmail(email);
  const isUserTypeValid =
    user_type === USER_TYPE.SUPPLIER
      ? !!supplier_name
      : !!userTypes?.some(({ type }) => type === user_type);
  const isUserRoleValid = userRoles?.some(({ role }) => role === user_role);
  const isAuthenticationTypeValid = authTypes?.some(
    ({ type }) => type === authentication
  );
  const isMeasureAccessValid = measureRestrictions?.some(
    ({ id }) => id === getMeasureAccessValue(measure_access)
  );

  const isNewUserValid = ['Y', 'N'].includes(new_user);

  const isBannerDataValid = () => {
    const userBanners = parseNumberFieldString(banner);
    const userGroups = parseNumberFieldString(user_group);

    if (!userBanners || !userGroups) {
      return false;
    }

    const filteredBanners = banners?.filter(({ id }) =>
      userBanners.includes(id)
    );

    const bannersData = filteredBanners?.map(({ id, user_groups }) => ({
      id,
      userGroups: user_groups.filter(({ id }) => userGroups.includes(id)),
    }));

    return (
      bannersData?.length === userBanners.length &&
      bannersData.every(({ userGroups }) => userGroups.length > 0) &&
      bannersData.flatMap(({ userGroups }) => userGroups).length ===
        userGroups.length
    );
  };

  return !!(
    isEmailValid &&
    isUserTypeValid &&
    isUserRoleValid &&
    isAuthenticationTypeValid &&
    isMeasureAccessValid &&
    isNewUserValid &&
    isBannerDataValid()
  );
};

export const mapUploadedData = (
  rows: UploadedUserData[],
  banners: BannerData[]
): PayloadUserData[] =>
  rows.map((row) => {
    const userData = omit(row, ['banner', 'user_group', 'measure_access']);
    const userBanners = parseNumberFieldString(row.banner);
    const userGroups = parseNumberFieldString(row.user_group);
    const filteredBanners = banners.filter(({ id }) =>
      userBanners.includes(id)
    );
    const bannerData = filteredBanners.map(({ id, user_groups }) => ({
      id,
      user_groups: user_groups
        .filter(({ id }) => userGroups.includes(id))
        .map(({ id }) => ({
          id,
        })),
    }));
    return {
      ...userData,
      banners: bannerData,
      measure_access: getMeasureAccessValue(row.measure_access),
      new_user: parseNewUserValue(row.new_user),
    };
  });

export const generateUserTemplateExample = () => {
  const workbook = utils.book_new();
  const header = [...USER_DATA_REQUIRED_FIELDS, ...USER_DATA_OPTIONAL_FIELDS];
  const row = [
    ...USER_DATA_REQUIRED_FIELDS.map((header) => {
      switch (header) {
        case 'banner':
          return '(Required) e.g. 101, 102';
        case 'user_group':
          return '(Required) e.g. 1010, 1020';
        case 'measure_access':
          return '(Required) Y / N';
        case 'new_user':
          return '(Required) Y / N';
        default:
          return '(Required)';
      }
    }),
    ...USER_DATA_OPTIONAL_FIELDS.map(() => '(Optional)'),
  ];
  const worksheet = utils.aoa_to_sheet([header, row]);
  worksheet['!cols'] = header.map(() => ({
    wch: 20,
  }));
  utils.book_append_sheet(workbook, worksheet);
  writeFile(workbook, 'invite-team-members-template.xlsx');
};

export const getUserFormData = (userData: UserData): UserFormWithError => {
  const {
    name,
    user_role,
    user_type,
    authentication,
    measure_access,
    banners,
    job_title,
    department,
  } = userData;

  return {
    ...omit(userFormInitState, ['email']),
    name,
    userRole: {
      name: getFormattedUserRole(user_role),
      role: user_role,
    },
    userType: {
      name: getFormattedUserType(user_type),
      type: user_type,
    },
    authType: authentication
      ? {
          name: getFormattedAuthType(authentication),
          type: authentication,
        }
      : null,
    measureAccess: {
      name: getFormattedUserMeasureAccess(measure_access),
      id: measure_access,
    },
    banners: banners.map(({ id, name, user_groups }) => ({
      id,
      banner: { id, name },
      userGroups: user_groups,
    })),
    jobTitle: job_title,
    department: department,
  };
};

export const getBannerNameById = (
  bannerId: number,
  banners: BannerData[]
): string | undefined => banners.find(({ id }) => id === bannerId)?.name;
