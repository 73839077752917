import { useContext, useState } from 'react';
import type { FormEvent, FunctionComponent } from 'react';
import {
  Button,
  ModalBody,
  ModalHeader,
  TextInput,
  InlineLoading,
  RadioButton,
  RadioButtonGroup,
  ToastNotification,
} from '@carbon/react';
import { DataCategorical } from '@carbon/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import type { AxiosError } from 'axios';
import { AppContext } from '../../providers/AppProvider';
import apiRequest from '../../api';
import { AttributeType } from '../../types/customAttribute';
import '../../styles/components/attributeManager.scss';

export enum ErrorType {
  Validation = 'validation',
}

interface ValidationError {
  readonly type: ErrorType.Validation;
  readonly messages: Record<'attribute' | 'attribute_type', string[]>;
}

interface GenericError {
  readonly message: string;
}

interface CreateAttributeModalProps {
  readonly onClose: (isSuccess: boolean) => void;
}

export const CreateAttributeModal: FunctionComponent<
  CreateAttributeModalProps
> = (props) => {
  const { onClose } = props;

  const { getAccessTokenSilently } = useAuth0();
  const { bannerId, groupId } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [attributeName, setAttributeName] = useState('');
  const attributeType = AttributeType.String;

  const [isSubmitError, setIsSubmitError] = useState(false);
  const [inputErrors, setInputErrors] =
    useState<Partial<Record<'attribute' | 'attribute_type', string[]> | null>>(
      null
    );

  const resetForm = (): void => {
    setIsSubmitError(false);
    setInputErrors(null);
    setAttributeName('');
  };

  const closeModal = (isSuccess: boolean): void => {
    resetForm();
    onClose(isSuccess);
  };

  const createAttribute = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setIsSubmitError(false);
      setInputErrors(null);

      const token = await getAccessTokenSilently();

      const payload = {
        attribute: attributeName,
        attribute_type: attributeType,
      };

      await apiRequest(
        `/custom/${bannerId}/user-groups/${groupId}/attributes`,
        'POST',
        token,
        payload
      );

      closeModal(true);
    } catch (error) {
      const errorResponse = error as
        | AxiosError<ValidationError | GenericError | string>
        | Error;

      const errorData =
        'response' in errorResponse ? errorResponse.response?.data : undefined;

      if (
        typeof errorData === 'object' &&
        'type' in errorData &&
        errorData?.type === ErrorType.Validation
      ) {
        setInputErrors(errorData.messages);

        return;
      }

      setIsSubmitError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isNameValid = attributeName.trim().length > 0;
  const canSubmit = isNameValid && !isLoading;

  return (
    <div
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.preventDefault();
        }
      }}
    >
      <ModalHeader>
        <h5 className="CreateAttributeModal__title">Create Attribute</h5>
        <div className="CreateAttributeModal__divider" />
      </ModalHeader>
      <ModalBody>
        {isSubmitError && (
          <ToastNotification
            aria-label="Error notification"
            kind="error"
            title="Something went wrong"
            subtitle="There was an issue creating the attribute, please try again later."
            className="CreateAttributeModal__request-notification"
            lowContrast
            hideCloseButton
          />
        )}
        <TextInput
          id="attribute-name"
          type="text"
          disabled={isLoading}
          labelText="Attribute"
          invalid={
            (!isNameValid || typeof inputErrors?.attribute === 'string') &&
            attributeName !== ''
          }
          invalidText={inputErrors?.attribute ?? 'Attribute must have a name'}
          helperText="Please enter a unique name"
          value={attributeName}
          autoFocus
          onBlur={() => {
            if (!isNameValid && attributeName === '') {
              setInputErrors({ attribute: ['Attribute must have a name'] });
            }
          }}
          onChange={({ currentTarget }: FormEvent<HTMLInputElement>) =>
            setAttributeName(currentTarget.value)
          }
        />
        <RadioButtonGroup
          legendText={
            <span className="body-02">
              Attribute Type
              <span className="body-03"> (Only string values are valid)</span>
            </span>
          }
          name="attribute-type"
          disabled={isLoading}
        >
          <RadioButton
            labelText={<span className="body-02">String (Text values)</span>}
            checked={attributeType === AttributeType.String}
            value={AttributeType.String}
          />
        </RadioButtonGroup>
      </ModalBody>
      <div className="CreateAttributeModal__actions">
        <Button
          kind="secondary"
          size="md"
          disabled={isLoading}
          onClick={() => closeModal(false)}
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          size="md"
          className="has-icon"
          disabled={!canSubmit}
          onClick={createAttribute}
          renderIcon={isLoading ? InlineLoading : DataCategorical}
        >
          Create Attribute
        </Button>
      </div>
    </div>
  );
};
