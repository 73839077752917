/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  DataTable,
  Pagination,
  Table,
  TableBatchActions,
  TableBatchAction,
  TableBody,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow as CarbonTableRow,
  TableSelectAll,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import { useStickyTableHeader } from '../../../utils/useStickyTableHeader';
import { usePagination } from '../../../hooks';
import { getSelectedRowIds } from '../../../utils/DataGridUtils';
import UsersTableRow from './UsersTableRow';
import type { UserData } from '../../../reducers/AdminPanelReducer';
import { USER_STATUS } from '../../../reducers/AdminPanelReducer';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../../api';
import { ModalContext } from '../../../providers/ModalProvider';
import { AppContext } from '../../../providers/AppProvider';
import { CACHE_KEY } from '../../../constants/api';
import Gravatar from '../../Gravatar';
import { SidePanelContext } from '../../../providers/SidePanelProvider';
import TransferAllReports from './TransferAllReports';
import DisableUser from '../../../assets/icons/DisableUser';
import EditUserModal from './EditUserModal';
import { AdminPanelContext } from '../../../providers/AdminPanelProvider';
import { getUserFormData } from '../../../utils/adminPanelUtils';

export const usersTableHeaderData = [
  { key: 'name', header: 'Name' },
  { key: 'user_type', header: 'User Type' },
  { key: 'authentication', header: 'Authentication' },
  { key: 'user_role', header: 'Role' },
  { key: 'job_title', header: 'Job Title' },
  { key: 'department', header: 'Department' },
  { key: 'banners', header: 'Banners', isSortable: false },
  { key: 'measure_access', header: 'Measure Access' },
  { key: 'status', header: 'Status' },
];

export type RowStatus = 'Deleting' | 'Disabling';

interface UserTableProps {
  readonly rowData: UserData[];
  readonly filteredData: UserData[];
}

const UsersTable: FunctionComponent<UserTableProps> = ({
  rowData,
  filteredData,
}) => {
  const { clearCacheForKey } = useContext(AppContext);
  const { updateModal, toggleModal, renderComponent } =
    useContext(ModalContext);
  const { renderSidePanelContent } = useContext(SidePanelContext);
  const { dataConfig } = useContext(AdminPanelContext);
  const { getAccessTokenSilently } = useAuth0();
  const [rowStatus, setRowStatus] = useState<RowStatus | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const tableId = 'admin-panel-users-table';
  const { isSticky, scrollbarRef } = useStickyTableHeader({
    rowsLength: rowData?.length,
    tableId,
  });

  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination();

  const getRowById = (rowId: string) => {
    return rowData.find(({ id }) => id === rowId);
  };

  useEffect(() => {
    setRowStatus(null);
    setSelectedUsers([]);
  }, [rowData]);

  const deleteUsers = async (userIds: string[]) => {
    setSelectedUsers(userIds);
    setRowStatus('Deleting');
    try {
      const token = await getAccessTokenSilently();
      const payload = {
        user_ids: userIds,
      };
      await apiRequest('/admin/users', 'DELETE', token, payload);
      clearCacheForKey(CACHE_KEY.USER_LIST);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue deleting users. Please try again.',
      });
    }
  };

  const disableUsers = async (userIds: string[]) => {
    setSelectedUsers(userIds);
    setRowStatus('Disabling');
    try {
      const token = await getAccessTokenSilently();
      const payload = {
        user_ids: userIds,
      };
      await apiRequest('/admin/users?action=disable', 'PATCH', token, payload);
      clearCacheForKey(CACHE_KEY.USER_LIST);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue disabling users. Please try again.',
      });
    }
  };

  const transferReports = async (userIds: string[], transferUser: UserData) => {
    try {
      const token = await getAccessTokenSilently();
      await apiRequest('/admin/users?transfer=true', 'DELETE', token, {
        user_ids: userIds,
        admin_user_id: transferUser.id,
      });
      updateModal({
        type: 'success',
        title: 'Reports ownership transferred',
        body: (
          <div>
            <div>Ownsership has been successfully transferred to</div>
            <div className="flex align-center justify-center">
              <Gravatar name={transferUser.name} />
            </div>
          </div>
        ),
      });
      clearCacheForKey(CACHE_KEY.USER_LIST);
    } catch {
      updateModal({
        type: 'error',
        title: 'Transfer of ownership failed',
        body: 'An error occurred when attempting to transfer ownership. Please try again.',
      });
    }
  };

  const handleDeleteClick = (userIds: string[]) => {
    const pluralTextVersion = userIds.length > 1 ? 'these users' : 'this user';
    updateModal({
      type: 'warning',
      title: `Are you sure you want to delete ${pluralTextVersion}?`,
      body: `If you click delete, the reports shared by ${pluralTextVersion} with others will also be deleted`,
      primaryCTAText: 'Delete User & Transfer Reports',
      onPrimaryCTAClick: () => {
        toggleModal(false);
        const selectedUsers = rowData.filter(({ id }) => userIds.includes(id));
        renderSidePanelContent(
          <TransferAllReports
            selectedUsers={selectedUsers}
            transferReports={transferReports}
          />,
          {
            title: 'Transfer Reports',
            subtitle: 'Transfer for all reports from the user to be deleted',
          }
        );
      },
      secondaryCTAText: 'Delete User & Reports',
      onSecondaryCTAClick: () => {
        deleteUsers(userIds);
        toggleModal(false);
      },
      className: 'UsersTableRow__delete-user-modal',
      isCloseIconVisible: true,
    });
  };

  const handleDisableClick = (userIds: string[]) => {
    updateModal({
      type: 'warning',
      title: `Are you sure you want to disable ${
        userIds.length > 1 ? 'these users' : 'this user'
      }?`,
      body: 'Once a user has been disabled they will no longer have access to the platform',
      primaryCTAText: 'Disable User',
      onPrimaryCTAClick: () => {
        toggleModal(false);
        disableUsers(userIds);
      },
      secondaryCTAText: 'Cancel',
      onSecondaryCTAClick: () => toggleModal(false),
      className: 'UsersTableRow__delete-user-modal',
      isCloseIconVisible: true,
    });
  };

  const handleEditClick = (userId: string, userStatus: string) => {
    const userData = rowData.find(({ id }) => id === userId);
    const currentAction =
      userStatus === USER_STATUS.DISABLED
        ? {
            title: 'Delete User',
            action: (userId: string) => handleDeleteClick([userId]),
          }
        : {
            title: 'Disable User',
            action: (userId: string) => handleDisableClick([userId]),
          };

    if (dataConfig && userData) {
      renderComponent(
        <EditUserModal
          dataConfig={dataConfig}
          userId={userData.id}
          initialFormState={getUserFormData(userData)}
          currentAction={currentAction}
        />,
        'EditUserModal__container'
      );
    }
  };

  const handleBatchDeleteClick = (selectedRows: CarbonSelectedRow[]) => {
    const canUsersBeDeleted = selectedRows.every(({ cells }) => {
      const status = cells.find(({ info }) => info.header === 'status')?.value;
      return status === USER_STATUS.DISABLED;
    });
    if (canUsersBeDeleted) {
      handleDeleteClick(selectedRows.map(({ id }) => id));
    } else {
      updateModal({
        type: 'warning',
        title: 'Unable to delete users',
        body: 'Only disabled users can be deleted',
      });
    }
  };

  const handleBatchDisableClick = (selectedRows: CarbonSelectedRow[]) => {
    const canUsersBeDisabled = selectedRows.every(({ cells }) => {
      const status = cells.find(({ info }) => info.header === 'status')?.value;
      return status !== USER_STATUS.DISABLED;
    });
    if (canUsersBeDisabled) {
      handleDisableClick(selectedRows.map(({ id }) => id));
    } else if (selectedRows.length === 1) {
      updateModal({
        type: 'warning',
        title: 'This user has already been disabled',
        primaryCTAText: 'Delete User',
        onPrimaryCTAClick: () => {
          handleDeleteClick(selectedRows.map(({ id }) => id));
        },
        secondaryCTAText: 'Cancel',
      });
    } else {
      // TODO: for this case, the logic will be implemented in TRP-3597
      updateModal({
        type: 'warning',
        title: 'These users has already been disabled',
      });
    }
  };

  return (
    <div>
      <div className="UsersTable__container" id={tableId} data-testid={tableId}>
        <DataTable rows={filteredData} headers={usersTableHeaderData}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getBatchActionProps,
            getSelectionProps,
            getToolbarProps,
            selectedRows,
            selectRow,
          }) => {
            const rowStartIndex = (page - 1) * pageSize;

            const visibleRows = isShowAll
              ? rows
              : rows.slice(rowStartIndex, rowStartIndex + pageSize);

            return (
              <TableContainer>
                <TableToolbarContent>
                  <TableToolbar {...getToolbarProps()}>
                    <TableBatchActions
                      {...getBatchActionProps()}
                      className="toolbar-buttons"
                      data-testid="table-batch-actions"
                    >
                      <TableBatchAction
                        onClick={() => {
                          handleBatchDisableClick(selectedRows);
                        }}
                        renderIcon={null}
                        data-testid="users-table-disable"
                      >
                        Disable <DisableUser />
                      </TableBatchAction>
                      <TableBatchAction
                        renderIcon={null}
                        onClick={() => handleBatchDeleteClick(selectedRows)}
                        data-testid="users-table-delete"
                      >
                        Delete <TrashCan />
                      </TableBatchAction>
                    </TableBatchActions>
                  </TableToolbar>
                </TableToolbarContent>
                <div className="data-table-container">
                  <Table {...getTableProps()} className="table-content">
                    <TableHead
                      data-testid="table-header"
                      className={`${
                        selectedRows.length === 0 ? 'rounded' : ''
                      }`}
                    >
                      <CarbonTableRow
                        className={
                          isSticky ? 'sticky-header' : 'visible-header'
                        }
                      >
                        <TableSelectAll
                          {...getSelectionProps()}
                          onSelect={() => {
                            const selectedRowIds = getSelectedRowIds(
                              visibleRows,
                              selectedRows
                            );
                            selectedRowIds.forEach((id) => {
                              selectRow(id);
                            });
                          }}
                          className="header-checkbox"
                        />
                        {headers.map((header) => (
                          <TableHeader
                            key={header.key}
                            tabIndex={0}
                            {...getHeaderProps({ header })}
                            isSortable={header.isSortable ?? true}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader className="table-header context-menu-header">
                          Actions
                        </TableHeader>
                      </CarbonTableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => {
                        return (
                          <UsersTableRow
                            key={row.id}
                            row={row}
                            rowStatus={rowStatus}
                            selectedUsers={selectedUsers}
                            isOverflowMenuDisabled={
                              selectedRows.length === 1
                                ? !row.isSelected
                                : selectedRows.length > 0
                            }
                            rowProps={getRowProps({ row })}
                            selectionProps={getSelectionProps({ row })}
                            getRowById={getRowById}
                            handleDisableClick={handleDisableClick}
                            handleDeleteClick={handleDeleteClick}
                            handleEditClick={handleEditClick}
                            scrollbarRef={scrollbarRef}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </TableContainer>
            );
          }}
        </DataTable>
      </div>
      <div
        className="data-grid-scrollbar-container"
        data-testid="data-grid-scrollbar-container"
        ref={scrollbarRef}
      >
        <div />
      </div>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={setPagination}
        page={page}
        pageSize={pageSize}
        pageSizes={pageSizes}
        itemRangeText={getItemRangeText}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default UsersTable;
