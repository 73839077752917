import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, FormLabel } from '@carbon/react';
import type { ReportConfig } from '../../reducers/ReportReducer';
import ParameterSummary from '../ReportBuilder/ParameterSummary';
import { AppContext } from '../../providers/AppProvider';
import { Report, Replicate, Edit } from '@carbon/icons-react';

interface ReportMetadataProps {
  reportConfig: ReportConfig;
  reportTemplateIndex: number;
  handleEdit: () => void;
  handleDuplicate: () => void;
}

const ReportMetaData: FunctionComponent<ReportMetadataProps> = ({
  reportConfig,
  reportTemplateIndex,
  handleEdit,
  handleDuplicate,
}) => {
  const [reportParams, setReportParams] = useState<ReportParameters | null>(
    null
  );
  const { user } = useContext(AppContext);

  useEffect(() => {
    reportConfig &&
      setReportParams(
        reportConfig.parameters.template_requests[reportTemplateIndex]
      );
  }, [reportConfig]);

  const overlappingParams = {};

  if (reportParams?.overlap?.length) {
    const overlapGroups = reportParams.overlap as string[];
    overlapGroups.forEach((item) => {
      overlappingParams[item] = {
        hasOverlap: true,
        reason: 'This dimension has an overlapping selection.',
      };
    });
  }

  return (
    <>
      {reportParams && (
        <div className="ReportMetaData">
          <div className="ReportMetaData__title">Report Summary</div>
          <div className="ReportMetaData__content">
            <div className="ReportMetaData__metadata">
              <div className="SidePanelTitle__section-title">
                <Report size={20} /> Report Details
              </div>

              <div className="ReportMetaData__report-name">
                <span className="ReportMetaData__user-name">Report Name: </span>
                <FormLabel>{reportConfig.report_name}</FormLabel>
              </div>
              <div className="ReportMetaData__user-name">
                <span className="ReportMetaData__user-name">Owner: </span>
                <FormLabel>{reportConfig.user_name}</FormLabel>
              </div>
            </div>
            <div className="ReportMetaData__summary-section">
              <ParameterSummary
                isEditable
                isTitleHidden
                parameters={reportParams}
                overlap={overlappingParams}
              />
            </div>
          </div>
          <div className="ReportMetaData__actions">
            <Button
              kind="secondary"
              onClick={handleDuplicate}
              data-testid="handleDuplicate-btn"
              size="md"
              className="has-icon"
              renderIcon={Replicate}
            >
              Duplicate
            </Button>
            {reportConfig.user_id === user?.id && (
              <Button
                kind="secondary"
                onClick={handleEdit}
                data-testid="handleEdit-btn"
                size="md"
                className="has-icon"
                renderIcon={Edit}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportMetaData;
