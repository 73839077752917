import type { FunctionComponent } from 'react';
import HierarchyNode from './HierarchyNode';
import type { SelectionObject } from '../ReportBuilder';

interface HierarchyTreeProps {
  nodes: HierNode[];
  searchTerm: string;
  selection: SelectionObject;
  updateSelection?: (node: HierNode) => void;
  updateSelections?: (level: string, selections: HierNode[]) => void;
  isShouldBeExpanded?: boolean;
  nodeLevel?: number;
  parentNode?: HierNode;
  selectedHierarchy: Hierarchy;
  levels: { [key: string]: HierarchyLevel };
  deleteFavourite?: (
    type: 'group' | 'item',
    name: string,
    parentName?: string
  ) => Promise<void>;
}

const HierarchyTree: FunctionComponent<HierarchyTreeProps> = ({
  nodes,
  searchTerm,
  selection,
  updateSelection,
  updateSelections,
  isShouldBeExpanded,
  nodeLevel,
  parentNode,
  selectedHierarchy,
  levels,
  deleteFavourite,
}) => {
  return (
    <>
      {nodes &&
        nodes.map((node) => {
          const favouriteGroupLevel =
            node.favouriteNodeType === 'favourite_group' ? 2 : undefined;
          return (
            <HierarchyNode
              key={node.id}
              node={node}
              excludeSelect={!!node.excludeSelect}
              searchTerm={searchTerm}
              selection={selection}
              updateSelection={updateSelection}
              updateSelections={updateSelections}
              isShouldBeExpanded={isShouldBeExpanded}
              parentNode={parentNode}
              selectedHierarchy={selectedHierarchy}
              deleteFavourite={deleteFavourite}
              nodeLevel={nodeLevel ? nodeLevel + 1 : favouriteGroupLevel}
              levels={levels}
            />
          );
        })}
    </>
  );
};

export default HierarchyTree;
