import type { FunctionComponent } from 'react';
import {
  TableCell,
  TableRow,
  TableSelectRow,
  Tag,
  InlineLoading,
} from '@carbon/react';
import {
  User,
  DeliveryTruck,
  Information,
  Headset,
  TrashCan,
  Edit,
} from '@carbon/icons-react';
import Tooltip from '../../Tooltip';
import BannerCell from '../BannerCell/BannerCell';
import type {
  BannerData,
  MeasureAccessValue,
  UserData,
} from '../../../reducers/AdminPanelReducer';
import { USER_STATUS } from '../../../reducers/AdminPanelReducer';
import Gravatar from '../../Gravatar';
import {
  getFormattedAuthType,
  getFormattedUserMeasureAccess,
  getFormattedUserRole,
  getFormattedUserStatus,
  getFormattedUserType,
} from '../../../utils/adminPanelUtils';
import type {
  AuthenticationType,
  USER_ROLE,
} from '../../../constants/metadata';
import { USER_TYPE } from '../../../constants/metadata';
import DisableUser from '../../../assets/icons/DisableUser';
import type { RowStatus } from './UsersTable';

interface RowProps {
  key: string;
  isExpanded: boolean;
  ariaLabel: string;
  isSelected: boolean;
  disabled: boolean;
  onExpand: () => void;
}

type CellValues = UserData[keyof UserData];

interface UsersTableRowProps {
  readonly row: CarbonSelectedRow;
  readonly rowProps: RowProps;
  readonly selectionProps: SelectionProps;
  readonly rowStatus: RowStatus | null;
  readonly selectedUsers: string[];
  readonly isOverflowMenuDisabled: boolean;
  readonly scrollbarRef: React.MutableRefObject<HTMLDivElement | null>;
  readonly getRowById: (rowId: string) => UserData | undefined;
  readonly handleDeleteClick: (userIds: string[]) => void;
  readonly handleDisableClick: (userIds: string[]) => void;
  readonly handleEditClick: (userId: string, userStatus: string) => void;
}

const UsersTableRow: FunctionComponent<UsersTableRowProps> = ({
  row,
  rowProps,
  selectionProps,
  rowStatus,
  selectedUsers,
  isOverflowMenuDisabled,
  scrollbarRef,
  getRowById,
  handleDeleteClick,
  handleDisableClick,
  handleEditClick,
}) => {
  const userStatus = row.cells.find(
    ({ info }) => info.header === 'status'
  ).value;

  const userTypeIcon = {
    [USER_TYPE.CUSTOMER]: <User />,
    [USER_TYPE.SUPPORT]: <Headset />,
    [USER_TYPE.SUPPLIER]: <DeliveryTruck />,
  };

  const getCellContent = (cell: CarbonCell<CellValues>) => {
    if (!cell.value && cell.value !== 0) {
      return '';
    }
    const userId = cell.id.split(':')[0];
    const rowData = getRowById(userId);
    switch (cell.info.header) {
      case 'name': {
        const value = cell.value as string;
        const jobTitle = row.cells.find(
          (cell) => cell.info.header === 'job_title'
        )?.value;
        return (
          <div className="UsersTableRow__name-cell">
            <Gravatar name={value} size={40} showName={false} />
            <div className="UsersTableRow__name-cell-info">
              <div className="body-02">{value}</div>
              <div className="body-03">{jobTitle}</div>
              <div>ID: {userId}</div>
            </div>
          </div>
        );
      }
      case 'user_type': {
        const value = cell.value as USER_TYPE;
        return (
          <div className="UsersTableRow__user-type-cell">
            <div className="UsersTableRow__user-type-cell-title">
              <div
                className={`UsersTableRow__user-type-cell-icon UsersTableRow__user-type-cell-icon--${value}`}
              >
                {userTypeIcon[value]}
              </div>
              <div>{getFormattedUserType(value)}</div>
            </div>
            {value === USER_TYPE.SUPPLIER && rowData?.supplier_name && (
              <div className="UsersTableRow__user-type-cell-subtitle">
                {rowData.supplier_name}
              </div>
            )}
          </div>
        );
      }
      case 'user_role': {
        return (
          <div className="UsersTableRow__user-role-cell">
            <div>{getFormattedUserRole(cell.value as USER_ROLE)}</div>
            <Tooltip
              description={rowData?.user_role_description}
              useButtonWrapper
            >
              <Information />
            </Tooltip>
          </div>
        );
      }
      case 'banners': {
        return (
          <BannerCell
            banners={cell.value as BannerData[]}
            rowId={row.id}
            scrollbarRef={scrollbarRef}
          />
        );
      }
      case 'status': {
        const value = cell.value as USER_STATUS;
        const isLoading = rowStatus !== null && selectedUsers.includes(row.id);
        return isLoading ? (
          <div className="UsersTableRow__loading-indicator">
            <InlineLoading />
            {rowStatus}...
          </div>
        ) : (
          <Tag
            size="sm"
            className={`UsersTableRow__status UsersTableRow__status--${value}`}
          >
            {getFormattedUserStatus(value)}
          </Tag>
        );
      }
      case 'measure_access':
        return getFormattedUserMeasureAccess(cell.value as MeasureAccessValue);
      case 'authentication':
        return getFormattedAuthType(cell.value as AuthenticationType);
      default:
        return cell.value;
    }
  };

  return (
    <>
      <TableRow {...rowProps} data-testid={row.id} tabIndex={0}>
        <TableSelectRow
          className="table-row-checkbox"
          key={row.id}
          {...selectionProps}
        />
        {row.cells.map((cell) => {
          return (
            <TableCell
              className={`${
                cell.info.header === 'status'
                  ? 'UsersTableRow__status-cell'
                  : ''
              }`}
              key={cell.id}
            >
              {getCellContent(cell)}
            </TableCell>
          );
        })}
        <TableCell>
          <div
            className={`UsersTableRow__actions-cell ${
              isOverflowMenuDisabled || rowStatus
                ? 'UsersTableRow__actions-cell--disabled'
                : ''
            }`}
          >
            <Tooltip description="Edit" useButtonWrapper>
              <Edit
                onClick={() =>
                  !isOverflowMenuDisabled && handleEditClick(row.id, userStatus)
                }
                size="14px"
                data-testid="users-table-action-edit"
              />
            </Tooltip>
            {userStatus === USER_STATUS.DISABLED ? (
              <Tooltip
                description="Delete User"
                useButtonWrapper
                align="top-right"
              >
                <TrashCan
                  onClick={() =>
                    !isOverflowMenuDisabled && handleDeleteClick([row.id])
                  }
                  size={16}
                  data-testid="users-table-action-delete"
                />
              </Tooltip>
            ) : (
              <Tooltip
                description="Disable User"
                useButtonWrapper
                align="top-right"
                className="UsersTableRow__disable-action-icon"
              >
                <DisableUser
                  onClick={() =>
                    !isOverflowMenuDisabled && handleDisableClick([row.id])
                  }
                  data-testid="users-table-action-disable"
                />
              </Tooltip>
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UsersTableRow;
