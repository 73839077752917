import { useContext, useMemo, type FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import { Reset, DataError } from '@carbon/icons-react';
import { ReportContext } from '../../providers/ReportProvider';
import type { VisualData } from '../../reducers/ReportReducer';
import DataGrid, { CustomActionKey, type RowDataDefaultType } from './DataGrid';
import { NPDContext } from '../../providers/NPDProvider';
import type { NPDGridRowDataType } from '../../reducers/NPDReducer';
import { ModalContext } from '../../providers/ModalProvider';
import NoDataPlaceholder from '../ReportTable/NoDataPlaceholder';

interface NPDGridProps {
  readonly visualId: number;
  readonly visualData: VisualData<NPDGridRowDataType>;
  readonly dropdownKey: string;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly isAuthor: boolean;
  readonly isSupplier: boolean;
}

const NPDGrid: FunctionComponent<NPDGridProps> = ({
  visualId,
  visualData,
  isAuthor,
  isSupplier,
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
}) => {
  const { reportConfig, patchVisualsData } = useContext(ReportContext);
  const {
    rows,
    isResetButtonDisabled,
    isNPDFinderFeaturesEnabled,
    updateNPDRowFileData,
    resetGridSelection,
  } = useContext(NPDContext);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { headers, stickyColumns, isVisible, isSearchable, columnWidths } =
    visualData;
  const rowData: NPDGridRowDataType[] = rows?.[dropdownKey];

  const { headerData, visibleColumns, stickyColumnsData } = useMemo(() => {
    const SELECTION_HEADER = headers?.[dropdownKey].find(
      ({ key }) => key === 'SELECTION'
    );
    if (isNPDFinderFeaturesEnabled || !SELECTION_HEADER) {
      return {
        headerData: headers?.[dropdownKey],
        visibleColumns: isVisible,
        stickyColumnsData: stickyColumns,
      };
    }

    const headerData = headers?.[dropdownKey].filter(
      ({ id }) => id !== SELECTION_HEADER.id
    );
    const visibleColumns = isVisible?.filter(
      (id) => id !== SELECTION_HEADER.id
    );
    const stickyColumnsData = stickyColumns?.filter(
      (id) => id !== SELECTION_HEADER.id
    );
    return {
      headerData,
      visibleColumns,
      stickyColumnsData,
    };
  }, [isNPDFinderFeaturesEnabled, headers, dropdownKey]);

  const onEditRows = ({ rows: newRows }: { rows: RowDataDefaultType[] }) => {
    // TODO: Change type to "NPDGridRowDataType[]" after merging TRP-3157
    updateNPDRowFileData(dropdownKey, newRows as NPDGridRowDataType[]);
  };

  const handleResetButtonClick = () => {
    updateModal({
      type: 'info',
      title: 'Are you sure you want to reset all changes made?',
      body: 'The changes will revert to the default empty selection for all products.',
      primaryCTAText: 'Yes, Reset',
      onPrimaryCTAClick: () => {
        resetGridSelection();
        toggleModal(false);
      },
      secondaryCTAText: 'Cancel',
      className: 'NPDProvider__reset-selection-modal',
    });
  };

  const resetButtonAction = {
    key: CustomActionKey.NPD_RESET_BUTTON,
    element: (
      <Button
        className="NPDGrid__reset-button"
        kind="secondary"
        iconDescription="Reset"
        hasIconOnly
        tooltipPosition="top"
        aria-label="Reset selection"
        onClick={handleResetButtonClick}
        disabled={isResetButtonDisabled}
        data-testid="reset-row-selection"
      >
        <Reset />
      </Button>
    ),
  };

  if (!rowData || !headerData) {
    return (
      <NoDataPlaceholder
        title="No Data"
        icon={DataError}
        description={{
          info: (
            <div className="getVisual__no-data-info">
              The selected input combination does not have any associated data.
              <br />
              Try altering your selection to obtain updated results.
            </div>
          ),
        }}
      />
    );
  }

  return (
    <div className="NPDGrid" data-testid="npd-grid">
      <DataGrid
        headerData={headerData}
        rowData={rowData}
        stickyColumns={stickyColumnsData}
        visibleColumns={visibleColumns}
        searchable
        exportedFileName={reportConfig.report_name}
        searchableColumns={isSearchable}
        columnWidths={columnWidths}
        visualData={visualData}
        id={visualId}
        dropdownKey={dropdownKey}
        resizable
        showPagination
        reportConfig={reportConfig}
        isAuthor={isAuthor}
        isSupplier={isSupplier}
        patchVisualsData={patchVisualsData}
        onEditRows={onEditRows}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        customToolbarActions={
          isNPDFinderFeaturesEnabled ? [resetButtonAction] : []
        }
      />
    </div>
  );
};

export default NPDGrid;
