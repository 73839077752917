import { useCallback, useContext, useState } from 'react';
import type { FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import { ChevronLeft } from '@carbon/icons-react';
import FormStepper from '../../FormStepper/FormStepper';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../../providers/ModalProvider';
import apiRequest from '../../../api';
import { AppContext } from '../../../providers/AppProvider';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import { AttributeValuesContext } from '../../../providers/AttributeValuesProvider';
import { AttributeValuesWebsocketStatus } from '../../../types/customAttribute';
import { WebsocketAction } from '../../../types/websocket';
import type { GenericWebsocketMessage } from '../../../types/websocket';
import type { AttributeValuesMessage } from '../types/websocket';
import { CACHE_KEY } from '../../../constants/api';
import AttributeValuesUpload from './AttributeValuesUpload';
import AttributeValuesReview from './AttributeValuesReview';
import AttributeValuesUploadSuccess from './AttributeValuesUploadSuccess';
import AttributeValuesGrid from './AttributeValuesGrid';
import useWebsocketListener from '../../../hooks/useWebsocketListener';

const isAttributeValuesMessage = (
  message: GenericWebsocketMessage | AttributeValuesMessage
): message is AttributeValuesMessage =>
  message.action === WebsocketAction.Update &&
  message?.data?.type === 'update_status';

export const AttributeValuesForm: FunctionComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { bannerId, groupId, clearCacheForKey } = useContext(AppContext);
  const {
    uploadData,
    fileSpecs,
    uploadMetadata,
    resetUploadData,
    updateUploadMetadata,
  } = useContext(AttributeValuesContext);

  const { updateModal } = useContext(ModalContext);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onMessage = useCallback(
    (message: GenericWebsocketMessage | AttributeValuesMessage) => {
      if (!isAttributeValuesMessage(message)) {
        return;
      }

      const validationErrorModal: ModalOptions = {
        type: 'error',
        title: 'Something went wrong',
        body: 'An issue occurred while processing your file. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        isCloseIconVisible: false,
      };

      switch (message.data.status) {
        case AttributeValuesWebsocketStatus.UpdatedValues: {
          updateUploadMetadata(message.data);
          setSubmitting(false);
          setSuccess(true);
          clearCacheForKey(CACHE_KEY.HIERARCHIES_CUSTOM_ATTRIBUTE);
          return;
        }

        case AttributeValuesWebsocketStatus.UpdatedValuesFailed: {
          resetUploadData();
          updateModal(validationErrorModal);
          return;
        }
      }
    },
    []
  );

  useWebsocketListener(onMessage);

  const submitUpdatedValues = async () => {
    if (!uploadMetadata) {
      return;
    }
    setSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        `/custom/${bannerId}/user-groups/${groupId}/attribute-values`,
        'POST',
        token,
        {
          process_id: uploadMetadata.process_id,
        }
      );
    } catch (error) {
      setSubmitting(false);
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    }
  };

  const hasValidSkusOrAttributes =
    hasInvalidItems(uploadData?.products) ||
    hasInvalidItems(uploadData?.attributes);

  const steps = [
    {
      label: 'Step 1/ Upload File',
      component: <AttributeValuesUpload />,
      nextDisabled:
        fileSpecs?.status === 'uploading' || fileSpecs?.invalid !== false,
      isValid: !fileSpecs?.invalid !== false,
    },
    {
      label: 'Step 2/ Check Data',
      component: <AttributeValuesGrid />,
      nextDisabled: hasValidSkusOrAttributes,
      isValid: !uploadData || !hasValidSkusOrAttributes,
    },
    {
      label: 'Step 3/ Review & Submit',
      component: <AttributeValuesReview />,
      nextDisabled: false,
      isValid: true,
      isSubmitStep: true,
    },
  ];

  if (success) {
    return <AttributeValuesUploadSuccess />;
  }

  return (
    <>
      <div className="AttributeValuesForm">
        <Button
          kind="ghost"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          hasIconOnly
          size="sm"
          disabled={false}
          onClick={() => navigate('/attribute-manager')}
        />
        <div className="heading-05">Update Attribute Values</div>
      </div>
      <div className="AttributeValuesForm__stepper">
        <FormStepper
          steps={steps}
          isFormValid={!!uploadMetadata?.process_id && !!uploadData}
          submitting={submitting || success}
          onSubmit={submitUpdatedValues}
          submitText="Update Values"
        />
      </div>
    </>
  );
};
