import type { FunctionComponent } from 'react';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import { ReportContext } from '../../providers/ReportProvider';
import TreeChartControls from '../Report/TreeChartControls';
import {
  getTreeNode,
  disableZoomingForWheelEvent,
} from '../../utils/chartUtils';
import { getTextStyleFromIndex } from '../../utils';
import type { TimePeriodData } from '../../reducers/ReportReducer';

interface TreeChartProps {
  treeId: number;
  treeData: TreeChartData[];
  layout?: 'top' | 'left' | 'bottom' | 'right';
  treeType: 'old' | 'new';
  visualHeader?: VisualHeader;
  tab: number | string;
  exportAllCharts?: boolean;
  timePeriods?: TimePeriodData;
}

const expandStyle =
  '"background: #eee; height: 40px; width: 40px; display:flex; align-items: center; justify-content:center; border-radius: 4px; margin: auto; font-size:20px;"';

const TreeChart: FunctionComponent<TreeChartProps> = ({
  treeId,
  treeData,
  layout = 'top',
  treeType,
  visualHeader,
  tab,
  exportAllCharts,
  timePeriods,
}) => {
  const d3Container = useRef(null);

  const { fullscreenVisual, treeCharts, updateTreeCharts } =
    useContext(ReportContext);

  const chart = treeCharts && treeCharts[treeId];

  useEffect(() => {
    const newChart = new OrgChart();
    newChart.expandToLevel = function () {
      const { allNodes } = this.getChartState();
      allNodes?.forEach((d) => {
        if (d.data.expandOnMount) {
          d.data._expanded = true;
        }
      });
      this.render();
      return this;
    };

    newChart.resetToDefault = function () {
      const { allNodes } = this.getChartState();
      allNodes?.forEach((d) => {
        d.data._expanded = !!d.data.expandOnMount;
      });
      this.render();
      return this;
    };

    updateTreeCharts(treeId, newChart);
  }, [treeId]);

  useLayoutEffect(() => {
    if (treeData && d3Container.current && chart) {
      chart
        .parentNodeId?.(({ parent_id }) => parent_id)
        .data?.(treeData)
        .container?.(d3Container.current)
        .svgHeight?.(fullscreenVisual !== null ? window.innerHeight : 590)
        .nodeHeight?.(() => 260)
        .nodeWidth?.(() => 430)
        .childrenMargin?.(() => 100)
        .compactMarginBetween?.(() => 10)
        .compactMarginPair?.(() => 80)
        .siblingsMargin?.(() => 60)
        .setActiveNodeCentered?.(false)
        .compact?.(false)
        .layout?.(layout)
        .buttonContent?.(({ node, state }) => {
          const icons = {
            top: (d) => `<div style=${expandStyle}>${d ? '▲' : '▼'}</div>`,
            left: (d) => `<div style=${expandStyle}>${d ? '◀' : '▶'}</div>`,
            bottom: (d) => `<div style=${expandStyle}>${d ? '▼' : '▲'}</div>`,
            right: (d) => `<div style=${expandStyle}>${d ? '▶' : '◀'}</div>`,
          };
          return icons[state.layout](node.children);
        })
        .nodeContent?.((node) => {
          return getTreeNode(node.data, treeType);
        })
        .linkUpdate?.(function (
          this: Element,
          node: d3.HierarchyNode<TreeChartData>
        ) {
          d3.select(this)
            .attr('stroke', getTextStyleFromIndex(node.data.index).color)
            .attr('stroke-width', node.data.strokeWidth ?? null)
            .attr(
              'stroke-dasharray',
              node.data.linkStyle === 'dashed' ? '5 10' : '0'
            );
        })
        .render?.();

      chart.expandToLevel?.();
      chart.fit?.();
    }
  }, [treeData, d3Container.current, fullscreenVisual, chart]);

  useLayoutEffect(() => {
    if (chart && d3Container.current) {
      disableZoomingForWheelEvent(d3Container.current);
    }
  }, [treeData, d3Container.current, fullscreenVisual, chart]);

  return (
    <div
      className={`kpi-tree-chart-container-${treeId} fullscreen-${
        fullscreenVisual === treeId
      }`}
    >
      {chart && (
        <TreeChartControls
          treeId={treeId}
          treeChart={chart}
          visualHeader={visualHeader}
          tab={tab}
          exportAllCharts={exportAllCharts}
          timePeriods={timePeriods}
        />
      )}
      <div ref={d3Container} />
    </div>
  );
};

export default TreeChart;
