import type { LinkedFilesConfig } from './GenAIAdmin.types';
import {
  LINKED_FILES_DEFAULT_VALUE,
  LINKED_FILES_OPTIONS,
} from './GenAiAdmin.constants';

export const compareCheckboxConfigs = (
  object1: LinkedFilesConfig,
  object2: LinkedFilesConfig
): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => object1[key] === object2[key]);
};

export const getLinkedFiles = (selectedOptions: LinkedFilesConfig): string[] =>
  Object.entries(LINKED_FILES_OPTIONS)
    .filter(([key]) => selectedOptions[key])
    .map(([, value]) => value);

export const getOldFormatLinkedFiles = (
  selectedOptions: LinkedFilesConfig
): { file: string; type: string }[] =>
  Object.entries(LINKED_FILES_OPTIONS)
    .filter(([key]) => selectedOptions[key])
    .map(([, value]) => {
      const [file, type] = value.split(' - ');
      return { file: file, type: type };
    });

export const getLinkedFilesConfig = (files: string[] | undefined) =>
  Object.entries(LINKED_FILES_OPTIONS).reduce((acc, [key, value]) => {
    const isOptionSelected = files?.some((option) => option === value);
    return {
      ...acc,
      [key]: isOptionSelected,
    };
  }, LINKED_FILES_DEFAULT_VALUE);
