import {
  useReducer,
  useState,
  useContext,
  type FunctionComponent,
  type FormEvent,
  useRef,
} from 'react';
import axios from 'axios';
import { ChevronLeft } from '@carbon/icons-react';
import {
  Button,
  Dropdown,
  Form,
  NumberInput,
  InlineLoading,
  TextArea,
  TextInput,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import MultiSelect from '../../MultiSelect/MultiSelect';
import {
  MAX_TOKEN_MIN,
  MAX_TOKEN_MAX,
  TEMPERATURE_MIN,
  TEMPERATURE_MAX,
  TOP_P_MIN,
  TOP_P_MAX,
} from '../../GenAIAdmin/GenAiAdmin.constants';
import {
  AIAdminFormReducer,
  initialAIAdminFormState,
} from '../../../reducers/AIAdminFormReducer';
import {
  UPDATE_AI_ADMIN_QUESTION_FORM,
  RESET_AI_ADMIN_QUESTION_FORM,
} from '../../../constants/reducers';
import { AppContext } from '../../../providers/AppProvider';
import apiRequest from '../../../api';
import { CACHE_KEY } from '../../../constants/api';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../../providers/ModalProvider';
import type {
  AIQuestion,
  AIQuestionCreate,
  AIQuestionForm,
} from '../AIAdmin.types';
import { AiQuestionLinkedFiles, AiQuestionStatus } from '../AIAdmin.constants';

export const AIAdminQuestionForm: FunctionComponent = () => {
  const [formState, dispatch] = useReducer(
    AIAdminFormReducer,
    initialAIAdminFormState
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const { bannerId, clearCacheForKey } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuth0();
  const { toggleModal, updateModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const updateIAdminForm = <T extends keyof AIQuestionForm>(
    key: T,
    value: AIQuestion[T]
  ) => {
    dispatch({
      type: UPDATE_AI_ADMIN_QUESTION_FORM,
      key,
      value,
    });
  };

  const resetAIAdminForm = () => {
    dispatch({
      type: RESET_AI_ADMIN_QUESTION_FORM,
    });
  };

  const {
    question,
    question_status,
    linked_files,
    prompt,
    top_p,
    temperature,
    max_tokens,
  } = formState;

  const handleGoBack = (): void => {
    abortControllerRef.current?.abort();
    setIsFormSubmitting(false);
    navigate('/ai-manager');
  };

  const validateNumberInputs = (): boolean => {
    const isMaxTokensValid =
      typeof max_tokens === 'number' &&
      Number.isInteger(max_tokens) &&
      max_tokens >= MAX_TOKEN_MIN &&
      max_tokens <= MAX_TOKEN_MAX;
    const isTemperatureValid =
      typeof temperature === 'number' &&
      temperature >= TEMPERATURE_MIN &&
      temperature <= TEMPERATURE_MAX;
    const isTopPValid =
      typeof top_p === 'number' && top_p >= TOP_P_MIN && top_p <= TOP_P_MAX;

    return isMaxTokensValid && isTemperatureValid && isTopPValid;
  };

  const successConfirmationModal = (questionStatus: AiQuestionStatus): void => {
    const modalData = {
      [AiQuestionStatus.Draft]: {
        title: 'Draft AI Question created',
        text: 'Your draft AI Question has been successfully created. View it on the AI Manager, or create another.',
        primaryCTAText: 'Create another',
        onPrimaryCTAClick: () => {
          resetAIAdminForm();
          toggleModal(false);
        },
      },
      [AiQuestionStatus.Testing]: {
        title: 'Testing AI Question created',
        text: 'Your testing AI Question has been successfully created. View it on the AI Manager, or test it now.',
        primaryCTAText: 'Test now',
        onPrimaryCTAClick: () => {
          toggleModal(false);
          navigate('/home');
        },
      },
      [AiQuestionStatus.Published]: {
        title: 'AI Question published',
        text: 'Your AI Question has been successfully published. View it on the AI Manager, or create another.',
        primaryCTAText: 'Create another',
        onPrimaryCTAClick: () => {
          resetAIAdminForm();
          toggleModal(false);
        },
      },
    };

    updateModal({
      type: 'success',
      title: modalData[questionStatus].title,
      body: modalData[questionStatus].text,
      primaryCTAText: modalData[questionStatus].primaryCTAText,
      secondaryCTAText: 'View AI Manager',
      onSecondaryCTAClick: () => {
        toggleModal(false);
        navigate('/ai-manager');
      },
      onPrimaryCTAClick: modalData[questionStatus].onPrimaryCTAClick,
    });
  };

  const handleFormSubmit = async (): Promise<void> => {
    if (!bannerId) {
      return;
    }
    setIsFormSubmitting(true);
    abortControllerRef.current = new AbortController();

    const payload: AIQuestionCreate = {
      question,
      question_status,
      prompt,
      max_tokens,
      linked_files,
      temperature,
      top_p,
      tdp: bannerId,
    };

    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        '/admin/genai/questions',
        'POST',
        token,
        payload,
        abortControllerRef.current.signal
      );
      clearCacheForKey(CACHE_KEY.GENAI_QUESTIONS);
      successConfirmationModal(question_status);
    } catch (error) {
      if (!axios.isCancel(error)) {
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
        });
      }
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return (
    <>
      <div className="AIAdmin__go-back">
        <Button
          kind="ghost"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          hasIconOnly
          size="sm"
          onClick={handleGoBack}
        />
        <div className="heading-05">Create AI Question</div>
      </div>
      <div className="AIAdmin__card AIAdmin__card--limited">
        <Form className="AIAdmin__form">
          <TextInput
            id="AIAdmin-question"
            data-testid="ai-question-name-input"
            type="text"
            disabled={isFormSubmitting}
            labelText="Enter User's Question"
            value={question}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateIAdminForm('question', e.currentTarget.value)
            }
            placeholder="Type your question"
          />
          <div data-testid="ai-question-linked-files">
            <MultiSelect
              label="Choose option(s)"
              titleText="Select Data"
              id="AIAdmin-data-options"
              items={Object.values(AiQuestionLinkedFiles)}
              selectedItems={linked_files}
              onChange={({ selectedItems }) =>
                updateIAdminForm(
                  'linked_files',
                  selectedItems as AiQuestionLinkedFiles[]
                )
              }
            />
          </div>
          <TextArea
            id="AIAdmin-prompt"
            type="text"
            rows={15}
            disabled={isFormSubmitting}
            labelText="Prompt"
            value={prompt}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateIAdminForm('prompt', e.currentTarget.value)
            }
            placeholder="Question prompt"
          />
          <NumberInput
            id="AIAdmin-max-tokens"
            allowEmpty={true}
            min={MAX_TOKEN_MIN}
            max={MAX_TOKEN_MAX}
            hideSteppers
            label="Maximum Response Length (in tokens)"
            invalidText="1000 tokens are recommended"
            invalid={!Number.isInteger(max_tokens)}
            value={max_tokens}
            disabled={isFormSubmitting}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateIAdminForm('max_tokens', Number(e.currentTarget.value))
            }
          />
          <NumberInput
            id="AIAdmin-temperature"
            allowEmpty={true}
            min={TEMPERATURE_MIN}
            max={TEMPERATURE_MAX}
            helperText="Enter a number between 0 and 1 (e.g., 0.5). Higher values increase the variety of responses."
            hideSteppers
            label="Response Variety (Temperature)"
            invalidText="Value is not valid"
            value={temperature}
            disabled={isFormSubmitting}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateIAdminForm('temperature', Number(e.currentTarget.value))
            }
          />
          <NumberInput
            id="AIAdmin-top-p"
            allowEmpty={true}
            min={TOP_P_MIN}
            max={TOP_P_MAX}
            helperText="Enter a number between 0 and 1. Lower values focus responses on the most likely predictions, while higher values increase variety."
            hideSteppers
            label="Response Precision (Top Predictions)"
            invalidText="Value is not valid"
            value={top_p}
            disabled={isFormSubmitting}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateIAdminForm('top_p', Number(e.currentTarget.value))
            }
          />
          <Dropdown
            id="AIAdmin-status"
            data-testid="ai-question-status"
            label="Choose question status"
            titleText="Status"
            className="AIAdmin__form--status-dropdown"
            disabled={isFormSubmitting}
            items={Object.values(AiQuestionStatus)}
            onChange={({ selectedItem }) =>
              updateIAdminForm('question_status', selectedItem)
            }
            selectedItem={question_status}
          />
          <div className="AIAdmin__form--actions">
            <Button
              kind="secondary"
              size="md"
              onClick={() => navigate('/ai-manager')}
              disabled={isFormSubmitting}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              size="md"
              onClick={handleFormSubmit}
              renderIcon={isFormSubmitting ? InlineLoading : null}
              className={`${isFormSubmitting ? 'has-icon' : ''}`}
              disabled={[
                isFormSubmitting,
                !question,
                !linked_files.length,
                !validateNumberInputs(),
              ].some(Boolean)}
            >
              {isFormSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
