import type { FunctionComponent, ReactNode, ChangeEvent } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbarSearch,
  Tag,
  IconButton,
} from '@carbon/react';
import '../../styles/components/AIAdmin.scss';
import AI from '../../assets/icons/Ai';
import type { AIQuestion } from './AIAdmin.types';
import NoDataPlaceholder from '../ReportTable/NoDataPlaceholder';
import { useNavigate } from 'react-router-dom';
import {
  aiAdminTableHeaders,
  aiQuestionStatusNames,
} from './AIAdmin.constants';
import {
  type CarbonIconType,
  Edit,
  Replicate,
  TrashCan,
} from '@carbon/icons-react';

interface Props {
  readonly questions: AIQuestion[];
}

const AIQuestions: FunctionComponent<Props> = (props) => {
  const { questions } = props;

  const navigate = useNavigate();

  const getCellContent = ({
    row,
    cell,
  }: {
    row: CarbonSelectedRow;
    cell: CarbonCell<string>;
  }): ReactNode => {
    const aiAdminTableActions: {
      label: 'Edit' | 'Duplicate' | 'Delete';
      onClick?: () => void;
      icon: CarbonIconType;
    }[] = [
      {
        label: 'Edit',
        onClick: () => navigate(`/ai-manager/edit?question=${row.id}`),
        icon: Edit,
      },
      {
        label: 'Duplicate',
        icon: Replicate,
      },
      {
        label: 'Delete',
        icon: TrashCan,
      },
    ];

    switch (cell.info.header) {
      case 'question_status': {
        const status = cell.value as AIQuestion['question_status'];
        const statusName = aiQuestionStatusNames[status];

        return (
          <div className="AIAdmin__table--status">
            <Tag size="sm" className={`status-tag ${status}`}>
              {statusName}
            </Tag>
          </div>
        );
      }

      case 'actions': {
        return (
          <div className="AIAdmin__table--actions">
            {aiAdminTableActions.map(({ label, onClick, icon: Icon }) => (
              <IconButton
                key={label}
                label={label}
                kind="ghost"
                size="sm"
                onClick={onClick}
              >
                <Icon />
              </IconButton>
            ))}
          </div>
        );
      }

      case 'last_updated_timestamp': {
        return new Date(cell.value).toLocaleDateString();
      }

      default:
        return cell.value;
    }
  };

  const getNoDataPlaceholder = (): ReactNode =>
    questions.length > 0 ? (
      <NoDataPlaceholder
        title="No AI questions found"
        description={{
          info: 'Please refine your search and try again',
        }}
        icon={AI}
      />
    ) : (
      <NoDataPlaceholder
        title="No AI questions found"
        description={{
          info: 'It looks like you haven’t created any questions yet. To get started, please use the button below to create a new question.',
        }}
        icon={AI}
        buttonLabel="Create AI Question"
        onClick={() => navigate('/ai-manager/create')}
        buttonIcon={AI}
      />
    );

  return (
    <DataTable headers={aiAdminTableHeaders} rows={questions}>
      {({
        rows,
        headers,
        getRowProps,
        getTableProps,
        getTableContainerProps,
        onInputChange,
      }: {
        rows: CarbonSelectedRow[];
        headers: Header[];
        getRowProps;
        getTableProps;
        getTableContainerProps;
        onInputChange: (event: ChangeEvent) => void;
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <TableToolbarSearch
            persistent
            size="md"
            className="AIAdmin__table--search"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onInputChange(event)
            }
          />
          {rows.length > 0 ? (
            <Table {...getTableProps()} className="AIAdmin__table--content">
              <TableHead>
                <TableRow>
                  {headers.map(({ header, key }) => (
                    <TableHeader key={key}>{header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent({ row, cell })}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            getNoDataPlaceholder()
          )}
        </TableContainer>
      )}
    </DataTable>
  );
};

export default AIQuestions;
