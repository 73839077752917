/* eslint-disable indent */
import type { FunctionComponent, ReactNode } from 'react';
import { Information } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import type { BaseCardProps } from './BaseCard';
import BaseCard from './BaseCard';
import { formatNumber } from '../../utils/reportUtils';
import Indicator from './Indicator';
import type { Settings } from 'react-slick';
import Slider from 'react-slick';
import ConditionalWrapper from '../ConditionalWrapper';
import { METRICS_CARDS_PER_PAGE } from '../../constants/values';
import CustomSliderArrow from './CustomSliderArrow';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import { slideChangeClick } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import Tooltip from '../Tooltip';

interface HomeCombinedCardProps extends BaseCardProps {
  items: Card[];
  visualHeader?: VisualHeader;
  hideIndicator?: boolean;
  hasPlaceholderItems?: boolean;
}

interface PlaceholderCardItem {
  isPlaceholder?: boolean;
  title: string;
}

type CardItem = Card | PlaceholderCardItem;

export const sliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: METRICS_CARDS_PER_PAGE,
  slidesToScroll: METRICS_CARDS_PER_PAGE,
  nextArrow: <CustomSliderArrow direction="right" />,
  prevArrow: <CustomSliderArrow direction="left" />,
};

const ReportMetricsCard: FunctionComponent<HomeCombinedCardProps> = ({
  items,
  visualHeader,
  hideIndicator,
  hasPlaceholderItems = true,
  borderColour,
}) => {
  const [cardItems, setCardItems] = useState<CardItem[]>([]);
  const posthogEvent = usePosthog();

  const sliderWrapper = (children: ReactNode) => (
    <Slider
      {...sliderSettings}
      afterChange={() => posthogEvent(slideChangeClick)}
    >
      {children}
    </Slider>
  );

  const sliderItemWrapper = (children: ReactNode) => <div>{children}</div>;

  useEffect(() => {
    if (items) {
      const newItems: CardItem[] = [...items];
      const placeholderItemsNumber = hasPlaceholderItems
        ? METRICS_CARDS_PER_PAGE - (newItems.length % METRICS_CARDS_PER_PAGE)
        : 0;
      if (
        placeholderItemsNumber > 0 &&
        placeholderItemsNumber < METRICS_CARDS_PER_PAGE
      ) {
        const placeholderItems: { isPlaceholder: boolean; title: string }[] =
          [];
        for (let index = 0; index < placeholderItemsNumber; index++) {
          placeholderItems.push({
            isPlaceholder: true,
            title: `placeholder_${index}`,
          });
        }
        newItems.push(...placeholderItems);
      }
      setCardItems(newItems);
    }
  }, [items]);

  const hasNoHeader = !visualHeader?.icon && !visualHeader?.title;

  const classes = [
    'ReportMetricsCard__container',
    cardItems.length > 5 ? 'with-slider' : '',
    hasNoHeader ? 'no-divider' : 'with-divider',
    !hasPlaceholderItems ? 'reduced-size' : '',
  ]
    .filter((className) => !!className)
    .join(' ');

  return (
    <BaseCard
      title={visualHeader?.title}
      icon={visualHeader?.icon}
      borderColour={borderColour}
    >
      <div className={classes} data-testid="card-grid">
        <ConditionalWrapper
          condition={cardItems.length > 5}
          wrapper={sliderWrapper}
        >
          {cardItems.map((item) => {
            const { title, data, child, tooltip } = item as Card;
            const { data: childData, index: childIndex } = child ?? {};
            const { value, format, currency } = childData ?? {};
            const formatOptions: Intl.NumberFormatOptions =
              typeof data?.value === 'number' && data.value >= 10000
                ? {
                    notation: 'compact',
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                  }
                : {};
            return !(item as PlaceholderCardItem).isPlaceholder ? (
              <ConditionalWrapper
                condition={cardItems.length > 5}
                wrapper={sliderItemWrapper}
                key={title}
              >
                <div
                  className={`ReportMetricsCard__card-item ${
                    hideIndicator
                      ? 'ReportMetricsCard__card-item--hide-indicator'
                      : ''
                  }`}
                  data-testid={`card-${title}`}
                >
                  <div className="card--title">
                    {title}
                    {tooltip ? (
                      <Tooltip
                        description={tooltip}
                        align="bottom"
                        useButtonWrapper
                      >
                        <Information size="16px" />
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="card--home-info" data-testid="home-card">
                    <div
                      className="card--home-info__value"
                      title={formatNumber(data)}
                    >
                      {formatNumber(data, formatOptions)}
                    </div>
                    {!hideIndicator &&
                      typeof value === 'number' &&
                      childIndex && (
                        <div className="card--home-info__pct">
                          <Indicator
                            value={value}
                            format={format}
                            currency={currency}
                            index={childIndex}
                            showArrow={false}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </ConditionalWrapper>
            ) : (
              <div key={title} />
            );
          })}
        </ConditionalWrapper>
      </div>
    </BaseCard>
  );
};

export default ReportMetricsCard;
