import type { ChartOptions, SeriesScatterOptions } from 'highcharts';
import Chart from './Chart';
import type { FunctionComponent } from 'react';
import type { VisualData } from '../../reducers/ReportReducer';
import { scatterTooltip } from '../Report/TooltipFormatters';
import {
  customLabel,
  getScatterData,
  getTooltipKey,
} from '../../utils/chartUtils';

export interface ScatterData {
  PRODUCT: string;
  comp_rank1: NumberPoint;
  comp_rank2: NumberPoint;
  comp_rank3: NumberPoint;
  id: string;
  rank_quad: NumberPoint;
  risk_pct: NumberPoint;
  risk_spend: NumberPoint;
  spend: NumberPoint;
  tfr_pct: NumberPoint;
  tfr_spend: NumberPoint;
}

interface ScatterProps {
  visualData: VisualData;
  reportTemplateId: string;
}

export interface ScatterInfo {
  series: SeriesScatterOptions[];
  tooltipMap: { [key: string]: ScatterData };
}

let topLabel;
let bottomLabel1;
let bottomLabel2;

const ScatterWithSummary: FunctionComponent<ScatterProps> = ({
  visualData,
  reportTemplateId,
}) => {
  const { series, tooltipMap } = getScatterData(
    visualData.seriesData as ScatterData[]
  );

  return (
    <Chart
      reportTemplateId={reportTemplateId}
      options={{
        series,
        legend: {
          enabled: false,
        },
        xAxis: { ...visualData.xAxis, labels: { enabled: false } },
        yAxis: { ...visualData.yAxis, labels: { enabled: false } },
        chart: {
          height: visualData.chart?.height,
          zoomType: 'xy',
          type: 'scatter',
          plotBorderWidth: 1,
          events: {
            redraw: function () {
              if (topLabel) topLabel.destroy();
              if (bottomLabel1) bottomLabel1.destroy();
              if (bottomLabel2) bottomLabel2.destroy();

              topLabel = customLabel(this, 'Top', 10, this.chartHeight - 16);
              bottomLabel1 = customLabel(this, 'Bottom', -50, 20, {
                transform: 'rotate(-90deg)',
              });
              bottomLabel2 = customLabel(
                this,
                'Bottom',
                this.chartWidth - 54,
                this.chartHeight - 10
              );
            },
          },
        } as ChartOptions,
        tooltip: {
          formatter: function () {
            const tooltipKey = getTooltipKey(
              this.x as number,
              this.y as number
            );
            const { PRODUCT, comp_rank1, comp_rank2, spend } =
              tooltipMap[tooltipKey];

            return scatterTooltip(
              PRODUCT,
              comp_rank1.value,
              comp_rank2.value,
              spend
            );
          },
        },
      }}
    />
  );
};

export default ScatterWithSummary;
