import type { BaseCardProps } from '../components/Cards/BaseCard';

export interface Category {
  category: string;
  category_name: string;
  location?: string | number | null;
}
export interface Location {
  location: string | null;
  location_name: string;
}

export interface CompareTimePeriod {
  compTimeperiod: number;
  compTimeperiodName: string;
}

export interface HomeGridRow {
  id: string;
  PRODUCT: string;
  spend: NumberPoint;
  spend_pchg: NumberPoint;
  spv: NumberPoint;
  spv_pchg: NumberPoint;
  visits: NumberPoint;
  visits_pchg: NumberPoint;
}

type CARD_TYPE = 'summary-card' | 'yoy-card';

export interface HomeSection {
  title: string;
  type: CARD_TYPE | 'cell-grid';
  data: BaseCardProps[] | GridResponse<HomeGridRow>;
  stickyColumns?: number[];
}

export interface TimePeriod {
  label: string;
  startDate: string;
  endDate: string;
}

export interface HomeData {
  [key: string]: HomeSection[];
}
export interface HomeState {
  selectedCategory: HierNode | null;
  categories: HierNode[];
  dropdowns: DropdownConfig[] | null;
  homeDropdownSelections: string | null;
  periodEnd: string | null;
  productKeys: {
    [key: string]: string;
  };
  reportConfigMap: {
    [key: string]: string[];
  };
  timePeriods: {
    [key: string]: TimePeriod[];
  };
}

export const initialHomeState: HomeState = {
  selectedCategory: null,
  categories: [],
  dropdowns: null,
  homeDropdownSelections: null,
  periodEnd: null,
  reportConfigMap: {},
  productKeys: {},
  timePeriods: {},
};

interface UpdateCategories {
  type: 'UPDATE_CATEGORIES';
  categories: HierNode[];
}

interface UpdatePeriodEndData {
  type: 'UPDATE_PERIOD_END_DATA';
  periodEnd: string;
}

interface UpdateSelectedCategoryId {
  type: 'SET_SELECTED_CATEGORY';
  selectedCategory: HierNode | null;
}

interface UpdateReportConfigMap {
  type: 'UPDATE_REPORT_CONFIG_MAP';
  reportConfigMap: {
    [key: string]: string[];
  };
}

interface UpdateProductKeys {
  type: 'UPDATE_PRODUCT_KEYS';
  productKeys: {
    [key: string]: string;
  };
}

interface UpdateHomepageDropdowns {
  type: 'UPDATE_HOMEPAGE_DROPDOWNS';
  dropdowns: DropdownConfig[];
}

interface UpdateDropdownSelections {
  type: 'UPDATE_DROPDOWN_SELECTIONS';
  dropdownKey: string;
}

interface UpdateTimePeriods {
  type: 'UPDATE_TIME_PERIODS';
  dropdownKey: string;
  timePeriod: TimePeriod[];
}

interface ResetAllHomeData {
  type: 'RESET_ALL_HOME_DATA';
}

export type HomeAction =
  | UpdateCategories
  | UpdatePeriodEndData
  | UpdateSelectedCategoryId
  | ResetAllHomeData
  | UpdateReportConfigMap
  | UpdateProductKeys
  | UpdateHomepageDropdowns
  | UpdateDropdownSelections
  | UpdateTimePeriods;

const homeReducer = (state: HomeState, action: HomeAction): HomeState => {
  switch (action.type) {
    case 'UPDATE_CATEGORIES':
      return {
        ...state,
        categories: action.categories,
      };

    case 'UPDATE_PERIOD_END_DATA':
      return {
        ...state,
        periodEnd: action.periodEnd,
      };

    case 'SET_SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case 'UPDATE_REPORT_CONFIG_MAP':
      return {
        ...state,
        reportConfigMap: action.reportConfigMap,
      };
    case 'UPDATE_PRODUCT_KEYS':
      return {
        ...state,
        productKeys: action.productKeys,
      };
    case 'UPDATE_HOMEPAGE_DROPDOWNS':
      return {
        ...state,
        dropdowns: action.dropdowns,
      };
    case 'UPDATE_DROPDOWN_SELECTIONS':
      return {
        ...state,
        homeDropdownSelections: action.dropdownKey,
      };
    case 'UPDATE_TIME_PERIODS':
      return {
        ...state,
        timePeriods: {
          ...state.timePeriods,
          [action.dropdownKey]: action.timePeriod,
        },
      };
    case 'RESET_ALL_HOME_DATA':
      return initialHomeState;
  }
};

export default homeReducer;
