/* eslint-disable indent */
import type { FunctionComponent, ReactNode } from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  InlineLoading,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from '@carbon/react';
import { Close, Document } from '@carbon/icons-react';
import '../styles/components/TermsAndConditionsModal.scss';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../api';
import { ModalContext } from '../providers/ModalProvider';
import jwt_decode from 'jwt-decode';
import {
  COOKIES_POLICY,
  PLATFORM_ACCESS_TERMS,
  PRIVACY_NOTICE,
} from '../constants/termsAndPolicies';
import usePosthog from '../utils/posthog';
import { termsAndConditionsTabClick } from '../constants/posthog';
import type { TERMS_STATUS } from '../constants/metadata';
import type { ResponsePayload } from './Fetch';
import type { AcceptanceStatusResponse } from '../types/apiTypes';

export interface TermsTab {
  label: string;
  content: ReactNode;
}

interface TermsAndConditionsModalProps {
  isSubmitButtonVisible?: boolean;
  optionalTerms?: TermsTab[];
  onClose?: () => void;
  onSubmit?: (status: TERMS_STATUS) => void;
}

const contentTabs = [
  { label: 'Access Terms', content: PLATFORM_ACCESS_TERMS },
  { label: 'Cookies Policy', content: COOKIES_POLICY },
  { label: 'Privacy Notice', content: PRIVACY_NOTICE },
];

const TermsAndConditionsModal: FunctionComponent<
  TermsAndConditionsModalProps
> = ({
  isSubmitButtonVisible = true,
  optionalTerms = [],
  onClose,
  onSubmit,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { updateModal, toggleModal } = useContext(ModalContext);
  const [submitting, setSubmitting] = useState(false);
  const posthogEvent = usePosthog();

  const handleAccept = async () => {
    try {
      setSubmitting(true);
      const accessToken = await getAccessTokenSilently();
      const decodedAccessToken: Auth0AccessToken = jwt_decode(accessToken);
      const response = await apiRequest<
        ResponsePayload<AcceptanceStatusResponse>
      >(
        `/users/${encodeURIComponent(
          decodedAccessToken.sub
        )}/terms-and-conditions/accept`,
        'POST',
        accessToken
      );
      onSubmit?.(response.data.acceptance_status);
      toggleModal(false);
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue accepting Terms & Policies. Please try again.',
        onPrimaryCTAClick: onClose,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose?.();
    toggleModal(false);
  };

  const handleTabClick = (tab: string) => {
    posthogEvent(termsAndConditionsTabClick, {
      tab,
    });
  };

  return (
    <div className="TermsAndConditions__container">
      <div className="TermsAndConditions__close-button">
        <Close
          className="hoverable clickable"
          data-testid="terms-and-conditions-close-button"
          onClick={handleClose}
          size={24}
        />
      </div>
      <div className="TermsAndConditions__header">
        <div className="TermsAndConditions__header-title">
          <Document size={24} /> Terms & Policies
        </div>
        {isSubmitButtonVisible && (
          <p className="TermsAndConditions__header-description">
            To continue, please read and accept the Terms & Conditions. The
            “Accept & Sign In” button only becomes active after scrolling to the
            bottom of the terms and conditions.
          </p>
        )}
      </div>
      <Tabs>
        <TabList aria-label="Terms and Policies ">
          {[...contentTabs, ...optionalTerms].map(({ label }) => (
            <Tab key={label} onClick={() => handleTabClick(label)}>
              {label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {[...contentTabs, ...optionalTerms].map(({ content, label }) => {
            const props =
              typeof content === 'string'
                ? {
                    dangerouslySetInnerHTML: {
                      __html: content,
                    },
                  }
                : { children: content };
            return (
              <TabPanel key={label}>
                <div className="TermsAndConditions__content" {...props} />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
      {isSubmitButtonVisible && (
        <Button
          className={`TermsAndConditions__accept-terms-button${
            submitting ? ' has-icon' : ''
          }`}
          onClick={handleAccept}
          disabled={submitting}
          renderIcon={submitting ? InlineLoading : null}
        >
          Accept & Sign In
        </Button>
      )}
    </div>
  );
};

export default TermsAndConditionsModal;
