/* eslint-disable indent */
import { removeApostrophe } from '../../../utils';
import { searchDeepNode } from '../../../utils/reportUtils';
import type { SelectionObject } from '../ReportBuilder';

export const showHierarchyTree = (
  hierarchies,
  selectedHierarchy: Hierarchy
) => {
  return (
    hierarchies &&
    selectedHierarchy &&
    hierarchies[selectedHierarchy.id] &&
    selectedHierarchy.id !== 'dates'
  );
};

export const levelExists = (selection: SelectionObject, level: string) => {
  return !!selection[level];
};

export const idExistsInLevel = (
  selection: SelectionObject,
  level: string,
  id
) => {
  return selection[level].map((n) => n.id).includes(id);
};

const removeItem = (selection: SelectionObject, level: string, id: string) => {
  return selection[level].filter((n) => n.id !== id);
};

const addItem = (selection: SelectionObject, node: HierNode) => {
  return [
    ...selection[node.level],
    { ...node, label: removeApostrophe(node.label) },
  ];
};

export const isSelectionEmpty = (selection: SelectionObject) => {
  const levels = Object.keys(selection);
  return levels.length > 0
    ? levels.every((level) => selection[level].length === 0)
    : true;
};

export const getUpdatedSelection = (
  selection: SelectionObject,
  node: HierNode
) => {
  const { level, id, label } = node;
  if (levelExists(selection, level)) {
    if (idExistsInLevel(selection, level, id)) {
      return removeItem(selection, level, id);
    } else {
      return addItem(selection, node);
    }
  } else {
    return [{ ...node, label: removeApostrophe(label) }];
  }
};

export const getNodeId = (node: HierNode | HierarchyParam) => {
  return node.level + '_' + node.id;
};

export const getFavouriteNode = (
  hierarchies: {
    [key: string]: HierarchyResponse;
  },
  selectedHierarchy: Hierarchy,
  favouriteGroups: FavouriteGroupItems[]
) => {
  const children: HierNode[] = favouriteGroups.map(
    ({ group_name, group_items }, index) => ({
      favouriteNodeType: 'favourite_group',
      id: `favourite_${index}_${group_name}`,
      label: group_name,
      level: '2',
      excludeSelect: true,
      children:
        hierarchies[selectedHierarchy.id] &&
        group_items.map(({ item_ids, item_name }) => {
          const parameters = item_ids
            .map((id) =>
              searchDeepNode(
                hierarchies[selectedHierarchy.id].hierarchy as HierNode[],
                id
              )
            )
            .filter((param) => !!param);
          const parameterIds = parameters.map(({ id }) => id);
          const parameter =
            parameters.length === 1 ? { ...parameters[0] } : null;
          return {
            ...parameter,
            favouriteNodeType: 'favourite_parameter',
            label: item_name,
            id:
              parameters.length > 1
                ? `favourite_parameter_${parameterIds.join('_')}`
                : (parameter?.id as string),
            groupedItems: parameters.length > 1 ? parameters : undefined,
            level: parameters.length > 1 ? '3' : (parameter?.level as string),
          };
        }),
    })
  );

  const favourites: HierNode = {
    id: 'Favourites',
    label: 'Favourites',
    level: '1',
    favouriteNodeType: 'favourite_title',
    excludeSelect: true,
    children: children,
  };

  return children?.length > 0 ? [favourites] : [];
};

export const getFavouriteNodes = (
  favouriteGroups: FavouriteGroup[],
  selectedHierarchy: Hierarchy,
  hierarchies: {
    [key: string]: HierarchyResponse;
  },
  bannerId: number,
  groupId: number
) => {
  const favouritesForHierarchy = favouriteGroups.find(
    ({ hierarchy_name }) => hierarchy_name === selectedHierarchy.name
  );
  const favouriteNodes =
    favouritesForHierarchy &&
    favouritesForHierarchy.tdp_id === bannerId &&
    favouritesForHierarchy.user_group === groupId
      ? getFavouriteNode(
          hierarchies,
          selectedHierarchy,
          favouritesForHierarchy.favourite_groups
        )
      : [];
  return favouriteNodes;
};

export const getInvalidNode = (node: HierNode): boolean =>
  !node.id && !node.groupedItems?.length && !node.children?.length;

interface ChildSelection {
  id: string;
  label: string;
  level: string;
}

export const selectChildLevel = (
  node: HierNode,
  level: string
): {
  nodeLevel: string;
  selections: ChildSelection[];
} => {
  const childrenAtLevel = node.children?.filter((child) => {
    const childLevel = child.levelId ?? child.level;
    return childLevel === level;
  });
  if (childrenAtLevel && childrenAtLevel.length > 0) {
    const selections = childrenAtLevel.map(({ id, label, level, levelId }) => ({
      id,
      label,
      level,
      levelId,
    }));
    const nodeLevel = node.levelId ? Number(node.level) + 1 : level;
    return { nodeLevel: nodeLevel.toString(), selections };
  } else {
    const selected: ChildSelection[] = [];

    node.children?.forEach((child) => {
      const { selections } = selectChildLevel(child, level);
      selected.push(...selections);
    });

    return { nodeLevel: level, selections: selected };
  }
};
