/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { Fragment, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Button, Search } from '@carbon/react';
import { SidePanelClose } from '@carbon/icons-react';
import '../../styles/components/itemOrder.scss';
import DraggableItem from './DraggableItem';
import DroppableColumn from './DroppableColumn';
import {
  getItemOrderPosthogEventParams,
  onDragEnd,
} from '../../utils/itemOrder';
import IconTitle from '../IconTitle';
import Tooltip from '../Tooltip';
import { getInitialSearchState } from '../ColumnOrder';
import usePosthog from '../../utils/posthog';

export interface DraggableItemType {
  name: string;
  id: number;
  isDragDisabled: boolean;
}

export interface List {
  listId: string;
  title: string;
  icon: JSX.Element;
  items: DraggableItemType[];
  kind: 'primary' | 'secondary';
  searchable?: boolean;
  dropDescription?: string;
}

interface MoveAll {
  label: string;
  description: string;
}
interface ItemOrderProps {
  lists: List[];
  onListUpdate: (lists: List[]) => void;
  onUpdateSearch: (searchState: { [key: string]: string }) => void;
  searchState: { [key: string]: string };
  moveAll?: MoveAll;
  portalId?: string;
}

const ItemOrder: FunctionComponent<ItemOrderProps> = ({
  lists,
  searchState,
  onListUpdate,
  onUpdateSearch,
  moveAll,
  portalId,
}) => {
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const posthogEvent = usePosthog();

  const columnHeights = useRef<HTMLDivElement[]>([]);

  return (
    <DragDropContext
      onDragEnd={({ source, destination, draggableId }) => {
        if (!destination) {
          return;
        }
        const newLists = onDragEnd(source, destination, lists);
        onListUpdate(newLists);
        setIsDragging(null);
        const eventParams = getItemOrderPosthogEventParams(
          draggableId,
          newLists,
          source,
          destination
        );
        if (eventParams) {
          const { eventName, payload } = eventParams;
          posthogEvent(eventName, payload);
        }
      }}
      onDragStart={(start) => {
        setIsDragging(start.source.droppableId);
      }}
    >
      <div className="item-order">
        {lists.map((list, index) => {
          const {
            title,
            listId,
            items,
            icon,
            kind,
            searchable,
            dropDescription,
          } = list;
          return (
            <Fragment key={listId}>
              <div
                className="item-order__column"
                ref={(el) => {
                  if (el) {
                    columnHeights.current[index] = el;
                  }
                }}
                style={
                  isDragging
                    ? {
                        height:
                          columnHeights.current[index].clientHeight + 'px',
                      }
                    : undefined
                }
              >
                <IconTitle
                  icon={icon}
                  title={`${items.length} ${title}`}
                  kind="secondary"
                  faded={kind === 'secondary'}
                  titleSize="body-02"
                />
                {searchable && (
                  <div className="item-order__column--search">
                    <Search
                      placeholder="Search"
                      className="item-order__item-search"
                      labelText=""
                      data-testid={`${listId}-search`}
                      value={searchState[listId]}
                      onChange={(e) =>
                        onUpdateSearch({
                          ...searchState,
                          [listId]: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
                <div
                  data-testid={`droppable-list-${listId}`}
                  className="item-order__droppable-list"
                  style={{ height: `calc(100% - ${searchable ? 120 : 60}px)` }}
                >
                  <DroppableColumn id={listId}>
                    <>
                      {items.map((item, index) => {
                        const { id, name, isDragDisabled } = item;
                        return item.name
                          .toLowerCase()
                          .match(searchState[listId]?.toLowerCase()) ? (
                          <div data-testid={`${listId}-item`} key={id}>
                            <DraggableItem
                              draggableId={id}
                              index={index}
                              isDragDisabled={isDragDisabled}
                              portalId={portalId}
                            >
                              <div>{name}</div>
                            </DraggableItem>
                          </div>
                        ) : null;
                      })}
                      {items.length === 0 && (
                        <div className="item-order__drop-placeholder-container">
                          <div className="item-order__drop-placeholder">
                            {dropDescription}
                          </div>
                        </div>
                      )}
                    </>
                  </DroppableColumn>
                </div>
              </div>
              {moveAll && index === 0 && (
                <Tooltip label={moveAll.label} align="bottom">
                  <Button
                    data-testid="set-all-visible-button"
                    hasIconOnly
                    tooltipPosition="bottom"
                    renderIcon={SidePanelClose}
                    iconDescription={moveAll.description}
                    kind="ghost"
                    size="sm"
                    onClick={() => {
                      const firstList = {
                        ...lists[0],
                        items: [...lists[0].items, ...lists[1].items],
                      };
                      const secondList = { ...lists[1], items: [] };

                      onListUpdate([firstList, secondList]);
                      onUpdateSearch(getInitialSearchState(lists));
                    }}
                  />
                </Tooltip>
              )}
            </Fragment>
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default ItemOrder;
