import type { DynamicCacheKey } from '../constants/api';
import {
  UPDATE_CALENDAR_DATA,
  UPDATE_DIMENSIONS_DATA,
  UPDATE_REPORT_PARAMETERS,
  UPDATE_HIERARCHY_DATA,
  UPDATE_QUERY_CACHE,
  UPDATE_REPORT_MODULES,
  UPDATE_USER,
  EXPAND_SIDEBAR,
  UPDATE_USER_GROUP_ID,
  INITIALISE_APP_DATA,
  UPDATE_FAVOURITE_GROUPS,
} from '../constants/reducers';

interface InitialiseAppData {
  type: 'INITIALISE_APP_DATA';
  user: User | null;
  bannersAndUserGroups: Banner[];
  bannerId: number;
  groupId: number;
}
interface UpdateDimensions {
  type: 'UPDATE_DIMENSIONS_DATA';
  dimensions: Dimension[];
}

interface UpdateReportParameters {
  type: 'UPDATE_REPORT_PARAMETERS';
  reportParameters: ReportParameters;
}

interface UpdateHierarchies {
  type: 'UPDATE_HIERARCHY_DATA';
  hierarchyId: string;
  hierarchy: HierarchyResponse;
}

interface UpdateCalendarData {
  type: 'UPDATE_CALENDAR_DATA';
  calendarId: string;
  dates: FormattedDate[];
}

interface UpdateReportModules {
  type: 'UPDATE_REPORT_MODULES';
  reportModules: ReportSection[];
}

interface UpdateUser {
  type: 'UPDATE_USER';
  user: User;
}

interface UpdateUserGroupId {
  type: 'UPDATE_USER_GROUP_ID';
  groupId: number;
}

interface UpdateQueryCache {
  type: 'UPDATE_QUERY_CACHE';
  cacheKey: DynamicCacheKey;
  bannerId: number | undefined;
}

interface ExpandSidebar {
  type: 'EXPAND_SIDEBAR';
  expandSidebar: boolean;
}

interface UpdateFavouriteGroups {
  type: 'UPDATE_FAVOURITE_GROUPS';
  favouriteGroups: FavouriteGroup[];
}

export type GlobalAction =
  | InitialiseAppData
  | UpdateDimensions
  | UpdateReportParameters
  | UpdateHierarchies
  | UpdateCalendarData
  | UpdateReportModules
  | UpdateUser
  | UpdateUserGroupId
  | UpdateQueryCache
  | ExpandSidebar
  | UpdateFavouriteGroups;

export const initialAppState: GlobalState = {
  dimensions: [],
  hierarchies: {},
  reportParameters: {},
  calendars: {},
  queryCache: {},
  reportModules: [],
  bannersAndUserGroups: [],
  user: null,
  bannerId: null,
  groupId: null,
  expandedSidebar: true,
  favouriteGroups: [],
};

const appReducer = (state: GlobalState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case INITIALISE_APP_DATA:
      return {
        ...state,
        user: action.user,
        bannersAndUserGroups: action.bannersAndUserGroups,
        bannerId: action.bannerId,
        groupId: action.groupId,
        queryCache: {},
        dimensions: [],
        hierarchies: {},
        favouriteGroups: [],
      };

    case UPDATE_DIMENSIONS_DATA:
      return {
        ...state,
        dimensions: action.dimensions,
      };

    case UPDATE_REPORT_PARAMETERS:
      return {
        ...state,
        reportParameters: action.reportParameters,
      };

    case UPDATE_HIERARCHY_DATA:
      return {
        ...state,
        hierarchies: {
          ...state.hierarchies,
          [action.hierarchyId]: action.hierarchy,
        },
      };

    case UPDATE_CALENDAR_DATA:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          [action.calendarId]: action.dates,
        },
      };

    case UPDATE_REPORT_MODULES:
      return { ...state, reportModules: action.reportModules };

    case UPDATE_USER:
      return { ...state, user: action.user };

    case UPDATE_USER_GROUP_ID:
      return {
        ...state,
        groupId: action.groupId,
        queryCache: {},
        reportModules: [],
        dimensions: [],
        hierarchies: {},
      };

    case UPDATE_QUERY_CACHE:
      return {
        ...state,
        queryCache: {
          ...state.queryCache,
          [action.cacheKey]: action.bannerId,
        },
      };

    case EXPAND_SIDEBAR:
      return { ...state, expandedSidebar: action.expandSidebar };

    case UPDATE_FAVOURITE_GROUPS:
      return { ...state, favouriteGroups: action.favouriteGroups };

    default:
      return state;
  }
};

export default appReducer;
