import type { ReactNode } from 'react';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  DatePicker,
  DatePickerInput,
  Search,
  Tag,
} from '@carbon/react';
import {
  Filter,
  UserAvatar,
  WatsonHealthStatusAcknowledge,
  FilterRemove,
  Report,
  Information,
  Redo,
} from '@carbon/icons-react';
import MultiSelect from '../MultiSelect/MultiSelect';
import { useSearchParams } from 'react-router-dom';
import Fetch from '../Fetch';
import { AppContext } from '../../providers/AppProvider';
import { REPORT_STATUS } from '../Workspace';
import { getFilterParam } from '../../utils/filterUtils';
import { ReportFilterContext } from '../../providers/ReportFilterProvider';
import type { FilterState } from '../../reducers/ReportFilterReducer';
import { tabInitialState } from '../../reducers/ReportFilterReducer';
import { CACHE_KEY } from '../../constants/api';
import NoDataPlaceholder from './NoDataPlaceholder';
import { DateTime } from 'luxon';
import usePosthog from '../../utils/posthog';
import {
  workspaceDeleteInfoPopoverClick,
  workspacePageFiltersBtnClick,
  workspacePageFiltersCheckboxClick,
  workspacePageSearchClick,
} from '../../constants/posthog';
import Popover from '../Popover';
import type { FilterConfig } from '../FiltersContent';
import FiltersContent from '../FiltersContent';
import '../../styles/components/filtersPopover.scss';

interface TableFiltersProps {
  rowCount: number;
  reportTypesOptions: string[] | null;
  tabKey: string;
  isStatusFilterHidden?: boolean;
  isTableEmpty?: boolean;
}

const statuses = [REPORT_STATUS.COMPLETED, REPORT_STATUS.FAILED];

const ReportTableFilters = ({
  reportTypesOptions,
  rowCount,
  tabKey,
  isStatusFilterHidden,
  isTableEmpty,
}: TableFiltersProps) => {
  const { groupId, user } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    tabs,
    users,
    updateUserList,
    changeTabFilter,
    setTabInitialState,
    resetTabFilters,
  } = useContext(ReportFilterContext);
  const [isRefReady, setIsRefReady] = useState(false);
  const startDateRangeRef = useRef<HTMLDivElement | null>(null);
  const endDateRangeRef = useRef<HTMLDivElement | null>(null);
  const posthogEvent = usePosthog();
  const activeTabState = tabs[tabKey];
  const userFilterItem = user ? [{ user_id: user.id, name: user.name }] : [];

  const handleChangeOption = <T extends keyof FilterState>(
    option: T,
    value: FilterState[T]
  ) => {
    if (value && value.length > 0) {
      const paramValue = getFilterParam(option, value);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        [option]: paramValue,
      });
    } else {
      searchParams.delete(option);
      setSearchParams(Object.fromEntries(searchParams));
    }
    changeTabFilter(tabKey, option, value);
  };

  useLayoutEffect(() => {
    setIsRefReady(true);
  }, []);

  useEffect(() => {
    const params = {};
    for (const param of searchParams.entries()) {
      const [key, value] = param;
      let paramValue;
      switch (key) {
        case 'report_name':
        case 'sharing':
          paramValue = value;
          break;

        case 'end_date':
        case 'start_date':
          paramValue = [value ? new Date(value) : null];
          break;

        default:
          paramValue = value ? value.split(',') : [];
          break;
      }
      params[key] = paramValue;
    }
    setTabInitialState(tabKey, {
      ...activeTabState,
      ...params,
    });
  }, []);

  const changeFilterValue = (
    option: keyof FilterState,
    checked: boolean,
    value: string | number
  ) => {
    const currentValues = activeTabState[option] as string[];
    const newValues = checked
      ? [...currentValues, value.toString()]
      : currentValues.filter((currentValue) => currentValue !== value);
    posthogEvent(workspacePageFiltersCheckboxClick, {
      activeTab: tabKey,
      type: option,
      checkedValues: newValues,
      origin: 'workspace_page',
    });

    handleChangeOption(option, newValues);
  };

  const otherFilters = useMemo(() => {
    const { user_name, status } = activeTabState;
    const userNameValue = user_name.map((value) => ({
      option: 'user_name',
      value,
    }));
    const statusValue = status.map((value) => ({
      option: 'status',
      value,
    }));

    return [...userNameValue, ...statusValue];
  }, [activeTabState.user_name, activeTabState.status]);

  const dateFilter = useMemo(() => {
    const { start_date, end_date } = activeTabState;
    let value;
    if (start_date[0] && !end_date[0]) {
      value = `> ${DateTime.fromJSDate(start_date[0]).toFormat('dd/LL/yyyy')}`;
    }
    if (!start_date[0] && end_date[0]) {
      value = `< ${DateTime.fromJSDate(end_date[0]).toFormat('dd/LL/yyyy')}`;
    }
    if (start_date[0] && end_date[0]) {
      value = `${DateTime.fromJSDate(start_date[0]).toFormat(
        'dd/LL/yyyy'
      )} - ${DateTime.fromJSDate(end_date[0]).toFormat('dd/LL/yyyy')}`;
    }

    return value ? [{ value, option: 'date' }] : [];
  }, [activeTabState.start_date, activeTabState.end_date]);

  const clearAllFilters = () => {
    resetTabFilters(tabKey);
    Object.keys(tabInitialState).forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(Object.fromEntries(searchParams));
  };

  const isFilterSelectionEmpty = () => {
    return Object.values(activeTabState).every((value) => value?.length === 0);
  };

  const filtersList = [...otherFilters, ...dateFilter];
  const handleCloseFilterTag = (event, option, value) => {
    event.stopPropagation();
    if (option === 'date') {
      handleChangeOption('start_date', []);
      handleChangeOption('end_date', []);
    } else {
      changeFilterValue(option as keyof FilterState, false, value);
    }
  };

  const filterContentWrapper = (children: ReactNode) => (
    <Fetch
      apiUrl={`/user-groups/${groupId}/users`}
      initialData={null}
      cacheKey={CACHE_KEY.REPORTS_USERS}
      loadingMessage="Loading users..."
      hideChildrenUntilFetched
      alwaysFetchOnMount={users.length === 0}
      onReceiveData={(data) => {
        if (data) {
          updateUserList(data.sort((a, b) => (a.name < b.name ? -1 : 1)));
        }
      }}
    >
      {children}
    </Fetch>
  );

  const filterConfig: FilterConfig[] = [
    {
      title: (
        <>
          <UserAvatar /> User Name
        </>
      ),
      filterKey: 'user_name',
      options: [...userFilterItem, ...users].map(({ name, user_id }) => ({
        id: user_id,
        value: name,
      })),
    },
    {
      title: (
        <>
          <WatsonHealthStatusAcknowledge /> Status
        </>
      ),
      filterKey: 'status',
      options: statuses.map((status) => ({
        value: status,
        label: (
          <Tag
            size="sm"
            className={`table-status ${status.replace(/ /g, '_')}`}
          >
            {status}
          </Tag>
        ),
      })),
      isHidden: isStatusFilterHidden,
    },
  ];

  return (
    <>
      <div className="filters-container">
        <div>
          <div
            className="report-type-filter-container"
            data-testid="report-type-filter"
          >
            <MultiSelect
              id="report-type-filter"
              items={reportTypesOptions || []}
              selectedItems={activeTabState.report_type}
              onChange={(e) =>
                handleChangeOption('report_type', e.selectedItems)
              }
              label={`All reports (${rowCount})`}
            />
          </div>
          <div className="date-filter-container">
            <div ref={startDateRangeRef}>
              {isRefReady && (
                <DatePicker
                  value={activeTabState.start_date}
                  datePickerType="single"
                  onChange={(date) => handleChangeOption('start_date', date)}
                  appendTo={startDateRangeRef.current}
                  data-testid="start-date-filter"
                  dateFormat="d/m/Y"
                  maxDate={
                    activeTabState.end_date[0] &&
                    DateTime.fromJSDate(activeTabState.end_date[0]).toFormat(
                      'dd/LL/yyyy'
                    )
                  }
                >
                  <DatePickerInput
                    id="date-picker-input-id-start"
                    placeholder="Start date"
                    data-testid="date-range-filter-start"
                    labelText=""
                    size="md"
                    autoComplete="off"
                  />
                </DatePicker>
              )}
            </div>
            <div ref={endDateRangeRef}>
              {isRefReady && (
                <DatePicker
                  value={activeTabState.end_date}
                  datePickerType="single"
                  onChange={(date) => handleChangeOption('end_date', date)}
                  appendTo={endDateRangeRef.current}
                  data-testid="end-date-filter"
                  dateFormat="d/m/Y"
                  minDate={
                    activeTabState.start_date[0] &&
                    DateTime.fromJSDate(activeTabState.start_date[0]).toFormat(
                      'dd/LL/yyyy'
                    )
                  }
                >
                  <DatePickerInput
                    id="date-picker-input-id-end"
                    placeholder="End date"
                    data-testid="date-range-filter-end"
                    labelText=""
                    size="md"
                    autoComplete="off"
                  />
                </DatePicker>
              )}
            </div>
          </div>
          {tabKey === 'archived' && (
            <Popover
              data-testid="del-info-popover"
              target={
                <Button
                  renderIcon={Information}
                  kind="ghost"
                  size="sm"
                  data-testid="deleted-info-btn"
                >
                  Deleted Information
                </Button>
              }
              shaded
              onOpenChange={(open) => {
                if (open) {
                  posthogEvent(workspaceDeleteInfoPopoverClick);
                }
              }}
            >
              <ul className="list-style-disc">
                <li>
                  Reports that are older than 90 days will be automatically
                  archived and appear here.
                </li>
                <li>
                  Reports that have been manually deleted will also appear here.
                </li>
                <li>
                  These reports will stay here for 14 days before being
                  permanently deleted.
                </li>
                <li>
                  In case you need to recover them within the 14 days, click on{' '}
                  <Redo size="14" />
                </li>
              </ul>
            </Popover>
          )}
        </div>
        <div>
          <Search
            placeholder="Search report by name"
            labelText=""
            data-testid="report-name-filter-input"
            className="report-name-filter-input-container"
            value={activeTabState.report_name ?? ''}
            onChange={(e) => handleChangeOption('report_name', e.target.value)}
            onClick={() =>
              posthogEvent(workspacePageSearchClick, {
                activeTab: tabKey,
                origin: 'workspace_page',
              })
            }
          />
          <Popover
            align="bottom-right"
            caret={false}
            dropShadow
            className="FiltersPopover"
            target={
              <Button
                size="md"
                kind="secondary"
                renderIcon={Filter}
                className="filters-button"
                data-testid="workspace-filters-btn"
              >
                Filters
              </Button>
            }
            onOpenChange={(open) => {
              if (open) {
                posthogEvent(workspacePageFiltersBtnClick, {
                  activeTab: tabKey,
                  origin: 'workspace_page',
                });
              }
            }}
          >
            <FiltersContent
              filterState={activeTabState}
              filterConfig={filterConfig}
              isClearButtonVisible={!isFilterSelectionEmpty()}
              changeFilterValue={changeFilterValue}
              clearAllFilters={clearAllFilters}
              conditionalWrapper={filterContentWrapper}
            />
          </Popover>
        </div>
      </div>
      {filtersList.length > 0 && (
        <div className="FiltersPopover__options-list">
          <div className="FiltersPopover__option-title">Other filters:</div>
          <div className="FiltersPopover__selected-options">
            {filtersList.map(({ value, option }) => (
              <Tag
                key={value}
                filter
                data-testid={`filter-tag-${value}`}
                onClose={(event) => handleCloseFilterTag(event, option, value)}
              >
                {value}
              </Tag>
            ))}
          </div>
        </div>
      )}
      {isTableEmpty && !isFilterSelectionEmpty() && (
        <NoDataPlaceholder
          title="No Reports Found"
          icon={Report}
          description={{
            info: 'Please refine your filters and try again.',
          }}
          buttonLabel="Clear Filters"
          onClick={clearAllFilters}
          buttonIcon={FilterRemove}
        />
      )}
    </>
  );
};

export default ReportTableFilters;
