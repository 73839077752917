import type { FunctionComponent } from 'react';
import { MultiSelect as CarbonMultiSelect, Tag } from '@carbon/react';

interface MultiSelectProps {
  id: string;
  items: string[];
  onChange?: (event: { selectedItems: string[] }) => void;
  selectedItems?: string[];
  label?: string;
  titleText?: string;
  invalid?: boolean;
  invalidText?: string;
  'data-testid'?: string;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = (props) => {
  const { selectedItems = [], onChange, label = '', titleText } = props;
  const removeItem = (item: string) => {
    onChange?.({
      selectedItems: selectedItems.filter(
        (selectedItem) => selectedItem !== item
      ),
    });
  };

  return (
    <CarbonMultiSelect
      {...props}
      label={
        selectedItems.length > 0 ? (
          <SelectionList
            selectedItems={selectedItems}
            removeItem={removeItem}
          />
        ) : (
          label
        )
      }
      hideLabel={!titleText}
      itemToString={(item) => (item ? item : '')}
      selectionFeedback="fixed"
      downshiftProps={{
        'data-testid': props['data-testid'],
      }}
      titleText={titleText}
      className={selectedItems.length <= 2 ? 'hidden-selected-items' : ''}
    />
  );
};

export default MultiSelect;

interface SelectionListProps {
  selectedItems: string[];
  removeItem: (item: string) => void;
}

const SelectionList: FunctionComponent<SelectionListProps> = ({
  selectedItems,
  removeItem,
}) => {
  return (
    <div className="selection-list-container">
      {selectedItems.slice(0, 2).map((item) => (
        <Tag
          key={item}
          filter
          onClose={(event) => {
            event.stopPropagation();
            removeItem(item);
          }}
        >
          {item}
        </Tag>
      ))}
      {selectedItems.length > 2 && (
        <div className="selected-items-list">...</div>
      )}
    </div>
  );
};
