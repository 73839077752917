import { useContext, useEffect, useState } from 'react';
import { NumberInput } from '@carbon/react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import { getSkuAllocationChange } from '../../utils/CDTUtils';
import { useDebounce } from '../../hooks';
import usePosthog from '../../utils/posthog';
import { assortmentOptimiserClusterGoalChange } from '../../constants/posthog';

const ClusterGoals = () => {
  const { formData, optimiserData, updateClusterGoals } =
    useContext(OptimiserFormContext);
  const posthogEvent = usePosthog();
  const [clusterGoalsSkuValues, setClusterGoalsSkuValues] = useState<{
    [key: string]: number;
  }>({});
  const { clusterGoals, newProducts } = formData;
  const debouncedSkuValues = useDebounce(clusterGoalsSkuValues, 2000);

  useEffect(() => {
    if (Object.keys(debouncedSkuValues).length > 0) {
      posthogEvent(assortmentOptimiserClusterGoalChange, debouncedSkuValues);
    }
  }, [debouncedSkuValues]);

  return (
    <div className="ClusterGoals">
      {clusterGoals.map(({ key, name, goal, max }) => {
        const productsInCluster = newProducts.filter(
          ({ locations }) =>
            locations?.length === 0 || locations?.includes(Number(key))
        ).length;

        return (
          <div key={name} data-testid={`cluster-goal-${name}`}>
            <div className="ClusterGoals__cluster-name">{name}</div>
            <div className="ClusterGoals__cluster">
              <div className="ClusterGoals__cluster-goal">
                <div className="ClusterGoals__cluster-goal--product-summary">
                  <div>Existing: {optimiserData[key].length}</div>
                  <div>New: {productsInCluster}</div>
                </div>
                <NumberInput
                  id={`${name}-input`}
                  className={`ClusterGoals__cluster-goal-input${
                    goal === max ? '--at-max' : ''
                  }`}
                  data-testid={`goal-input-${name}`}
                  max={max}
                  label="Target SKU Allocation"
                  value={goal}
                  onChange={(_, { value }) => {
                    const updatedClusters = clusterGoals.map((clusterGoal) =>
                      clusterGoal.name === name
                        ? { ...clusterGoal, goal: value || 0 }
                        : clusterGoal
                    );
                    updateClusterGoals(updatedClusters);
                    setClusterGoalsSkuValues((current) => ({
                      ...current,
                      [name]: value,
                    }));
                  }}
                />
                <div className="ClusterGoals__cluster-goal--cut">
                  {getSkuAllocationChange({
                    existingValue: optimiserData[key].length,
                    targetValue: Number(goal),
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ClusterGoals;
