import type { FunctionComponent, ReactNode } from 'react';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { Draggable as DragHandle } from '@carbon/icons-react';
import { createPortal } from 'react-dom';

interface DraggableItemProps {
  draggableId: number;
  index: number;
  isDragDisabled: boolean;
  portalId?: string;
  children: JSX.Element;
}
interface PortalItemProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  portalId?: string;
  children?: JSX.Element;
}

const PortalAwareItem: FunctionComponent<PortalItemProps> = ({
  provided,
  portalId,
  snapshot,
  children,
}) => {
  const { isDragging } = snapshot;

  const child: ReactNode = (
    <div
      className={`draggable-item ${isDragging ? 'is-dragging' : ''}`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {children}
    </div>
  );

  if (!isDragging || !portalId) {
    return child;
  }
  const container = document.getElementById(portalId);

  return container ? createPortal(child, container) : child;
};

const DraggableItem: FunctionComponent<DraggableItemProps> = ({
  draggableId,

  index,
  isDragDisabled,
  portalId,
  children,
}) => {
  return (
    <Draggable
      draggableId={String(draggableId)}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <PortalAwareItem
          provided={provided}
          snapshot={snapshot}
          portalId={portalId}
        >
          <>
            {children}
            {!isDragDisabled && (
              <div className="drag-handle-container">
                <DragHandle />
              </div>
            )}
          </>
        </PortalAwareItem>
      )}
    </Draggable>
  );
};

export default DraggableItem;
