import { useContext, type FunctionComponent } from 'react';
import type { UploadData } from '../../../reducers/AttributeValuesReducer';
import {
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import Fetch from '../../Fetch';
import { WarningFilled } from '@carbon/icons-react';
import { usePagination } from '../../../hooks';
import { AttributeValuesContext } from '../../../providers/AttributeValuesProvider';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import type { DataTableRenderProps } from '../carbon';
import AttributeValuesErrorNotifications from './AttributeValuesErrorNotifications';

interface HeaderData {
  key: string;
  header: string;
  is_exists: boolean;
}

interface RowData {
  id: string;
  product: string;
  [key: string]: string;
}

const AttributeValuesGrid: FunctionComponent = () => {
  const { uploadData, uploadMetadata, updateUploadData } = useContext(
    AttributeValuesContext
  );
  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination({});

  const headerData: HeaderData[] = [
    { key: 'product', header: 'Product', is_exists: true },
    { key: 'id', header: 'SKU ID', is_exists: true },
    ...(uploadData?.attributes ?? []).map(({ attribute, is_exists }) => ({
      key: attribute,
      header: capitalizeFirstLetter(attribute),
      is_exists,
    })),
  ];

  const rowData: RowData[] = (uploadData?.products ?? []).map((product) => {
    const productData: RowData = {
      id: product.id,
      product: product.label || '',
      ...(uploadData?.attributes ?? []).reduce((acc, attribute) => {
        const value = attribute.attribute_values[product.id]?.value || '';
        return { ...acc, [attribute.attribute]: value };
      }, {}),
    };
    return productData;
  });

  return (
    <div className="AttributeValuesGrid">
      <Fetch
        initialData={null}
        alwaysFetchOnMount={!uploadData}
        apiUrl={uploadMetadata?.file_path}
        loadingMessage="Loading custom attributes..."
        onReceiveData={(data: UploadData | null | undefined) => {
          if (data) {
            updateUploadData(data);
          }
        }}
      >
        {uploadData && (
          <>
            <AttributeValuesErrorNotifications uploadData={uploadData} />
            <DataTable rows={rowData} headers={headerData}>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
              }: DataTableRenderProps<HeaderData>) => {
                const rowStartIndex = (page - 1) * pageSize;

                const visibleRows = isShowAll
                  ? rows
                  : rows.slice(rowStartIndex, rowStartIndex + pageSize);

                const getCellContent = (
                  cell: CarbonCell<string>,
                  hasEmptyProductCell: boolean,
                  exists: boolean
                ): TableCell => {
                  return (
                    <TableCell
                      style={{
                        ...(!exists && {
                          borderTop: '2px solid #DA1E28',
                          borderBottom: '2px solid #DA1E28',
                        }),
                        background:
                          !exists || hasEmptyProductCell ? '#FFF1F1' : 'none',
                      }}
                      key={cell.id}
                    >
                      {cell.id.includes('product') && cell.value === '' ? (
                        <span className="AttributeValuesGrid__invalid-cell">
                          Product ID not found in database. Edit the number or
                          re-order file
                        </span>
                      ) : (
                        cell.value
                      )}
                    </TableCell>
                  );
                };

                return (
                  <TableContainer {...getTableContainerProps()}>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header: HeaderData) => {
                            return (
                              <TableHeader
                                key={header.key}
                                {...getHeaderProps({ header })}
                              >
                                <div className="AttributeValuesGrid__header-wrapper">
                                  {!header.is_exists && (
                                    <WarningFilled className="AttributeValuesGrid__warning-filled-icon" />
                                  )}
                                  {header.header}
                                </div>
                              </TableHeader>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {visibleRows.map((row: TableRow) => {
                          const hasEmptyProductCell = row.cells.some(
                            (cell: CarbonCell<string>) =>
                              cell.id.includes('product') && cell.value === ''
                          );
                          return (
                            <TableRow
                              style={{
                                ...(hasEmptyProductCell && {
                                  borderTop: '2px solid #DA1E28',
                                  borderBottom: '2px solid #DA1E28',
                                }),
                              }}
                              key={row.id}
                              {...getRowProps({ row })}
                            >
                              {row.cells.map((cell: CarbonCell<string>) => {
                                const exists = headerData.some(
                                  (item) =>
                                    item.key === cell.info.header &&
                                    item.is_exists
                                );
                                return getCellContent(
                                  cell,
                                  hasEmptyProductCell,
                                  exists
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </DataTable>
            <Pagination
              backwardText="Previous page"
              forwardText="Next page"
              itemsPerPageText="Items per page:"
              onChange={setPagination}
              page={page}
              pageSize={pageSize}
              pageSizes={pageSizes}
              itemRangeText={getItemRangeText}
              totalItems={rowData.length}
            />
          </>
        )}
      </Fetch>
    </div>
  );
};

export default AttributeValuesGrid;
