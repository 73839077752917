/* eslint-disable indent */
import type { ElementRef, FunctionComponent, ReactNode } from 'react';
import { useContext, useMemo, useRef } from 'react';
import { Repeat } from '@carbon/icons-react';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { addAndRemoveItems } from '../../utils/reportUtils';
import type { HighlightedCell, RowData } from './DataGrid';
import DataGrid from './DataGrid';
import { AppContext } from '../../providers/AppProvider';
import type { AssortmentData } from '../../reducers/AssortmentReducer';
import { SUBS_HEADER_KEYS, USER_TYPE } from '../../constants/metadata';
import {
  getContinuousHighlightedCells,
  getDiscreteHighlightedCells,
  getSubstituteHighlightedCells,
  getSubstituteProducts,
} from '../../utils/CDTUtils';

export const SUBSTITUTE_HIGHLIGHT_KEY = 'substitutes';

type ExtendedAssortmentData = AssortmentData & {
  readonly PRODUCT:
    | AssortmentData['PRODUCT']
    | {
        readonly value: string;
        readonly action: {
          readonly onClick: (cellId: string) => void | Promise<void>;
          readonly icon: ReactNode;
          readonly tooltip: string;
        };
      };
};

interface AssortmentGridProps {
  dropdownKey: string;
  reportTemplateId: string;
  reportTemplateIndex: number;
}

const AssortmentGrid: FunctionComponent<AssortmentGridProps> = ({
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
}) => {
  const { user } = useContext(AppContext);
  const { visualsData, reportConfig, patchVisualsData } =
    useContext(ReportContext);
  const {
    assortmentData,
    selectedProducts,
    highlight,
    highlightLegend,
    resetSelection,
    updateSelectedProducts,
    saveAssortmentData,
    updateHighlight,
    clearHighlight,
  } = useContext(AssortmentContext);
  const isAuthor = reportConfig.user_id === user?.id;
  const isSupplier = user?.user_type === USER_TYPE.SUPPLIER;

  const dataGridRef = useRef<ElementRef<typeof DataGrid>>(null);

  const rows: RowData<ExtendedAssortmentData>[] = useMemo(() => {
    if (highlight?.key && highlight.key !== SUBSTITUTE_HIGHLIGHT_KEY) {
      return assortmentData;
    }

    return assortmentData.map((row) => ({
      ...row,
      PRODUCT: {
        value: row.PRODUCT as string,
        action: {
          onClick: async (cellId: string) => {
            if (
              highlight &&
              'targetProductId' in highlight &&
              highlight.targetProductId === row.PRODUCT_ID
            ) {
              clearHighlight();
            } else {
              updateHighlight({
                cellId,
                by: SUBSTITUTE_HIGHLIGHT_KEY,
                targetProductId: row.PRODUCT_ID,
              });
            }

            await dataGridRef.current?.showHeaders(SUBS_HEADER_KEYS);

            dataGridRef.current?.scrollHeaderIntoView();
          },
          icon: <Repeat />,
          tooltip: 'View substitutes',
        },
      },
    }));
  }, [highlight, assortmentData]);

  const substituteProducts:
    | {
        productName: string;
        substitutes: [name: string, transfer: NumberPoint][];
      }
    | undefined = useMemo(() => {
    if (
      !highlight ||
      !('targetProductId' in highlight) ||
      highlight.key !== SUBSTITUTE_HIGHLIGHT_KEY
    ) {
      return undefined;
    }

    const selectedProduct = assortmentData.find(
      (row) => row.PRODUCT_ID === highlight.targetProductId
    );

    return selectedProduct
      ? {
          productName: selectedProduct?.PRODUCT as string,
          substitutes: getSubstituteProducts(selectedProduct),
        }
      : undefined;
  }, [highlight, assortmentData]);

  const highlightedCells: Record<string, HighlightedCell> | undefined =
    useMemo(() => {
      if (!highlight?.key) {
        return undefined;
      }

      const legend = highlightLegend?.find(
        (entry) => entry.key === highlight.key
      );

      if (!legend) {
        return undefined;
      }

      if (highlight.key === SUBSTITUTE_HIGHLIGHT_KEY && substituteProducts) {
        return getSubstituteHighlightedCells({
          ...substituteProducts,
          assortmentData,
          legend,
        });
      }

      switch (highlight.type) {
        case 'discrete':
          return getDiscreteHighlightedCells({ assortmentData, legend });

        case 'continuous':
        case 'index':
          return getContinuousHighlightedCells({ assortmentData, legend });

        default:
          return undefined;
      }
    }, [
      assortmentData,
      highlight?.key,
      highlight?.type,
      highlightLegend,
      substituteProducts,
    ]);

  const orderedData = useMemo(() => {
    if (!highlight || highlight.key === SUBSTITUTE_HIGHLIGHT_KEY) {
      return visualsData[1];
    }
    const data = visualsData[1];
    const headers = data.headers?.[1];
    const highlightedId = headers?.find((h) => h.key === highlight.key)?.id;

    const headerIndex =
      typeof highlightedId === 'number'
        ? data.isVisible?.indexOf(highlightedId)
        : undefined;

    const columnOrder = data.isVisible ? [...data.isVisible] : [];
    const firstColIndex = data.stickyColumns?.length;
    if (typeof headerIndex === 'number') {
      columnOrder.splice(headerIndex, 1);
    }
    if (
      typeof firstColIndex === 'number' &&
      typeof highlightedId === 'number'
    ) {
      columnOrder.splice(firstColIndex, 0, highlightedId);
    }

    return {
      ...data,
      isVisible: columnOrder,
      stickyColumns: highlightedId
        ? [...(visualsData[1].stickyColumns ?? []), highlightedId]
        : visualsData[1].stickyColumns,
    };
  }, [highlight, visualsData[1]]);

  const customSortHeaders = (a: Header, b: Header): number => {
    if (!orderedData.isVisible) {
      return -1;
    }
    return (
      orderedData.isVisible.indexOf(a.id) - orderedData.isVisible.indexOf(b.id)
    );
  };

  const selectRows = (indexes: number[], add?: boolean) => {
    resetSelection();
    const productIds = assortmentData
      .filter((_, idx) => indexes.includes(idx))
      .map((product) => product.PRODUCT_ID);

    updateSelectedProducts(
      add ? addAndRemoveItems(selectedProducts, productIds) : productIds
    );
  };

  return (
    <div className="assortment-grid">
      <DataGrid
        ref={dataGridRef}
        headerData={orderedData.headers?.[dropdownKey] as Header[]}
        rowData={rows as RowData<AssortmentData>[]}
        stickyColumns={orderedData.stickyColumns}
        visibleColumns={orderedData.isVisible}
        collapsed
        showExport={false}
        isSortable={false}
        showPagination={false}
        columnWidths={orderedData.columnWidths}
        visualData={visualsData[1]}
        id={1}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        resizable
        isAuthor={isAuthor}
        reportConfig={reportConfig}
        patchVisualsData={patchVisualsData}
        onSave={isAuthor && !isSupplier ? saveAssortmentData : undefined}
        clickedCellId={
          highlight && 'cellId' in highlight ? highlight.cellId : undefined
        }
        highlightedRows={selectedProducts.map((sku) =>
          assortmentData.map((row) => row.PRODUCT_ID).indexOf(sku)
        )}
        highlightedCells={highlightedCells}
        onSelectRows={(rowIndexes, add) => selectRows(rowIndexes, add)}
        customSortHeaders={customSortHeaders}
      />
    </div>
  );
};

export default AssortmentGrid;
