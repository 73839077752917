/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Stack,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  SkeletonPlaceholder,
} from '@carbon/react';
import { Search, ChevronRight, Calendar } from '@carbon/icons-react';
import { AppContext } from '../../providers/AppProvider';
import { HomeContext } from '../../providers/HomeProvider';
import Fetch from '../Fetch';
import HomeCategoryHierarchy from './HomeCategoryHierarchy';
import { getSelectedItem, renderNodeLabel } from '../../utils';
import {
  getFirstDropdownSelectionKey,
  getHomepageConfigUrl,
} from '../../utils/reportUtils';
import { ReportContext } from '../../providers/ReportProvider';
import HomeDropdownsSkeleton from '../Loading/HomeDropdownsSkeleton';
import { CACHE_KEY } from '../../constants/api';
import { formatDateRange } from '../../utils/DateUtils';
import usePosthog from '../../utils/posthog';
import {
  homepageCategorytDropdownClick,
  homepageFiltersDropdownClick,
} from '../../constants/posthog';

export interface CategoryHierararchy {
  hierarchy: HierNode;
}

interface HomeDropdownsData {
  dropdowns: DropdownConfig[];
  product_keys: {
    [key: string]: string;
  };
  dropdowns_reports_mapping: {
    [key: string]: string[];
  };
  shared_dropdown?: DropdownConfig[];
  dropdown_config?: DropdownConfig[];
}

interface DropdownsUpdateConfig {
  endpoint: string;
  updateHomeContext: (data: CategoryHierararchy | HomeDropdownsData) => void;
  isEmpty: boolean;
}

const HomeDropdowns: FunctionComponent = () => {
  const { bannerId, groupId, user } = useContext(AppContext);
  const {
    categories,
    selectedCategory,
    dropdowns,
    homeDropdownSelections,
    timePeriod,
    updateCategories,
    updateSelectedCategory,
    updateProductKeys,
    updateHomepageDropdownSelections,
    updateHomepageDropdowns,
    updateReportConfigMap,
  } = useContext(HomeContext);
  const posthogEvent = usePosthog();
  const [isCategoryHierarchyVisible, setIsCategoryHierarchyVisible] =
    useState(false);
  const categoryContainerRef = useRef<HTMLDivElement | null>(null);
  const { reportConfig, dropdownSelections } = useContext(ReportContext);
  const [categoryBreacrumbs, setCategoryBreacrumbs] = useState<HierNode[]>([]);

  const updateDropdownSelection = (
    selectedItem: string,
    dropdownId: number
  ) => {
    const dropdownIndex = dropdowns?.findIndex((dd) => dd.id === dropdownId);
    if (typeof dropdownIndex === 'number') {
      posthogEvent(homepageFiltersDropdownClick, {
        dropdownType: dropdowns?.[dropdownIndex].label as string,
        selectedItem,
      });
      const selectedKey = dropdowns?.[dropdownIndex].options.find(
        (opt) => opt.label === selectedItem
      )?.key;
      const newKey = homeDropdownSelections?.split('_');
      if (newKey && selectedKey !== undefined) {
        newKey[dropdownIndex] = selectedKey.toString();
        updateHomepageDropdownSelections(newKey.join('_'));
      }
    }
  };

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      updateSelectedCategory(categories[0]);
      setCategoryBreacrumbs([categories[0]]);
    }
  }, [categories]);

  useEffect(() => {
    if (!homeDropdownSelections && dropdowns) {
      const firstKey = getFirstDropdownSelectionKey(dropdowns);
      updateHomepageDropdownSelections(firstKey);
    }
  }, [dropdowns]);

  const shouldRenderDropdowns =
    categories?.length > 0 &&
    dropdownSelections !== null &&
    homeDropdownSelections !== null;

  const getConfigApiUrl = () => {
    const reportUrl =
      Object.values(reportConfig.configuration.visuals)[0][0].apiUrl ?? '';
    return `${getHomepageConfigUrl(
      reportUrl,
      bannerId as number,
      groupId as number,
      true
    )}/config`;
  };

  const dropdownsUpdateConfig: DropdownsUpdateConfig[] =
    Object.values(reportConfig.configuration.visuals).length > 0 && user
      ? [
          {
            endpoint: `/metadata/${bannerId}/user-groups/${groupId}/user-types/${user.user_type}/homepage-metadata`,
            updateHomeContext: (responseData) => {
              const { hierarchy } = responseData as CategoryHierararchy;
              if (Array.isArray(hierarchy)) {
                updateCategories(hierarchy);
              } else {
                const { children = [], ...totalBannerData } = hierarchy;
                updateCategories([totalBannerData, ...children]);
              }
            },
            isEmpty: !categories?.length,
          },
          {
            endpoint: getConfigApiUrl(),
            updateHomeContext: (responseData) => {
              const { product_keys, dropdowns, dropdowns_reports_mapping } =
                responseData as HomeDropdownsData;
              updateProductKeys(product_keys);
              updateHomepageDropdowns(dropdowns);
              updateReportConfigMap(dropdowns_reports_mapping);
            },
            isEmpty: !dropdowns?.length,
          },
        ]
      : [];

  const handleOutsideClick = (e) => {
    if (
      categoryContainerRef.current &&
      !categoryContainerRef.current.contains(e.target)
    ) {
      setIsCategoryHierarchyVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <Stack orientation="horizontal" className="HomeDropdowns">
      {reportConfig.configuration && (
        <Fetch
          key="category-fetch"
          multiRequest
          apiUrls={
            bannerId && groupId
              ? dropdownsUpdateConfig.map(({ endpoint }) => endpoint)
              : []
          }
          cacheKey={CACHE_KEY.HOMEPAGE_DROPDOWNS}
          alwaysFetchOnMount={dropdownsUpdateConfig.some(
            ({ isEmpty }) => isEmpty
          )}
          initialData={null}
          loadingComponent={<HomeDropdownsSkeleton />}
          onReceiveData={(data) => {
            data?.forEach(({ responseURL, data: responseData }) => {
              const dropdownConfig = dropdownsUpdateConfig.find((config) => {
                return responseURL.includes(config.endpoint);
              });
              const { updateHomeContext } = dropdownConfig ?? {};
              updateHomeContext?.(responseData);
            });
          }}
        >
          {shouldRenderDropdowns && (
            <>
              <div className="HomeDropdowns__time-period">
                {timePeriod ? (
                  timePeriod.map(({ label, startDate, endDate }) => (
                    <div
                      key={label}
                      className="HomeDropdowns__date-range-container"
                    >
                      <Calendar />
                      <div className="HomeDropdowns__date-range-label">
                        <span>{label}:</span>{' '}
                        {formatDateRange({
                          startDate,
                          endDate,
                          formatToken: 'd LLL yyyy',
                        })}
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <SkeletonPlaceholder />
                    <SkeletonPlaceholder />
                  </>
                )}
              </div>
              <div
                className="HomeDropdowns__wrapper"
                ref={categoryContainerRef}
              >
                <div className="HomeCategoryDropdown__container">
                  <div
                    className="HomeCategoryDropdown__button"
                    onClick={() => setIsCategoryHierarchyVisible(true)}
                    data-testid="category-dropdown"
                  >
                    <Search />
                    <div className={!selectedCategory ? 'empty' : ''}>
                      {selectedCategory
                        ? renderNodeLabel(selectedCategory.label)
                        : 'Search product or select from options'}
                    </div>
                    <ChevronRight />
                  </div>
                </div>
                {dropdowns?.map((dropdown) => {
                  const { id, options, label } = dropdown;
                  const items = options.map(({ label }) => label);
                  return (
                    <div
                      className="HomeDropdowns__dropdown-container"
                      key={`homepage-dropdown-${id}`}
                    >
                      <span className="description">{label}</span>
                      <Dropdown
                        id={`homepage-dropdown-${id}`}
                        data-testid={`homepage-dropdown-${id}`}
                        label={label}
                        items={items}
                        selectedItem={getSelectedItem(
                          dropdown.id,
                          homeDropdownSelections,
                          dropdowns
                        )}
                        onChange={({ selectedItem }) => {
                          updateDropdownSelection(selectedItem, dropdown.id);
                        }}
                      />
                    </div>
                  );
                })}
                {isCategoryHierarchyVisible && (
                  <HomeCategoryHierarchy
                    hierarchy={categories}
                    handleItemClick={(items) => {
                      const { label, level } = items[items.length - 1];
                      posthogEvent(homepageCategorytDropdownClick, {
                        label,
                        level,
                      });
                      updateSelectedCategory(items[items.length - 1]);
                      setCategoryBreacrumbs(items);
                      setIsCategoryHierarchyVisible(false);
                    }}
                    selectedItemPath={categoryBreacrumbs.map(({ id }) => id)}
                  />
                )}
              </div>
              {categoryBreacrumbs.length > 1 && (
                <div className="HomeDropdowns__breadcrumb">
                  <span>Result:</span>
                  <Breadcrumb noTrailingSlash>
                    {categoryBreacrumbs.map((item, index) => (
                      <BreadcrumbItem
                        key={item.label}
                        isCurrentPage={index === categoryBreacrumbs.length - 1}
                      >
                        {renderNodeLabel(item.label)}
                      </BreadcrumbItem>
                    ))}
                  </Breadcrumb>
                </div>
              )}
            </>
          )}
        </Fetch>
      )}
    </Stack>
  );
};

export default HomeDropdowns;
