/* eslint-disable indent */

import {
  LOAD_OPTIMISER_DATA,
  UPDATE_CLUSTER_GOALS,
  UPDATE_NEW_PRODUCTS,
  UPDATE_STRATEGY,
  UPDATE_OPTIMISER_MODE,
  UPDATE_PLAN_INCLUSION,
  UPDATE_PLAN_PLANOGRAM,
  UPDATE_PLAN_TARGET,
} from '../constants/reducers';
import type { AssortmentData, Decision } from './AssortmentReducer';

export enum OptimiserStrategy {
  Breadth = 'b',
  Depth = 'd',
}

export enum OptimiserMode {
  NonPlan = 'Non-Plan',
  Plan = 'Plan',
}

export type Performance = 'High' | 'Medium' | 'Low';

export interface DecisionGroups {
  readonly cd1: Decision;
  readonly cd2: Decision;
  readonly cd3: Decision;
}

export interface NewProduct {
  readonly id: string;
  readonly name: string;
  readonly performance: Performance;
  readonly price_point: number;
  readonly margin_rate?: number;
  readonly lookalike: { readonly id: string; readonly value: string };
  readonly cd1: Decision;
  readonly cd2: Decision;
  readonly cd3: Decision;
  readonly force_in: boolean;
  readonly locations?: (string | number)[];
}

export interface PlanogramHeader<K> {
  readonly id: number;
  readonly header: string;
  readonly key: K;
}

export interface PlanogramRow {
  readonly id: string;
  readonly planogramName: string;
  readonly planogramNo: string;
  readonly skuCount: number;
  readonly storeCount: number;
}

export interface PlanogramGrid {
  readonly headers: [
    PlanogramHeader<'planogramName'>,
    PlanogramHeader<'storeCount'>,
    PlanogramHeader<'skuCount'>
  ];
  readonly rows: PlanogramRow[];
}

export interface PlanTargetHeader<K> {
  readonly id: number;
  readonly header: string;
  readonly key: K;
}

export interface PlanTargetRow {
  readonly id: string;
  readonly include: boolean;
  readonly maxSkusCount: number;
  readonly minSkusCount: number;
  readonly planName: string;
  readonly planNo: string;
  readonly planogramCount: number;
  readonly referencePlanogramNo: string;
  readonly skuTarget: number;
  readonly storeCount: number;
  readonly planograms: PlanogramGrid;
}

export interface LocationPlanTargetRow extends PlanTargetRow {
  readonly location: number;
  readonly locationName: string;
}

export interface PlanTargetGrid {
  readonly headers: [
    PlanTargetHeader<'planName'>,
    PlanTargetHeader<'planogramCount'>,
    PlanTargetHeader<'storeCount'>,
    PlanTargetHeader<'minSkusCount'>,
    PlanTargetHeader<'maxSkusCount'>,
    PlanTargetHeader<'skuTarget'>
  ];
  readonly rows: PlanTargetRow[];
}

export interface LocationPlanTargetGrid {
  readonly headers: [
    PlanTargetHeader<'locationName'>,
    PlanTargetHeader<'planName'>,
    PlanTargetHeader<'planogramCount'>,
    PlanTargetHeader<'storeCount'>,
    PlanTargetHeader<'minSkusCount'>,
    PlanTargetHeader<'maxSkusCount'>,
    PlanTargetHeader<'skuTarget'>
  ];
  readonly rows: LocationPlanTargetRow[];
}

export interface ClusterGoal {
  readonly name: string;
  readonly key: string | number;
  readonly goal: number;
  readonly max: number;
}

export interface DataInitialisation {
  readonly optimiserData: { readonly [key: string]: AssortmentData[] };
  readonly clusters: DropdownOptions[];
  readonly newProducts: NewProduct[];
  readonly clusterGoals: ClusterGoal[];
  readonly planTargets: PlanTargetGrid | LocationPlanTargetGrid | undefined;
  readonly strategy?: OptimiserStrategy;
}

export interface OptimiserFormData {
  readonly strategy: OptimiserStrategy;
  readonly newProducts: NewProduct[];
  readonly clusterGoals: ClusterGoal[];
  readonly planTargets: PlanTargetGrid | LocationPlanTargetGrid | undefined;
}

export interface OptimiserFormState {
  readonly optimiserData: { readonly [key: string]: AssortmentData[] };
  readonly clusters: DropdownOptions[];
  readonly mode: OptimiserMode | undefined;
  readonly formData: OptimiserFormData;
}

interface LoadData {
  readonly type: typeof LOAD_OPTIMISER_DATA;
  readonly data: DataInitialisation;
}

interface UpdateOptimiserMode {
  readonly type: typeof UPDATE_OPTIMISER_MODE;
  readonly mode: OptimiserMode;
}

interface UpdateStrategy {
  readonly type: typeof UPDATE_STRATEGY;
  readonly strategy: OptimiserStrategy;
}

interface UpdateNewProduct {
  readonly type: typeof UPDATE_NEW_PRODUCTS;
  readonly newProducts: NewProduct[];
}

interface UpdateClusterGoals {
  readonly type: typeof UPDATE_CLUSTER_GOALS;
  readonly clusterGoals: ClusterGoal[];
}

interface UpdatePlanInclusion {
  readonly type: typeof UPDATE_PLAN_INCLUSION;
  readonly id: string;
  readonly include: boolean;
}

interface UpdatePlanPlanogram {
  readonly type: typeof UPDATE_PLAN_PLANOGRAM;
  readonly id: string;
  readonly planogramNo: string;
}

interface UpdatePlanTarget {
  readonly type: typeof UPDATE_PLAN_TARGET;
  readonly id: string;
  readonly value: number;
}

export type OptimiserFormAction =
  | LoadData
  | UpdateOptimiserMode
  | UpdateStrategy
  | UpdateNewProduct
  | UpdateClusterGoals
  | UpdatePlanInclusion
  | UpdatePlanPlanogram
  | UpdatePlanTarget;

export const initialOptimiserForm: OptimiserFormData = {
  strategy: OptimiserStrategy.Depth,
  clusterGoals: [],
  newProducts: [],
  planTargets: undefined,
};

export const initialOptimiserFormState: OptimiserFormState = {
  mode: undefined,
  optimiserData: {},
  clusters: [],
  formData: initialOptimiserForm,
};

export const optimiserFormReducer = (
  state: OptimiserFormState,
  action: OptimiserFormAction
): OptimiserFormState => {
  switch (action.type) {
    case LOAD_OPTIMISER_DATA: {
      const {
        data: {
          optimiserData,
          clusters,
          clusterGoals,
          newProducts,
          planTargets,
          strategy,
        },
      } = action;

      return {
        ...state,
        optimiserData,
        clusters,
        formData: {
          clusterGoals,
          newProducts,
          planTargets,
          strategy: strategy ?? initialOptimiserForm.strategy,
        },
      };
    }

    case UPDATE_OPTIMISER_MODE: {
      return { ...state, mode: action.mode };
    }

    case UPDATE_STRATEGY:
      return {
        ...state,
        formData: { ...state.formData, strategy: action.strategy },
      };

    case UPDATE_NEW_PRODUCTS:
      return {
        ...state,
        formData: { ...state.formData, newProducts: action.newProducts },
      };

    case UPDATE_CLUSTER_GOALS:
      return {
        ...state,
        formData: { ...state.formData, clusterGoals: action.clusterGoals },
      };

    case UPDATE_PLAN_INCLUSION: {
      const {
        formData: { planTargets: existingPlanTargets },
      } = state;

      if (!existingPlanTargets) {
        return state;
      }

      const planTargets: PlanTargetGrid | LocationPlanTargetGrid = {
        ...existingPlanTargets,
        rows: existingPlanTargets.rows.map((row) =>
          row.id === action.id ? { ...row, include: action.include } : row
        ),
      };

      return {
        ...state,
        formData: { ...state.formData, planTargets },
      };
    }

    case UPDATE_PLAN_PLANOGRAM: {
      const {
        formData: { planTargets: existingPlanTargets },
      } = state;

      if (!existingPlanTargets) {
        return state;
      }

      const planTargets: PlanTargetGrid | LocationPlanTargetGrid = {
        ...existingPlanTargets,
        rows: existingPlanTargets.rows.map((row) =>
          row.id === action.id
            ? {
                ...row,
                referencePlanogramNo: action.planogramNo,
                skuTarget: row.planograms.rows.find(
                  (planogram) => planogram.planogramNo === action.planogramNo
                ).skuCount,
              }
            : row
        ),
      };

      return {
        ...state,
        formData: { ...state.formData, planTargets },
      };
    }

    case UPDATE_PLAN_TARGET: {
      const {
        formData: { planTargets: existingPlanTargets },
      } = state;

      if (!existingPlanTargets) {
        return state;
      }

      const planTargets: PlanTargetGrid | LocationPlanTargetGrid = {
        ...existingPlanTargets,
        rows: existingPlanTargets.rows.map((row) =>
          row.id === action.id ? { ...row, skuTarget: action.value } : row
        ),
      };

      return {
        ...state,
        formData: { ...state.formData, planTargets },
      };
    }
  }
};
