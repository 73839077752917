import { useContext } from 'react';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { AppContext } from '../providers/AppProvider';
import {
  getBannerNameById,
  getFormattedUserRole,
  getFormattedUserType,
} from './adminPanelUtils';

export interface PosthogPayload {
  [key: string]:
    | string
    | number
    | (string | number)[]
    | boolean
    | {
        [key: string]: string | number;
      }
    | AnalyticEngineParams
    | undefined
    | null;
}
const usePosthog = () => {
  const { bannerId, groupId, user, bannersAndUserGroups } =
    useContext(AppContext);
  const posthogEvent = (eventName: string, payload?: PosthogPayload) => {
    posthog.capture(eventName, {
      ...(user && {
        user: {
          id: user.id,
          user_type: user.user_type && getFormattedUserType(user.user_type),
          user_role: user.user_role && getFormattedUserRole(user.user_role),
          supplier_name: user.supplier_name,
          job_title: user.job_title,
          department: user.department,
        },
      }),
      date: DateTime.now().toLocaleString(),
      month: DateTime.now().monthLong,
      banner: bannerId && getBannerNameById(bannerId, bannersAndUserGroups),
      user_group: groupId,
      payload: payload || '',
    });
  };

  return posthogEvent;
};

export default usePosthog;
