import type { LinkedFilesConfig, LinkedFilesKeys } from './GenAIAdmin.types';

export const MAX_TOKEN_DEFAULT_VALUE = 500;
export const TEMPERATURE_DEFAULT_VALUE = 0;
export const TOP_P_DEFAULT_VALUE = 0.999;

export const LINKED_FILES_DEFAULT_VALUE: LinkedFilesConfig = {
  product: false,
  location: false,
  sales: false,
  units: false,
};

export const LINKED_FILES_OPTIONS: {
  [key in LinkedFilesKeys]: string;
} = {
  product: 'kpi-tree-grid - Product',
  location: 'kpi-tree-grid - Location',
  sales: 'ckpi-chart - Sales Change',
  units: 'ckpi-chart - Units Change',
};

export const PROMPT_DEFAULT_VALUE = `I am going to provide you with data about the performance of a category in a grocery retailer. 
    It will also include data on how the category and subcategories are performing by customer segment and store cluster.

    ========= Members Section =========

    The member field includes values for customer segments. These are:
    1.\tPrice Sensitive, customers most sensitive to price and therefore those who tend to look for the lowest priced products or ways to save money or get better value for money.
    2.\tUpmarket customers, those who have more money and for whom price is less of a concern. Upmarket customers are therefore typically more interested in quality and choice.
    3.\tMid-market customers are those in the middle who shop with an average behaviour and are neither price sensitive nor upmarket.

            ========= Location Section =========

    The store locations are based on a mix of location type and customers shopping them;
    •\tOffice - High Income (Upscale) ; stores near offices that category to high income upmarket customers
    •\tOffice - Local (Wellcome) ; stores near offices that cater to local population. These are more midmarket in their customer appeal
    •\tResidential - High Income (Wellcome) ; stores catering to upmarket residential areas, 
    •\tResidential - Local (Wellcome) ; stores catering to more price sensitive and midmarket residential areas
    •\tResidential - Western & Top Income (Upscale) ; stores catering to the most affluent residential areas
    •\tTourist Border ; stores situated close to the border and popular with tourists coming across the border for shopping
    •\tUrban Hub - High Income (Upscale) ; stores in densely urban areas, typically with well connected transport links, catering to more upmarket customers
    •\tUrban Hub - Local (Wellcome) ; stores in densely urban areas, typically with well connected transport links, catering to more price sensitive and midmarket customers
    We have analysed the sales change over two comparable periods to understand where sales have increased and decreased, across every combination of product group, customer segment and location.

            ========= Measures Section =========

    The data includes 5 key metrics which make up the foundations of the customer behaviours we are able to measure to explain the changes in product group performance are;
    •\t'Visits Per Member'; the average number of transactions or visits that a customer made during the period in the specified product group
    •\t'Retailer Members'; the total number of customers that shopped in the retailer during the time period. This is independent of the product group performance but tells us whether the total number of customers shopping was increasing or decreasing which provides perspective in whether the product groups being analysed grew or declined because of the overall retailer customer traffic.
    •\t'Member Penetration %'; the percentage of members who shopped in the specified product group as a percentage of all the customers that shopped in the retailer. It is essentially a measure of the product group's attractiveness. 
    •\t'Units Per Member Visit' is the average number of units purchased by customers in the period, in the product group.
    •\t'Member Price Per Unit' is the average price per unit that customers paid when making purchases in the product group.
    •\t'Total'; this is the total sales change. Each of the contribution values 

    ========== Contribution explanation ===========

    The contribution values have been pre-calculated to determine the dollar value change associated with the change in each of the metrics. To understand where the change was in this analysis, we can focus on the contribution value. Negative contribution indicate a the metric contributed to a decline in the product group performance. A positive contribution indicates that a metric contributed to increased sales in the product group performance. 

    ========== Analysis tee up ===========

    I need your help to understand what is happening in my category. 

    A brief final summary should tie your analysis to the overall change in sales and should state the $ value of the overall change.
`;

export const MAX_TOKEN_MIN = 0;

export const MAX_TOKEN_MAX = 1000;

export const TEMPERATURE_MIN = 0;

export const TEMPERATURE_MAX = 1;

export const TOP_P_MIN = 0;

export const TOP_P_MAX = 1;
