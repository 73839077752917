/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useMemo, useState } from 'react';
import { Column } from '@carbon/react';
import type { GlobalSelection } from '../ReportContent';
import CardHeader from '../../Cards/CardHeader';
import { ReportContext } from '../../../providers/ReportProvider';
import type { Visual, VisualData } from '../../../reducers/ReportReducer';
import {
  VisualContainerType,
  VisualType,
} from '../../../reducers/ReportReducer';
import {
  DEFAULT_COLUMN_SPAN,
  VISUAL_CONTAINER_KEY,
} from '../../../constants/values';
import VisualsListContainer from './VisualsListContainer';
import VisualsTabsContainer from './VisualsTabsContainer';
import { getIcon } from '../../../utils/iconUtils';
import {
  filterVisuals,
  getCustomStyles,
  visualResponsive,
} from '../../../utils/reportUtils';
import DataVisual from '../DataVisual';

interface VisualContainerProps {
  readonly visual: Visual;
  readonly reportType: ReportType | undefined;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly globalSelections: GlobalSelection[];
  readonly withoutBackground?: boolean;
  readonly hideDropdowns?: boolean;
  readonly useDropdownsWrapper?: boolean;
  readonly isVisible?: boolean;
  readonly hideVisualHeader?: boolean;
  readonly headerPrefix?: string;
  readonly excludedSharedDropdownIds?: number[];
}

const VisualContainer: FunctionComponent<VisualContainerProps> = ({
  visual,
  reportType,
  reportTemplateId,
  reportTemplateIndex,
  globalSelections,
  withoutBackground,
  hideDropdowns,
  useDropdownsWrapper,
  isVisible,
  hideVisualHeader,
  headerPrefix = '',
  excludedSharedDropdownIds,
}) => {
  const { visualsData, visualRefs, tabIndex } = useContext(ReportContext);
  const { id, visuals, container, apiUrl, colSpan, type } = visual;
  const isFirstTabReady = !!visuals && !!visualsData[visuals[0].id];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const isResponsive = visualResponsive(visual);

  const getHeaderData = (
    visualHeader: VisualData['visualHeader']
  ):
    | {
        title: string;
        icon?: JSX.Element;
        iconColour?: string;
        iconBackgroundColour?: string;
      }
    | undefined => {
    if (!visualHeader) {
      return undefined;
    }
    const { title, icon, icon_colour, icon_background_colour } = visualHeader;
    return {
      title: `${headerPrefix}${title}`,
      icon: icon ? getIcon(icon) : undefined,
      iconColour: icon_colour,
      iconBackgroundColour: icon_background_colour,
    };
  };

  const visualContainerHeader = useMemo(() => {
    if (!container || hideVisualHeader) {
      return null;
    }
    const getVisualData = () => {
      switch (container) {
        case VisualContainerType.LIST: {
          const visualsWithHeader = filterVisuals(
            visual.visuals ?? [],
            ({ id }) => !!visualsData[id]?.visualHeader
          );
          return visualsWithHeader.length === 1
            ? visualsData[visualsWithHeader[0].id]
            : null;
        }
        case VisualContainerType.TABS:
          return visuals?.[selectedTabIndex]?.id
            ? visualsData?.[visuals?.[selectedTabIndex]?.id]
            : null;
        default:
          return null;
      }
    };
    const visualData = getVisualData();

    return visualData?.visualHeader
      ? getHeaderData(visualData.visualHeader)
      : null;
  }, [selectedTabIndex, visualsData, headerPrefix]);

  const renderContainer = () => {
    if (!visuals) {
      return null;
    }
    switch (container) {
      case VisualContainerType.TABS:
        return (
          <VisualsTabsContainer
            visuals={visuals}
            reportType={reportType}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            globalSelections={globalSelections}
            isFirstTabReady={isFirstTabReady}
            headerPrefix={headerPrefix}
            selectedIndex={selectedTabIndex}
            setSelectedIndex={setSelectedTabIndex}
          />
        );
      case VisualContainerType.LIST:
        return (
          <VisualsListContainer
            visuals={visuals}
            reportType={reportType}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            headerPrefix={headerPrefix}
            globalSelections={globalSelections}
            excludedSharedDropdownIds={excludedSharedDropdownIds}
            hideVisualHeader={!!visualContainerHeader || !!hideVisualHeader}
          />
        );
      default:
        return null;
    }
  };

  return container && visuals ? (
    <Column
      data-testid={`visual-container-${id}`}
      className={`VisualContainer__container ${
        !isFirstTabReady ? 'VisualContainer__container--loading' : ''
      }`}
      key={`${id}_${visuals.map(({ id }) => id).join('_')}`}
      lg={DEFAULT_COLUMN_SPAN}
      md={DEFAULT_COLUMN_SPAN / 2}
      sm={DEFAULT_COLUMN_SPAN / 4}
    >
      <div
        ref={(el) => {
          if (visualRefs?.current && el) {
            visualRefs.current[`${VISUAL_CONTAINER_KEY}-${id}`] = el;
          }
        }}
      >
        {visualContainerHeader && <CardHeader {...visualContainerHeader} />}
        {renderContainer()}
      </div>
    </Column>
  ) : (
    <Column
      data-testid={apiUrl}
      className={type}
      lg={colSpan}
      md={isResponsive ? 8 : Number(colSpan) / 2}
      sm={isResponsive ? 4 : Number(colSpan) / 4}
      style={getCustomStyles(visual)}
    >
      {reportType ? (
        <DataVisual
          {...visual}
          tab={tabIndex}
          apiUrl={`/reports${apiUrl}`}
          visualData={visualsData[id]}
          reportType={reportType}
          reportTemplateId={reportTemplateId}
          reportTemplateIndex={reportTemplateIndex}
          globalSelections={globalSelections}
          useDropdownsWrapper={
            (reportType === 'Consolidated Performance Report' &&
              type === VisualType.CELL_GRID) ||
            useDropdownsWrapper
          }
          withoutBackground={withoutBackground}
          hideDropdowns={hideDropdowns}
          isVisible={isVisible}
          visualHeader={
            visualsData?.[id]?.visualHeader && !hideVisualHeader
              ? getHeaderData(visualsData[id].visualHeader)
              : undefined
          }
        />
      ) : null}
    </Column>
  );
};

export default VisualContainer;
