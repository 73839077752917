import { WebsocketAction } from '../../../types/websocket';
import type { CustomAttribute } from '../../../types/customAttribute';
import { omit } from '../../../utils/object';
import type { AttributeMessage } from '../types/websocket';

export enum AttributesActionType {
  Load = 'load',
  Message = 'message',
}

interface LoadAttributes {
  readonly type: AttributesActionType.Load;
  readonly data: CustomAttribute[];
}

interface AttributesMessage {
  readonly type: AttributesActionType.Message;
  readonly data: AttributeMessage;
}

export type AttributesState = CustomAttribute[] | null;
export type AttributesAction = LoadAttributes | AttributesMessage;

const websocketMessageReducer = (
  state: AttributesState,
  message: AttributeMessage
): AttributesState => {
  if (state === null) {
    return state;
  }

  switch (message.action) {
    case WebsocketAction.Create: {
      const { attribute_id } = message.data;

      return [
        omit(message.data, ['user_group']),
        ...state.filter((attribute) => attribute.attribute_id !== attribute_id),
      ];
    }

    case WebsocketAction.Update: {
      const { attribute_id, status, sku_count } = message.data;

      return state.map((attribute) =>
        attribute.attribute_id === attribute_id
          ? { ...attribute, status, sku_count }
          : attribute
      );
    }

    case WebsocketAction.Delete: {
      const { attribute_id } = message.data;

      return state.filter(
        (attribute) => attribute.attribute_id !== attribute_id
      );
    }

    default:
      return state;
  }
};

export const attributesReducer = (
  state: AttributesState,
  action: AttributesAction
): AttributesState => {
  switch (action.type) {
    case AttributesActionType.Load:
      return action.data;

    case AttributesActionType.Message: {
      return websocketMessageReducer(state, action.data);
    }

    default:
      return state;
  }
};
