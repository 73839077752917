/* eslint-disable indent */
import type { FunctionComponent, ChangeEvent, MouseEvent } from 'react';
import { useState, useContext, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  InlineLoading,
  FormGroup,
  Checkbox,
} from '@carbon/react';
import {
  TrashCan,
  User,
  Location,
  Product,
  Locked,
  Unlocked,
  Edit,
} from '@carbon/icons-react';
import CustomGroupsEdit from '../CustomGroupsEdit/CustomGroupsEdit';
import { CustomGroupsContext } from '../../../providers/CustomGroupsProvider';
import { AppContext } from '../../../providers/AppProvider';
import {
  CustomGroupAccess,
  USER_ROLE,
  USER_TYPE,
} from '../../../constants/metadata';
import {
  renderCustomGroupsMetadata,
  type UpdateCustomGroupParams,
} from './CustomGroups';
import {
  editCustomGroupClick,
  existingCustGroupClick,
} from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import type { ExistingCustGroup } from '../../../reducers/CustomGroupsReducer';
import '../../../styles/components/CustomGroups.scss';
import '../../../styles/components/viewReport.scss';

interface CustomGroupItemProps {
  readonly group: ExistingCustGroup;
  readonly filteredCustGroups: ExistingCustGroup[];
  readonly deletingItems: string[];
  readonly updatingItems: string[];
  readonly updateCustomGroup: (params: UpdateCustomGroupParams) => void;
  readonly deleteCustomGroup: (customGroupIds: string[]) => void;
  readonly setFilteredCustGroups: (updatedData: ExistingCustGroup[]) => void;
}

export interface CustGroupsData {
  readonly customGroup: string;
  readonly entity_no: number;
  readonly id: number;
  readonly label: string;
}

const CustomGroupItem: FunctionComponent<CustomGroupItemProps> = ({
  group,
  updatingItems,
  deletingItems,
  filteredCustGroups,
  updateCustomGroup,
  deleteCustomGroup,
  setFilteredCustGroups,
}) => {
  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const { user } = useContext(AppContext);
  const {
    selectedDimGroup,
    existingCustGroups,
    updateSelectedCustomGroupId,
    updateSelectedDimGroup,
  } = useContext(CustomGroupsContext);
  const [hoveredGroup, setHoveredGroup] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);

  const renderGroupType = (dim: string) => {
    switch (dim) {
      case 'product':
        return <Product />;
      case 'location':
        return <Location />;
      case 'member':
        return <User />;
    }
  };

  const isAccessButtonDisabled = useMemo(
    () => filteredCustGroups.filter((group) => group.checked).length > 1,
    [filteredCustGroups]
  );

  const checkUserType =
    (user?.user_type === USER_TYPE.CUSTOMER &&
      user.user_role === USER_ROLE.SUPER_USER) ||
    user?.user_type === USER_TYPE.SUPPORT;

  return (
    <div
      key={group.id}
      onMouseEnter={() => setHoveredGroup(true)}
      onMouseLeave={() => setHoveredGroup(false)}
      data-testid={`uploaded-custom-group-${group.id}`}
      className="CustomGroups__item"
      onClick={(e) => {
        e.stopPropagation();
        posthogEvent(existingCustGroupClick, {
          groupId: group.id,
          groupName: group.name,
          dimension: group.dimension,
        });
        updateSelectedCustomGroupId(group.id);
        updateSelectedDimGroup(capitalizeFirstLetter(group.dimension));
        navigate(`my-uploaded-groups/${group.id}`);
      }}
    >
      {deletingItems.includes(group.id) || updatingItems.includes(group.id) ? (
        <div className="CustomGroups__item-loader">
          <InlineLoading
            description={
              updatingItems.includes(group.id) ? 'Updating...' : 'Deleting...'
            }
          />
        </div>
      ) : (
        <>
          <div>
            <div className="CustomGroups__item-title-wrapper">
              <FormGroup onClick={(e: MouseEvent) => e.stopPropagation()}>
                <Checkbox
                  key={`custom-group-checkbox-${group.id}`}
                  labelText=""
                  hideLabel
                  id={`custom-group-checkbox-${group.id}`}
                  checked={group.checked}
                  data-testid={`custom-group-checkbox-${group.id}`}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    { checked }: { checked: boolean }
                  ) => {
                    const updatedData = filteredCustGroups.map((gr) =>
                      gr.id === group.id ? { ...gr, checked } : gr
                    );

                    setFilteredCustGroups(updatedData);
                  }}
                />
              </FormGroup>
              <CustomGroupsEdit
                textInputRef={textInputRef}
                groupId={group.id}
                groupName={group.name}
                hoveredGroup={hoveredGroup}
                existingCustGroups={existingCustGroups}
                updateCustomGroup={updateCustomGroup}
              />
            </div>
            <div className="CustomGroups__metadata">
              {renderCustomGroupsMetadata(group)}
            </div>
          </div>

          <div className="CustomGroups__tag-wrapper">
            <div className="CustomGroups__tag">
              <div className="CustomGroups__tag-icon">
                {renderGroupType(group.dimension)}
              </div>
              <span>{`${group.dimension.replace(/^\w/, (c) =>
                c.toUpperCase()
              )} Group${
                group.row_count ? ` - (${group.row_count} Items)` : ''
              }`}</span>
            </div>
            <div data-testid={`custom-groups-action-${group.id}`}>
              <Button
                kind="secondary"
                disabled={isAccessButtonDisabled}
                size="md"
                renderIcon={
                  group.access === CustomGroupAccess.PRIVATE ? Locked : Unlocked
                }
                className={`CustomGroups__access-btn has-icon ${
                  group.access === CustomGroupAccess.PUBLIC
                    ? 'cds--btn--secondary__neutral'
                    : ''
                }`}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  updateCustomGroup({
                    customGroupIds: [group.id],
                    targetValue: '',
                    currentAccess: group.access,
                  });
                }}
                data-testid={`custom-group-access-btn-${group.id}`}
              >
                {group.access}
              </Button>
              <IconButton
                className="CustomGroups__icon-btn"
                label="Edit custom group"
                kind="ghost"
                disabled={isAccessButtonDisabled}
                data-testid={`edit-custom-group-icon-btn-${group.id}`}
                onClick={(e: MouseEvent) => {
                  posthogEvent(editCustomGroupClick);
                  e.stopPropagation();
                  updateSelectedDimGroup(
                    group.dimension.replace(/^\w/, (c) => c.toUpperCase())
                  );
                  navigate(
                    `/custom-groups/upload?mode=edit&id=${group.id}&dimension=${selectedDimGroup}&name=${group.name}`
                  );
                }}
                size="sm"
              >
                <Edit />
              </IconButton>
              {checkUserType && (
                <IconButton
                  label="Delete custom group"
                  disabled={
                    deletingItems.length > 0 ||
                    updatingItems.length > 0 ||
                    isAccessButtonDisabled
                  }
                  kind="ghost"
                  data-testid="delete-custom-group-icon-btn"
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    deleteCustomGroup([group.id]);
                  }}
                  size="sm"
                >
                  <TrashCan />
                </IconButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CustomGroupItem;
