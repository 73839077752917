import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import '../styles/components/side-panel.scss';
import type {
  OptimiserFormState,
  ClusterGoal,
  DataInitialisation,
  NewProduct,
  OptimiserMode,
  OptimiserStrategy,
} from '../reducers/OptimiserFormReducer';
import {
  initialOptimiserFormState,
  optimiserFormReducer,
} from '../reducers/OptimiserFormReducer';
import {
  LOAD_OPTIMISER_DATA,
  UPDATE_CLUSTER_GOALS,
  UPDATE_NEW_PRODUCTS,
  UPDATE_STRATEGY,
  UPDATE_OPTIMISER_MODE,
  UPDATE_PLAN_INCLUSION,
  UPDATE_PLAN_PLANOGRAM,
  UPDATE_PLAN_TARGET,
} from '../constants/reducers';

type LoadData = (data: DataInitialisation) => void;
type UpdateMode = (mode: OptimiserMode) => void;
type UpdateStrategy = (strategy: OptimiserStrategy) => void;
type UpdateNewProducts = (newProducts: NewProduct[]) => void;
type UpdateClusterGoals = (clusterGoals: ClusterGoal[]) => void;
type UpdatePlanInclusion = (data: {
  readonly id: string;
  readonly include: boolean;
}) => void;
type UpdatePlanPlanogram = (data: {
  readonly id: string;
  readonly planogramNo: string;
}) => void;
type UpdatePlanTarget = (data: {
  readonly id: string;
  readonly value: number;
}) => void;

export interface OptimiserFormContextProps {
  readonly cdtPath: string;
  readonly initialMode: OptimiserMode | undefined;
  readonly children?: ReactNode;
}

export interface OptimiserContextType extends OptimiserFormState {
  readonly cdtPath: OptimiserFormContextProps['cdtPath'];
  readonly loadData: LoadData;
  readonly updateMode: UpdateMode;
  readonly updateStrategy: UpdateStrategy;
  readonly updateNewProducts: UpdateNewProducts;
  readonly updateClusterGoals: UpdateClusterGoals;
  readonly updatePlanInclusion: UpdatePlanInclusion;
  readonly updatePlanPlanogram: UpdatePlanPlanogram;
  readonly updatePlanTarget: UpdatePlanTarget;
}

export const OptimiserFormContext = createContext<OptimiserContextType>(
  {} as OptimiserContextType
);

const OptimiserFormProvider: FunctionComponent<OptimiserFormContextProps> = ({
  cdtPath,
  initialMode,
  children,
}) => {
  const [state, dispatch] = useReducer(optimiserFormReducer, {
    ...initialOptimiserFormState,
    mode: initialMode,
  });

  const loadData: LoadData = (data) => {
    dispatch({ type: LOAD_OPTIMISER_DATA, data });
  };

  const updateMode: UpdateMode = (mode) => {
    dispatch({ type: UPDATE_OPTIMISER_MODE, mode });
  };

  const updateStrategy: UpdateStrategy = (strategy) => {
    dispatch({ type: UPDATE_STRATEGY, strategy });
  };

  const updateNewProducts: UpdateNewProducts = (newProducts) => {
    dispatch({ type: UPDATE_NEW_PRODUCTS, newProducts });
  };

  const updateClusterGoals: UpdateClusterGoals = (clusterGoals) => {
    dispatch({ type: UPDATE_CLUSTER_GOALS, clusterGoals });
  };

  const updatePlanInclusion: UpdatePlanInclusion = (data) => {
    dispatch({ type: UPDATE_PLAN_INCLUSION, ...data });
  };

  const updatePlanPlanogram: UpdatePlanPlanogram = (data) => {
    dispatch({ type: UPDATE_PLAN_PLANOGRAM, ...data });
  };

  const updatePlanTarget: UpdatePlanTarget = (data) => {
    dispatch({ type: UPDATE_PLAN_TARGET, ...data });
  };

  const contextValue = useMemo(() => {
    return {
      ...state,
      cdtPath,
      loadData,
      updateMode,
      updateStrategy,
      updateNewProducts,
      updateClusterGoals,
      updatePlanInclusion,
      updatePlanPlanogram,
      updatePlanTarget,
    };
  }, [state]);

  return (
    <OptimiserFormContext.Provider value={contextValue}>
      {children}
    </OptimiserFormContext.Provider>
  );
};

export default OptimiserFormProvider;
