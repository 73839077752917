/* eslint-disable indent */

import { SUBSTITUTE_HIGHLIGHT_KEY } from '../components/Charts/AssortmentGrid';
import type { HighlightedCell } from '../components/Charts/DataGrid';
import {
  CONTINUOUS_SCALE,
  DISCRETE_SCALE,
  INDEX_SCALE,
} from '../constants/palettes';
import type {
  AssortmentData,
  AssortmentState,
  CDTNode,
  CDTNodeData,
  Decision,
  HighlightLegendEntry,
  HighlightLegendItem,
} from '../reducers/AssortmentReducer';
import type {
  ClusterGoal,
  DecisionGroups,
  LocationPlanTargetGrid,
  NewProduct,
  PlanTargetGrid,
} from '../reducers/OptimiserFormReducer';
import { OptimiserMode } from '../reducers/OptimiserFormReducer';
import type { ReportConfig, VisualData } from '../reducers/ReportReducer';
import { VisualType } from '../reducers/ReportReducer';
import { pick } from './object';
import { findVisual, formatNumber, getTextColor } from './reportUtils';

export interface NodeMetrics {
  sales: string;
  skus: string;
  profit?: string;
}

export const PRICE_POINT_ERROR_NEGATIVE =
  'Price point must be a positive number';

export const PRICE_POINT_ERROR_TOO_LARGE = 'Price point must be below $100,000';

export const getCdKey = (depth: number): string | undefined => {
  switch (depth) {
    case 1:
      return 'cd3';
    case 2:
      return 'cd2';
    case 3:
      return 'cd1';
  }
};

export const getTotal = (
  data: AssortmentData[],
  key: 'pct_spend' | 'pct_profit_share'
) => {
  return data.reduce((prev, curr) => (curr[key]?.value || 0) + prev, 0);
};

export const getMetrics = (
  assortmentData: AssortmentData[],
  key?: CDKeys,
  child?: AssortmentData
): NodeMetrics => {
  if (!key && !child) {
    const sales = formatNumber({
      value: getTotal(assortmentData, 'pct_spend'),
      format: 'percent1',
    });
    const skus = formatNumber(
      {
        value: assortmentData.length,
        format: 'integer',
      },
      { notation: 'compact' }
    );
    const profit = formatNumber({
      value: getTotal(assortmentData, 'pct_profit_share'),
      format: 'percent1',
    });
    return {
      sales,
      skus,
      profit,
    };
  } else {
    const allProducts: AssortmentData[] = assortmentData.filter(
      (row) => key && row[key].id === child?.[key].id
    );
    const sales = formatNumber({
      value: getTotal(allProducts, 'pct_spend'),
      format: 'percent1',
    });
    const skus = formatNumber(
      {
        value: allProducts.length,
        format: 'integer',
      },
      { notation: 'compact' }
    );
    const profit = formatNumber({
      value: getTotal(allProducts, 'pct_profit_share'),
      format: 'percent1',
    });
    return {
      sales,
      skus,
      profit,
    };
  }
};

export const deriveCDTData = (
  assortmentData: AssortmentData[],
  cdtRootName: string
): CDTNodeData[] => {
  const totalMetrics = getMetrics(assortmentData);
  const CDTData: CDTNodeData[] = [
    {
      name: cdtRootName,
      id: 'ROOT',
      parentNodeId: null,
      level: 0,
      ...totalMetrics,
    },
  ];

  const parentNodes: Decision[] = [];
  assortmentData.forEach((node) => {
    if (!parentNodes.map((n) => n.id).includes(node.cd3.id)) {
      parentNodes.push(node.cd3);
    }
  });

  parentNodes.sort((a, b) => {
    return typeof a.order === 'number' && typeof b.order === 'number'
      ? a.order - b.order
      : -1;
  });

  parentNodes.forEach((parent) => {
    const parentRow = assortmentData.find((row) => row.cd3.id === parent.id);
    const metrics = getMetrics(assortmentData, 'cd3', parentRow);

    CDTData.push({
      id: parent.id,
      name: parent.value,
      parentNodeId: 'ROOT',
      level: 3,
      ...metrics,
    });

    const descendants = assortmentData
      .filter((row) => row.cd3.id === parent.id)
      .sort((a, b) =>
        typeof a.cd2.order === 'number' && typeof b.cd2.order === 'number'
          ? a.cd2.order - b.cd2.order
          : -1
      )
      .sort((a, b) =>
        typeof a.cd1.order === 'number' && typeof b.cd1.order === 'number'
          ? a.cd1.order - b.cd1.order
          : -1
      );

    descendants.forEach((child) => {
      if (!CDTData.map((node) => node.id).includes(child.cd2.id)) {
        const metrics = getMetrics(assortmentData, 'cd2', child);
        CDTData.push({
          id: child.cd2.id,
          name: child.cd2.value,
          parentNodeId: parent.id,
          level: 2,
          ...metrics,
        });
      }
      if (!CDTData.map((node) => node.id).includes(child.cd1.id)) {
        const metrics = getMetrics(assortmentData, 'cd1', child);
        CDTData.push({
          id: child.cd1.id,
          name: child.cd1.value,
          parentNodeId: child.cd2.id,
          level: 1,
          ...metrics,
        });
      }
    });
  });

  return CDTData;
};

const getCdsToUpdate = (cdKey: string): string[] | undefined => {
  switch (cdKey) {
    case 'cd3':
      return ['cd3', 'cd2', 'cd1'];
    case 'cd2':
      return ['cd2', 'cd1'];
    case 'cd1':
      return ['cd1'];
  }
};

export const getCDId = (
  cdKey: string,
  newValue: string,
  row: AssortmentData
) => {
  switch (cdKey) {
    case 'cd3':
      return newValue;
    case 'cd2':
      return newValue + '_' + row.cd3.value;
    case 'cd1':
      return newValue + '_' + row.cd2.value + '_' + row.cd3.value;
  }
};

export const getInitialAssortmentData = (
  assortmentData: AssortmentData[]
): AssortmentData[] => {
  return assortmentData.map((row) => {
    return {
      ...row,
      cd1: { ...row.cd1, id: getCDId('cd1', row.cd1.value, row) },
      cd2: { ...row.cd2, id: getCDId('cd2', row.cd2.value, row) },
      cd3: { ...row.cd3, id: getCDId('cd3', row.cd3.value, row) },
    };
  }) as AssortmentData[];
};

export const getDiscreteHighlightItems = (
  header: Header,
  assortmentData: AssortmentData[]
): HighlightLegendEntry => {
  const { key, header: label } = header;

  const distinctValues = Array.from(new Set(assortmentData.map((d) => d[key])));

  const items = distinctValues.map((label, index) => ({
    label,
    colour: DISCRETE_SCALE[index % DISCRETE_SCALE.length],
  }));

  return { type: 'discrete', key, label, items };
};

export const getContinuousHighlightItems = (
  header: Header,
  assortmentData: AssortmentData[]
): HighlightLegendEntry => {
  const { key, header: label } = header;

  const values: number[] = assortmentData
    .map((data) => data[key].value)
    .filter(Boolean);

  const format = assortmentData[0][key].format;
  const min = Math.min(...values);
  const max = Math.max(...values);
  const diff = max - min;
  const step = diff / 10;

  const items = [...Array(10)].reduce((valueAccumulator, _, index) => {
    const value = min + step * (9 - index);

    if (!value) {
      return valueAccumulator;
    }

    return [
      ...valueAccumulator,
      {
        range: {
          min: index === 9 ? 0 : value,
          max: index === 0 ? Infinity : value + step,
        },
        label: formatNumber({ value, format }),
        colour: CONTINUOUS_SCALE[index],
      },
    ];
  }, [] as HighlightLegendItem[]);

  return { type: 'continuous', key, label, items };
};

export const getIndexHighlightItems = (
  header: Header
): HighlightLegendEntry => {
  const { key, header: label } = header;

  const items = INDEX_SCALE.map(({ min, max, label, colour, showLabel }) => ({
    range: { min, max },
    label,
    colour,
    ...(typeof showLabel === 'boolean' && { showLabel }),
  }));

  return { type: 'index', key, label, items };
};

const getSubstituteLabel = ({
  min,
  max,
  isFirstItem,
  isLastItem,
}: {
  min: number;
  max: number;
  isFirstItem: boolean;
  isLastItem: boolean;
}) => {
  if (isFirstItem) {
    return 'Most';
  }

  if (isLastItem) {
    return 'Least';
  }

  return `${min * 100}-${max * 100}%`;
};

export const getSubstituteHighlightItems = (
  header: Pick<Header, 'key' | 'header'>
): HighlightLegendEntry => {
  const { key, header: label } = header;

  const items: HighlightLegendItem[] = [...Array(10)].reduce(
    (valueAccumulator, _value, index, { length: numberOfSteps }) => {
      const isFirstItem = index === 0;
      const isLastItem = index === numberOfSteps - 1;

      const min = (numberOfSteps * (numberOfSteps - index - 1)) / 100;
      const max = (numberOfSteps * (numberOfSteps - index)) / 100;

      return [
        ...valueAccumulator,
        {
          range: { min, max },
          label: getSubstituteLabel({ min, max, isFirstItem, isLastItem }),
          colour: CONTINUOUS_SCALE[index],
          ...(!isFirstItem && !isLastItem && { showLabel: false }),
        },
      ];
    },
    [] as HighlightLegendItem[]
  );

  return { type: 'continuous', key, label, items };
};

export const getHighlightItems = (
  assortmentData: AssortmentData[],
  headers: Header[]
): HighlightLegendEntry[] => {
  const highlightMap = headers.reduce((headersAccumulator, header) => {
    switch (header?.highlightable) {
      case 'discrete':
        return [
          ...headersAccumulator,
          getDiscreteHighlightItems(header, assortmentData),
        ];

      case 'continuous':
        return [
          ...headersAccumulator,
          getContinuousHighlightItems(header, assortmentData),
        ];

      case 'index':
        return [...headersAccumulator, getIndexHighlightItems(header)];

      default:
        return headersAccumulator;
    }
  }, [] as HighlightLegendEntry[]);

  const substituteHighlight: HighlightLegendEntry = {
    ...getSubstituteHighlightItems({
      key: SUBSTITUTE_HIGHLIGHT_KEY,
      header: 'Substitutes',
    }),
    title: 'Substitutability Strength',
    hidden: true,
  };

  return [...highlightMap, substituteHighlight];
};

export const updateCDForIds = (
  ids: Array<string | number>,
  cdKey: string,
  newName: string,
  assortmentData: AssortmentData[]
): AssortmentData[] => {
  const cdsToUpdate = getCdsToUpdate(cdKey);

  return assortmentData.map((row) => {
    if (!ids.includes(row.PRODUCT_ID)) {
      return row;
    } else {
      const newRow = { ...row };
      cdsToUpdate?.forEach((levelKey) => {
        const configName =
          levelKey === cdKey ? newName : newRow[levelKey].value;

        newRow[levelKey] = {
          ...newRow[levelKey],
          value: configName,
          id: getCDId(levelKey, configName, newRow),
        };
      });

      return newRow;
    }
  });
};

export const updateGoal = (
  value: number,
  pctOrAbs: 'pct' | 'abs',
  totalProducts: number
): DelistGoal => {
  if (pctOrAbs === 'abs') {
    return {
      numProducts: value,
      pctProducts: Math.round((value / totalProducts) * 100),
    };
  } else {
    return {
      numProducts: Math.round((totalProducts * value) / 100),
      pctProducts: value,
    };
  }
};

export const getReportPath = (apiUrl: string, includeFile?: boolean) => {
  const reportPath = apiUrl.split('/');
  const fileName = reportPath.splice(reportPath.length - 1, 1);
  const path = reportPath.join('/').replace('?path=', '/').substring(1) + '/';
  return includeFile ? path + `${fileName}` : path;
};

export const getFileUpdateReportPath = (apiUrl: string): string => {
  const [url, searchParams] = apiUrl.slice(1).split('?');
  const file = new URLSearchParams(searchParams).get('path');

  return `${url}/${file}`;
};

export const getOptimiserTabIndex = (
  reportConfig: ReportConfig,
  reportTemplateId: string
) => {
  return reportConfig.configuration.switchers[reportTemplateId].findIndex(
    (s) => s.name === 'Optimisation'
  );
};

export const hasPlanograms = (reportConfig: ReportConfig): boolean => {
  const { planogram_groups: planogramGroups } =
    reportConfig.parameters.template_requests[0] ?? {};

  return Array.isArray(planogramGroups) && planogramGroups.length > 0;
};

export const getOptimiserMode = (
  reportConfig: ReportConfig,
  reportTemplateId: string
): OptimiserMode | undefined => {
  const {
    configuration: { switchers, visuals },
  } = reportConfig;

  const visualContent = switchers[reportTemplateId].find(
    ({ name }) => name === 'Optimisation'
  )?.visualContent;

  if (!visualContent) {
    return undefined;
  }

  const visual = findVisual(
    visuals[reportTemplateId],
    ({ id, type }) =>
      visualContent.includes(id) &&
      !!type &&
      [VisualType.OPTIMISER_GRID, VisualType.OPTIMISER_PLAN_GRID].includes(type)
  );

  if (!visual) {
    return undefined;
  }

  return visual.type === VisualType.OPTIMISER_PLAN_GRID
    ? OptimiserMode.Plan
    : OptimiserMode.NonPlan;
};

export const reorderAssortmentData = (
  assortmentData: AssortmentData[],
  cdKey: string,
  idOrder: Array<string | number>
): AssortmentData[] => {
  return assortmentData.map((row) => {
    if (idOrder.includes(row[cdKey].id)) {
      return {
        ...row,
        [cdKey]: {
          ...row[cdKey],
          order: idOrder.indexOf(row[cdKey].id) + 1,
        },
      };
    }
    return row;
  });
};

export const reorderList = <T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const nodeHeaderStyle =
  'padding: 9px 16px; border-bottom: 1px solid #1b71d5; line-height: 22px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;';
export const nodeBodyStyle = 'padding: 16px; display: flex; ';
export const skusStyle = 'padding: 5px 16px; border-left: 4px solid #0f62fe;';
export const salesStyle = 'padding: 5px 16px; border-left: 4px solid #0E6027;';
export const profitStyle = 'padding: 5px 16px; border-left: 4px solid #f25829;';
export const NODE_HEIGHT = 104;
export const NODE_WIDTH = 259;
export const PADDING = 20;

export const getNodeY = (node: CDTNode) => {
  const indexInChildren = node.parent.children?.indexOf(node) as number;
  return indexInChildren * (node.height + PADDING);
};

export const getNodePath = (node: CDTNode) => {
  const indexInChildren = node.parent.children?.indexOf(node);
  return `m 145 ${getNodeY(node) + 50} l -16 0 l 0 -${
    node.height + (indexInChildren === 0 ? -2 : 20)
  }`;
};

export const drawNodePath = (
  node: d3.Selection<Element, CDTNode, Element, CDTNode>
): d3.Selection<Element, CDTNode, Element, CDTNode> => {
  return node
    .attr('d', (node: CDTNode) => getNodePath(node))
    .attr('stroke-width', 1)
    .attr('fill', 'none')
    .style('z-index', 0)
    .attr('stroke', '#1b71d5');
};

export const getUniqueCDGroups = (
  assortmentData: AssortmentData[],
  cdKey: CDKeys,
  selection?: DecisionGroups
): Decision[] => {
  const allCds = assortmentData
    .filter((row) => row.cd3.id !== '0')
    .filter(
      (row) =>
        !selection ||
        ((!selection.cd3?.id || row.cd3.id === selection.cd3?.id) &&
          (!selection.cd2?.id || row.cd2.id === selection.cd2?.id))
    )
    .map((row) => {
      return {
        id: row[cdKey].id,
        value: row[cdKey].value,
      };
    });

  return [...new Map(allCds.map((cd) => [cd.id, cd])).values()];
};

export const getProductsInCdGroup = (
  assortmentData: AssortmentData[],
  decisionGroup: DecisionGroups,
  cdKey = 'cd1'
): AssortmentData[] => {
  return assortmentData.filter((row) => {
    return row[cdKey].id === decisionGroup?.[cdKey]?.id;
  });
};

export const pricePointValid = (
  num: number
): { valid: boolean; message?: string } => {
  if (num >= 0 && num < 100000) {
    return { valid: true };
  } else {
    return {
      valid: false,
      message:
        num < 0 ? PRICE_POINT_ERROR_NEGATIVE : PRICE_POINT_ERROR_TOO_LARGE,
    };
  }
};
export const marginRateValid = (value?: number): boolean => {
  return typeof value === 'number' && value > 0 && value <= 1 && !isNaN(value);
};

export const newProductFormValid = ({
  newProduct,
  newProductList,
  showMargin,
}: {
  newProduct: NewProduct;
  newProductList: NewProduct[];
  showMargin: boolean;
}): boolean => {
  const { name, lookalike, cd1, price_point, margin_rate } = newProduct;
  return !!(
    name.trim().length > 0 &&
    lookalike.value &&
    lookalike.id &&
    cd1 &&
    pricePointValid(price_point).valid &&
    (!showMargin || marginRateValid(margin_rate)) &&
    !nameAlreadyExists(name, newProductList)
  );
};

export const deriveUnsavedChanges = (
  state: AssortmentState,
  initialState: AssortmentState,
  data1: AssortmentData[],
  data2: AssortmentData[]
): boolean => {
  const updatedFields1 = data1.map((d) => {
    const { cd1, cd2, cd3 } = d;
    return {
      cd1: cd1.value,
      cd2: cd2.value,
      cd3: cd3.value,
    };
  });
  const updatedFields2 = data2.map((d) => {
    const { cd1, cd2, cd3 } = d;
    return {
      cd1: cd1.value,
      cd2: cd2.value,
      cd3: cd3.value,
    };
  });

  return (
    state.assortmentData !== initialState.assortmentData &&
    JSON.stringify(updatedFields1) !== JSON.stringify(updatedFields2)
  );
};

interface ObjectWithName {
  name: string;
}

export const nameAlreadyExists = (name: string, list: ObjectWithName[]) => {
  return list.map((item) => item.name.trim()).includes(name.trim());
};

export const getTotalLevelKey = (reportConfig: ReportConfig): number => {
  if (reportConfig.parameters.template_requests[0].location_groups) {
    return reportConfig.parameters.template_requests[0].location_groups
      .length === 1
      ? 1
      : 0;
  }
  return 1;
};

export const getClusterGoals = (
  optimiserData: {
    [key: string]: AssortmentData[];
  },
  clusters: DropdownOptions[],
  newProducts: NewProduct[],
  existingGoals?: { id: string; filter: number }[]
): ClusterGoal[] => {
  const clusterGoals: ClusterGoal[] = clusters.map((cluster) => {
    const { key } = cluster;
    const existingGoal = existingGoals?.find(
      (g) => Number(g.id) === Number(key)
    );
    const goal = existingGoal ?? { id: key, filter: 0 };
    const { id, filter } = goal;
    const max =
      optimiserData[id].length +
      newProducts.filter(
        (prod) =>
          prod.locations?.includes(Number(id)) || prod.locations?.length === 0
      ).length;

    return {
      key: Number(id),
      goal: max - filter,
      max,
      name: clusters.find((c) => c.key === Number(id))?.label as string,
    };
  });

  return clusterGoals;
};

export const getOptimiserDataUpdates = <
  T = Record<string, Partial<AssortmentData>[]>
>({
  data,
  includeActions,
}: {
  data: VisualData<AssortmentData>;
  includeActions: boolean;
}): T => {
  const dropdownKeys = Object.keys(data.rows ?? {});

  return dropdownKeys.reduce((rowUpdates, dropdownKey) => {
    const editableHeaders = data.headers?.[dropdownKey].filter(
      ({ key, editable }) => editable && (includeActions || key === 'notes')
    );

    const nonValidatedHeaderKeys =
      editableHeaders
        ?.filter(({ editable: { type } = {} }) => type !== 'dropdown')
        .map(({ key }) => key) ?? [];

    const validatedHeaders = editableHeaders?.filter(
      ({ editable: { type } = {} }) => type === 'dropdown'
    );

    return {
      ...rowUpdates,
      [dropdownKey]: data.rows?.[dropdownKey].map((row) => {
        const validatedHeaderValues = validatedHeaders?.reduce(
          (valueAccumulator, header) => {
            const { editable, key } = header;

            if (!editable || editable.type !== 'dropdown') {
              return valueAccumulator;
            }

            const { options } = editable;

            // Legacy value (being phased out)
            if (
              typeof options[0] === 'string' &&
              (options as string[]).some((option) => row[key].value === option)
            ) {
              const option = row[key] as EditableDropdownOption;
              return { ...valueAccumulator, [key]: option };
            }

            // Modern value
            // This matches the value within the options array and uses that
            // instead of the source value - this is because the Analytics
            // Engine and Data Science use differing hex colour casing, with the
            // Back End requiring the casing used in the options array.
            if (typeof options[0] === 'object') {
              const option = (options as EditableDropdownOption[]).find(
                ({ type }) => row[key].type === type
              );

              return option
                ? { ...valueAccumulator, [key]: option }
                : valueAccumulator;
            }

            return valueAccumulator;
          },
          {} as Record<string, EditableDropdownOption>
        );

        return {
          ...pick(row, nonValidatedHeaderKeys as (keyof typeof row)[]),
          ...validatedHeaderValues,
          PRODUCT_ID: row.PRODUCT_ID,
        };
      }),
    };
  }, {} as T);
};

export const getExtendedHeaders = (
  headers: Header[],
  rows: AssortmentData[]
): Header[] => {
  return headers.map((header) => {
    const { key, type, info } = header;

    if (type !== 'plan') {
      return header;
    }

    const skuCount = rows.reduce(
      (counter, row) =>
        // Warning: This relies on string matching
        key in row && row[key].value.includes('In') ? counter + 1 : counter,
      0
    );

    return {
      ...header,
      header: `${header.header} (${skuCount}/${info?.skuTarget})`,
      info: { ...info, skuCount },
    };
  });
};

export const isLocationPlanTargetsGrid = (
  planGrid: PlanTargetGrid | LocationPlanTargetGrid
): planGrid is LocationPlanTargetGrid =>
  (planGrid as LocationPlanTargetGrid).headers.some(
    ({ key }) => key === 'locationName'
  );

export const getClusterFilters = (
  clusterGoals: ClusterGoal[]
): { filter: number } | { clusters: { id: string; filter: number }[] } => {
  const totalOnly = clusterGoals.some(({ name }) => name === 'Total');

  const clusters = clusterGoals.map(({ key, goal, max }) => ({
    id: key.toString(),
    filter: max - goal,
  }));

  return totalOnly ? { filter: clusters[0].filter } : { clusters: clusters };
};

export const getPlanFilters = (
  planTargets: PlanTargetGrid | LocationPlanTargetGrid | undefined
): { filter: number } | { clusters: { id: string; filter: number }[] } => {
  if (!planTargets || !isLocationPlanTargetsGrid(planTargets)) {
    return { filter: 0 };
  }

  const locationsIds = Array.from(
    new Set(planTargets.rows.map(({ location }) => location))
  );

  return {
    clusters: locationsIds.map((id) => ({ id: String(id), filter: 0 })),
  };
};

export const getLargestPlanHeader = (headers: Header[]): Header | undefined => {
  return headers.reduce((acc: Header | undefined, header) => {
    const { type, level } = header;

    if (type !== 'plan' || typeof level !== 'number') {
      return acc;
    }

    if (typeof acc === 'undefined') {
      return header;
    }

    return typeof acc.level === 'number' && acc.level < level ? header : acc;
  }, undefined);
};

export const getSkuAllocationChange = ({
  existingValue,
  targetValue,
}: {
  existingValue: number;
  targetValue: number;
}): string => {
  if (targetValue === existingValue) {
    return '(0.0% change)';
  }

  return targetValue > existingValue
    ? `(${((targetValue / existingValue - 1) * 100).toFixed(1)}% increase)`
    : `(${((1 - targetValue / existingValue) * 100).toFixed(1)}% decrease)`;
};

export const getSubstituteProducts = (
  product: AssortmentData
): [name: string, transfer: NumberPoint][] =>
  [...Array(5)]
    .map((_value, index): [string, NumberPoint] => [
      product[`subs_${index + 1}_name`],
      product[`subs_${index + 1}_tfr`],
    ])
    .filter(([name]) => name !== '-');

export const getSubstituteHighlightedCells = ({
  assortmentData,
  legend,
  productName,
  substitutes,
}: {
  assortmentData: AssortmentData[];
  legend: HighlightLegendEntry;
  productName: string;
  substitutes: [name: string, transfer: NumberPoint][];
}): Record<string, HighlightedCell> => {
  const substituteProducts = Object.fromEntries(
    substitutes.map(([name, transfer], index) => [name, { ...transfer, index }])
  );

  const rowHighlighting = assortmentData.map((productRow) =>
    Object.entries(productRow).reduce(
      (cellAccumulator, [cellKey, cellValue]) => {
        const cellId = `${productRow.id}:${cellKey}`;

        if (typeof cellValue !== 'string') {
          return cellAccumulator;
        }

        if (cellKey !== 'PRODUCT' && cellValue === productName) {
          return { ...cellAccumulator, [cellId]: { showBorder: true } };
        }

        if (cellKey === 'PRODUCT' && substituteProducts[cellValue]) {
          const { value } = substituteProducts[cellValue];

          const colour =
            legend.items.find(
              ({ range }) =>
                typeof value === 'number' &&
                range &&
                value >= range.min &&
                value <= range.max
            )?.colour || '#fff';

          const formattedValue = formatNumber({ value, format: 'percent2' });

          return {
            ...cellAccumulator,
            [cellId]: {
              color: getTextColor(colour),
              backgroundColor: colour,
              tooltip: `Substitution transfer ${formattedValue}`,
            },
          };
        }

        return cellAccumulator;
      },
      {} as Record<string, HighlightedCell>
    )
  );

  return Object.assign({}, ...rowHighlighting);
};

export const getDiscreteHighlightedCells = ({
  assortmentData,
  legend,
}: {
  assortmentData: AssortmentData[];
  legend: HighlightLegendEntry;
}): Record<string, HighlightedCell> =>
  assortmentData.reduce((productRowAccumulator, productRow) => {
    const cellId = `${productRow.id}:PRODUCT`;
    const legendValue = productRow[legend.key];

    const colour = legend.items.find((i) => i.label === legendValue)?.colour;

    return {
      ...productRowAccumulator,
      [cellId]: {
        color: colour && getTextColor(colour),
        backgroundColor: colour,
        tooltip: `${legend.label}: ${legendValue}`,
      },
    };
  }, {} as Record<string, HighlightedCell>);

export const getContinuousHighlightedCells = ({
  assortmentData,
  legend,
}: {
  assortmentData: AssortmentData[];
  legend: HighlightLegendEntry;
}): Record<string, HighlightedCell> =>
  assortmentData.reduce((productRowAccumulator, productRow) => {
    const cellId = `${productRow.id}:PRODUCT`;
    const legendValue = productRow[legend.key];

    const colour =
      legend.items.find(
        ({ range }) =>
          range &&
          legendValue.value >= range.min &&
          legendValue.value <= range.max
      )?.colour || '#fff';

    return {
      ...productRowAccumulator,
      [cellId]: {
        color: getTextColor(colour),
        backgroundColor: colour,
        tooltip: `${legend.label}: ${formatNumber(legendValue)}`,
      },
    };
  }, {} as Record<string, HighlightedCell>);

export const isSkuTargetValid = (
  value: string | number | undefined
): boolean => {
  if (typeof value === 'undefined' && value !== '') {
    return false;
  }

  const numericValue = Number(value);

  return (
    Number.isInteger(numericValue) && numericValue > 0 && numericValue < 100000
  );
};
