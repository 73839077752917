/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { Button } from '@carbon/react';
import { Reset } from '@carbon/icons-react';
import DataGrid, { CustomActionKey, type RowData } from './DataGrid';
import type { VisualData } from '../../reducers/ReportReducer';
import { VisualType } from '../../reducers/ReportReducer';
import { DEFAULT_PRODUCT_SUMMARY } from '../../constants/assortmentFileSync';
import { getExtendedHeaders } from '../../utils/CDTUtils';
import type { AssortmentData } from '../../reducers/AssortmentReducer';
import { findVisualByType } from '../../utils/reportUtils';
import { ModalContext } from '../../providers/ModalProvider';

interface OptimiserGridProps {
  isAuthor?: boolean;
  isSupplier?: boolean;
  dropdownKey: string;
  reportTemplateId: string;
  reportTemplateIndex: number;
}

const getConfiguration = (
  configuration: number[] | Record<string, number[]> | undefined,
  dropdownKey: string
): number[] | undefined => {
  if (!configuration) {
    return undefined;
  }

  return Array.isArray(configuration)
    ? configuration
    : configuration[dropdownKey];
};

const OptimiserGrid: FunctionComponent<OptimiserGridProps> = ({
  isAuthor,
  isSupplier,
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
}) => {
  const { visualsData, reportConfig, patchVisualsData, updateVisualsData } =
    useContext(ReportContext);
  const {
    optimiserId,
    optimiserValid,
    optimiserData,
    updateOptimiserRows,
    saveOptimiserData,
    saveOptimiserGrid,
    validateOptimiser,
  } = useContext(AssortmentContext);
  const { updateModal, toggleModal } = useContext(ModalContext);

  const confirmOptimisation = () => {
    updateModal({
      type: 'warning',
      title: 'Are you sure you want to re-optimise?',
      body: 'The changes will create a new range recommendation based on your changes.',
      primaryCTAText: 'Yes Re-Optimise',
      secondaryCTAText: 'Cancel',
      onSecondaryCTAClick: () => {
        toggleModal(false);
      },
      onPrimaryCTAClick: () => {
        toggleModal(false);
        saveOptimiserData();
      },
    });
  };

  const editRowTransformer = <
    T extends RowData<AssortmentData> = RowData<AssortmentData>
  >({
    row,
    header,
    headers,
    value,
  }: {
    row: T;
    header: Header;
    headers: Header[];
    value: string | EditableDropdownOption;
  }): T | undefined => {
    const isUpdatableValue =
      value && typeof value === 'object' && 'type' in value;

    if (header.type !== 'plan' || !isUpdatableValue) {
      return undefined;
    }

    return headers.reduce((rowAccumulator, headerItem) => {
      if (
        headerItem.type !== 'plan' ||
        typeof headerItem.level !== 'number' ||
        typeof header.level !== 'number'
      ) {
        return rowAccumulator;
      }

      if (
        (value.type === 'force_in' && headerItem.level < header.level) ||
        (value.type === 'force_out' && headerItem.level > header.level)
      ) {
        return rowAccumulator;
      }

      return { ...rowAccumulator, [headerItem.key]: value };
    }, row);
  };

  const onEditRows = ({
    rows,
    cellKey,
    header,
  }: {
    rows: RowData<AssortmentData>[];
    cellKey: string;
    header: Header;
  }): void => {
    if (
      (cellKey === 'action' || header.type === 'plan') &&
      JSON.stringify(rows) !== JSON.stringify(optimiserData[dropdownKey])
    ) {
      const { visuals } = reportConfig.configuration;
      validateOptimiser(false);
      const productSummaryId = findVisualByType(
        visuals[reportTemplateId],
        VisualType.PRODUCT_SUMMARY
      )?.id;
      const analysisCardsId = findVisualByType(
        visuals[reportTemplateId],
        VisualType.SUMMARY_CARDS
      )?.id;

      if (typeof productSummaryId === 'number') {
        const newSummary: VisualData = {
          ...visualsData[productSummaryId],
          summary: {
            ...visualsData[productSummaryId].summary,
            [dropdownKey]: [DEFAULT_PRODUCT_SUMMARY],
          } as VisualData['summary'],
        };

        updateVisualsData(productSummaryId, newSummary);
      }

      if (typeof analysisCardsId === 'number') {
        const updatedCards = visualsData[analysisCardsId].cards?.[
          dropdownKey
        ].map((card) => {
          const children =
            'children' in card
              ? card.children.map((child) => ({ ...child, value: undefined }))
              : [];

          return {
            ...card,
            data: { ...card.data, value: undefined },
            children,
          };
        });

        if (updatedCards) {
          const newCards: VisualData = {
            ...visualsData[analysisCardsId],
            cards: {
              ...visualsData[analysisCardsId].cards,
              [dropdownKey]: updatedCards,
            },
          };

          updateVisualsData(analysisCardsId, newCards);
        }
      }
    }

    updateOptimiserRows(rows as AssortmentData[], dropdownKey);
  };

  const optimiserVisual = visualsData[optimiserId];

  const { isSearchable, isVisible, stickyColumns, headers, rows } =
    (optimiserVisual as VisualData<AssortmentData>) ?? {};

  const rowData: RowData<AssortmentData>[] | undefined = rows?.[dropdownKey];

  // TODO: To be improved after TRP-3533
  const isNestedConfiguration = !Array.isArray(isVisible);
  const columnWidths = (
    isNestedConfiguration
      ? optimiserVisual?.columnWidths?.[dropdownKey]
      : optimiserVisual?.columnWidths
  ) as Record<string, number> | undefined;

  if (!headers?.[dropdownKey] || !rows?.[dropdownKey]) {
    return null;
  }

  const extendedHeaders = getExtendedHeaders(
    headers[dropdownKey],
    rows[dropdownKey]
  );

  return (
    <div className="OptimiserGrid" key={dropdownKey}>
      <DataGrid
        headerData={extendedHeaders}
        rowData={rowData as RowData<AssortmentData>[]}
        stickyColumns={getConfiguration(stickyColumns, dropdownKey)}
        visibleColumns={getConfiguration(isVisible, dropdownKey)}
        searchable
        exportedFileName={`${reportConfig.report_name} - Optimisation`}
        searchableColumns={getConfiguration(isSearchable, dropdownKey)}
        columnWidths={columnWidths}
        visualData={optimiserVisual}
        id={optimiserId}
        dropdownKey={dropdownKey}
        resizable
        showPagination={false}
        reportConfig={reportConfig}
        isAuthor={isAuthor}
        isSupplier={isSupplier}
        isNestedConfiguration={isNestedConfiguration}
        customToolbarActions={
          !optimiserValid && isAuthor && !isSupplier
            ? [
                {
                  key: CustomActionKey.OPTIMISER_SAVE_BUTTON,
                  element: (
                    <Button
                      className="OptimiserGrid__save-btn has-icon"
                      renderIcon={Reset}
                      size="md"
                      onClick={confirmOptimisation}
                    >
                      Save & Optimise
                    </Button>
                  ),
                },
              ]
            : []
        }
        patchVisualsData={patchVisualsData}
        onEditRows={onEditRows}
        onSave={optimiserValid ? saveOptimiserGrid : undefined}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        editRowTransformer={editRowTransformer}
      />
    </div>
  );
};

export default OptimiserGrid;
