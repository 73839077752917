/* eslint-disable indent */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { Button, ToastNotification } from '@carbon/react';
import { Compare } from '@carbon/icons-react';
import NPDReducer, {
  initialNPDState,
  NPDProductSelection,
  type NPDGridRowDataType,
  type NPDState,
} from '../reducers/NPDReducer';
import {
  UPDATE_NPD_ROW_FILE_DATA,
  UPDATE_NPD_ROWS,
} from '../constants/reducers';
import { ReportContext } from './ReportProvider';
import { findVisual } from '../utils/reportUtils';
import {
  VisualType,
  type FileData,
  type VisualData,
} from '../reducers/ReportReducer';
import ReportMetricsCard from '../components/Cards/ReportMetricsCard';
import '../styles/components/NPDFinder.scss';
import { useFeatureFlagEnabled } from '../context/posthog';

interface NPDContextType extends NPDState {
  isResetButtonDisabled: boolean;
  isNPDFinderFeaturesEnabled: boolean;
  resetGridSelection: () => void;
  updateNPDRowFileData: (
    dropdownKey: string,
    rows: NPDGridRowDataType[]
  ) => void;
  updateNPDRows: (
    rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>
  ) => void;
}

export const NPDContext = createContext<NPDContextType>({} as NPDContextType);

interface NPDProviderProps {
  children: ReactNode;
  reportTemplateId: string;
}

const NPDProvider: FunctionComponent<NPDProviderProps> = ({
  children,
  reportTemplateId,
}) => {
  const [state, dispatch] = useReducer(NPDReducer, initialNPDState);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const {
    visualsData,
    dropdownSelections,
    tabIndex,
    reportConfig: {
      configuration: { visuals },
    },
  } = useContext(ReportContext);
  const NPDGridVisualConfig = findVisual(
    visuals[reportTemplateId],
    (vis) => vis.type === VisualType.SELECTION_GRID
  );
  const { id: NPDGridId } = NPDGridVisualConfig ?? {};
  const NPDGridVisualData = NPDGridId
    ? (visualsData[NPDGridId] as VisualData<NPDGridRowDataType>)
    : undefined;
  const { files, ...sharedVisualData } = NPDGridVisualData || {};
  const visualDropdownSelection = NPDGridId
    ? dropdownSelections?.[tabIndex]?.[NPDGridId]
    : undefined;
  const currentVisualData = visualDropdownSelection
    ? ({
        ...sharedVisualData,
        ...files?.[visualDropdownSelection],
      } as FileData<NPDGridRowDataType>)
    : undefined;
  const dropdownSelection = currentVisualData?.fileDropdownSelections;
  const isNPDFinderFeaturesEnabled = useFeatureFlagEnabled('npd-finder');

  const updateNPDRowFileData = (
    dropdownKey: string,
    rows: NPDGridRowDataType[]
  ) => {
    dispatch({ type: UPDATE_NPD_ROW_FILE_DATA, rows, dropdownKey });
  };

  const updateNPDRows = (
    rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>
  ) => {
    dispatch({ type: UPDATE_NPD_ROWS, rowData });
  };

  useEffect(() => {
    if (currentVisualData?.rows) {
      updateNPDRows(currentVisualData.rows);
    }
  }, [currentVisualData?.rows]);

  const cardsData: Card[] = useMemo(() => {
    if (!dropdownSelection) {
      return [];
    }
    const cardData = state.cards[dropdownSelection];

    if (!cardData) {
      return [];
    }

    return [
      {
        title: 'Focus Products',
        tooltip: 'Select at least 1 SKU',
        index: 'neutral',
        data: {
          value: cardData.focusProducts,
          format: 'integer',
        },
      },
      {
        title: 'Benchmark Products',
        tooltip: 'Select at least 3 SKUs',
        index: 'neutral',
        data: {
          value: cardData.benchmarkProducts,
          format: 'integer',
        },
      },
    ];
  }, [state.cards, dropdownSelection]);

  const isAddToBencmarkButtonDisabled = useMemo(() => {
    if (!dropdownSelection) {
      return true;
    }
    return !state.rows?.[dropdownSelection]?.some(
      ({ SELECTION }) => SELECTION.type === NPDProductSelection.UNSELECTED
    );
  }, [state.rows]);

  const handleAddToBenchmarkClick = () => {
    if (!dropdownSelection) {
      return;
    }
    const rows = state.rows[dropdownSelection];
    const newRows = rows.map((row) =>
      row.SELECTION.type === NPDProductSelection.UNSELECTED
        ? {
            ...row,
            SELECTION: {
              type: NPDProductSelection.BENCHMARK,
              value: 'Benchmark',
            },
          }
        : row
    );
    updateNPDRowFileData(dropdownSelection, newRows);
    setIsToastVisible(true);
    setTimeout(() => setIsToastVisible(false), 10000);
  };

  const isResetButtonDisabled = useMemo(() => {
    if (!dropdownSelection) {
      return true;
    }
    return !!state.rows?.[dropdownSelection]?.every(
      ({ SELECTION }) => SELECTION.type === NPDProductSelection.UNSELECTED
    );
  }, [state.rows]);

  const resetGridSelection = () => {
    if (!dropdownSelection) {
      return;
    }
    const rows = state.rows[dropdownSelection];
    const newRows = rows.map((row) => ({
      ...row,
      SELECTION: {
        type: NPDProductSelection.UNSELECTED,
        value: 'Unselected',
      },
    }));
    updateNPDRowFileData(dropdownSelection, newRows);
  };

  const NPDContextValue: NPDContextType = useMemo(
    () => ({
      rows: state.rows,
      cards: state.cards,
      isResetButtonDisabled,
      isNPDFinderFeaturesEnabled,
      resetGridSelection,
      updateNPDRowFileData,
      updateNPDRows,
    }),
    [state]
  );

  return (
    <NPDContext.Provider value={NPDContextValue}>
      <div className="NPDProvider">
        {isNPDFinderFeaturesEnabled && cardsData.length > 0 ? (
          <div className="NPDProvider__actions">
            <div className="NPDProvider__actions-cards">
              <ReportMetricsCard
                items={cardsData}
                hideIndicator
                hasPlaceholderItems={false}
                borderColour="#F25829"
              />
            </div>
            <div>
              <Button
                kind="secondary"
                className="gap--small"
                size="sm"
                disabled={isAddToBencmarkButtonDisabled}
                onClick={handleAddToBenchmarkClick}
              >
                Add all unselected to Benchmark <Compare />
              </Button>
              {isToastVisible && (
                <ToastNotification
                  className="NPDProvider__toast"
                  title="All unselected rows have been added to Benchmark"
                  size="sm"
                  lowContrast
                  kind="info"
                  onCloseButtonClick={() => setIsToastVisible(false)}
                />
              )}
            </div>
          </div>
        ) : null}
        {children}
      </div>
    </NPDContext.Provider>
  );
};

export const NPDWrapper = (
  children: JSX.Element[],
  reportTemplateId: string
) => <NPDProvider reportTemplateId={reportTemplateId}>{children}</NPDProvider>;

export default NPDProvider;
