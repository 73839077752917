import {
  useCallback,
  useContext,
  type ChangeEvent,
  type FunctionComponent,
} from 'react';
import {
  FileUploaderDropContainer,
  FileUploaderItem,
  TableHeader,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@carbon/react';
import { Upload } from '@carbon/icons-react';
import { pick } from '../../../utils/object';
import { ModalContext } from '../../../providers/ModalProvider';
import apiRequest from '../../../api';
import { useAuth0 } from '@auth0/auth0-react';
import type { AxiosError } from 'axios';
import { AttributeValuesContext } from '../../../providers/AttributeValuesProvider';
import { AppContext } from '../../../providers/AppProvider';
import { AttributeValuesWebsocketStatus } from '../../../types/customAttribute';
import { WebsocketAction } from '../../../types/websocket';
import type { GenericWebsocketMessage } from '../../../types/websocket';
import type { AttributeValuesMessage } from '../types/websocket';
import useWebsocketListener from '../../../hooks/useWebsocketListener';

interface AttributeValuesUploadError {
  messages: {
    file: string;
    type: string;
  };
}

const isAttributeValuesMessage = (
  message: GenericWebsocketMessage | AttributeValuesMessage
): message is AttributeValuesMessage =>
  message.action === WebsocketAction.Update &&
  message?.data?.type === 'validation_status';

const AttributeValuesUpload: FunctionComponent = () => {
  const { bannerId, groupId } = useContext(AppContext);
  const { fileSpecs, updateUploadMetadata, updateFileSpecs, resetUploadData } =
    useContext(AttributeValuesContext);
  const { updateModal } = useContext(ModalContext);
  const { getAccessTokenSilently } = useAuth0();

  const onMessage = useCallback(
    (message: GenericWebsocketMessage | AttributeValuesMessage) => {
      if (!isAttributeValuesMessage(message)) {
        return;
      }

      const validationErrorModal: ModalOptions = {
        type: 'error',
        title: 'Something went wrong',
        body: 'An issue occurred while processing your file. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        isCloseIconVisible: false,
      };

      updateFileSpecs({
        status: 'edit',
        invalid: false,
        iconDescription: 'Upload complete',
      });

      switch (message.data.status) {
        case AttributeValuesWebsocketStatus.Validated: {
          updateUploadMetadata(message.data);
          return;
        }

        case AttributeValuesWebsocketStatus.ValidationFailed: {
          updateUploadMetadata(null);
          updateModal(validationErrorModal);
          return;
        }
      }
    },
    []
  );

  useWebsocketListener(onMessage);

  const uploadFile = async (file: File) => {
    const fileInfo = pick(file, ['name', 'type', 'size']);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_name', file.name);

      const token = await getAccessTokenSilently();
      await apiRequest(
        `/custom/${bannerId}/user-groups/${groupId}/attribute-files`,
        'POST',
        token,
        formData,
        undefined,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
    } catch (error) {
      const errorResponse = error as AxiosError<AttributeValuesUploadError>;
      if (errorResponse?.response) {
        updateFileSpecs({
          ...fileInfo,
          status: 'edit',
          invalid: true,
          iconDescription: 'Upload Error',
          errorSubject: errorResponse.response.data.messages.file,
        });
      } else {
        resetUploadData();
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error uploading custom attributes. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        });
      }
    }
  };

  const onAddFiles = (
    e: ChangeEvent<HTMLInputElement>,
    { addedFiles }: { addedFiles: File[] }
  ) => {
    e.stopPropagation();

    if (addedFiles.length === 0) {
      return;
    }

    const file = addedFiles[0];
    const fileInfo = pick(file, ['name', 'type', 'size']);

    if (
      file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'text/csv'
    ) {
      updateFileSpecs({
        ...fileInfo,
        status: 'edit',
        invalid: true,
        iconDescription: 'Upload Error',
        errorSubject:
          'Unsupported file type. Please upload an XLSX or CSV file.',
      });

      return;
    }

    if (file.size >= 6291456) {
      updateFileSpecs({
        ...fileInfo,
        status: 'edit',
        invalid: true,
        iconDescription: 'Upload Error',
        errorSubject: 'Please Upload a smaller file size below 6MB',
      });

      return;
    }

    uploadFile(file);

    updateFileSpecs({
      name: fileInfo.name,
      type: fileInfo.type,
      size: fileInfo.size,
      invalid: false,
      status: 'uploading',
      iconDescription: 'Uploading...',
    });
  };

  const dragDropMessage = (
    <div className="CustomGroupsUploadValidator__drag-and-drop-wrapper">
      <Upload
        size={20}
        className="CustomGroupsUploadValidator__drag-and-drop-button"
      />
      <div>
        <span className="CustomGroupsUploadValidator__drag-and-drop-info">
          Drag and drop file here or{' '}
          <span className="CustomGroupsUploadValidator__drag-and-drop-info--highlighted">
            click to upload
          </span>
        </span>
      </div>
    </div>
  );

  return (
    <div className="AttributeValuesUpload__container">
      <div className="AttributeValuesUpload__container-info">
        <p className="body-02">
          Supported file types are
          <span className="body-emphasis-02"> csv or xlsx.</span>
        </p>
        <p className="body-02">
          File Number & Size Limit:{' '}
          <span className="body-emphasis-02"> 1 File - Max 6MB</span>
        </p>
        <ul className="AttributeValuesUpload__container-info__description">
          <li className="AttributeValuesUpload__container-info__list">
            {' '}
            Ensure the file contains a
            <span className="body-emphasis-02"> Product ID column</span>. The
            Product ID value{' '}
            <span className="body-emphasis-02">
              should match exactly to the product names already available in the
              platform,
            </span>
            <br /> otherwise the system is unable to match the value.
          </li>
          <Table
            className="AttributeValuesUpload__preview-table"
            aria-label="Sample CSV structure"
            data-testid="attribute-upload-preview-table"
          >
            <TableHead>
              <TableRow>
                <TableHeader>ID</TableHeader>
                <TableHeader>Attribute (i.e Flavour)</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1234567</TableCell>
                <TableCell>Attribute Value (i.e Orange)</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell>3456789</TableCell>
                <TableCell>Attribute Value (i.e Strawberry)</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <ul className="AttributeValuesUpload__container-info__list">
            <li>
              Ensure the{' '}
              <span className="body-emphasis-02"> attribute name matches </span>
              the existing attribute names, otherwise we won&apos;t be able to
              update the values.
            </li>
            <li>
              Any <span className="body-emphasis-02"> cells left blank</span>{' '}
              under the attributes column will be{' '}
              <span className="body-emphasis-02">
                considered as a deleted value.
              </span>
            </li>
            <li>
              If your SKU ID starts with a 0, please start all records in the
              column with a single quote &apos;.
            </li>
          </ul>
        </ul>
      </div>
      <FileUploaderDropContainer
        labelText={dragDropMessage}
        multiple={false}
        accept={['.csv', '.xlsx']}
        onAddFiles={onAddFiles}
        data-testid="attribute-upload-icon"
      />
      {fileSpecs && (
        <FileUploaderItem
          {...fileSpecs}
          key={`file-uploader-item-${fileSpecs.name}`}
          name={fileSpecs.name}
          size="md"
          onDelete={resetUploadData}
        />
      )}
    </div>
  );
};
export default AttributeValuesUpload;
