import type { FunctionComponent } from 'react';
import { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  DataTable,
  Pagination,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableContainer,
  TableExpandHeader,
  TableHead,
  TableHeader,
  TableRow as CarbonTableRow,
  TableSelectAll,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react';
import type { CarbonIconType } from '@carbon/icons-react';
import { REPORT_STATUS } from '../Workspace';
import { useStickyTableHeader } from '../../utils/useStickyTableHeader';
import '../../styles/components/workspace.scss';
import type { ActionOption, RowStatus } from './ReportTableRow';
import ReportTableRow from './ReportTableRow';
import { ModalContext } from '../../providers/ModalProvider';
import { getSelectedRowIds } from '../../utils/DataGridUtils';
import type { DeleteHandlerParameters, JustUpdated } from './MyReportsTable';
import { AppContext } from '../../providers/AppProvider';
import { getReportLink } from '../../utils/reportTableUtils';
import { tableRowClick, reportsTableSortBy } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import { usePagination } from '../../hooks';

interface TableCell {
  errors: null | string;
  id: string;
  info: { [key: string]: string };
  isEditable: boolean;
  isEditing: boolean;
  isValid: boolean;
  value: REPORT_STATUS;
}
export interface TableRow {
  cells: TableCell[];
  disabled: boolean;
  id: string;
  isExpanded: boolean;
  isSelected: boolean;
}

export interface TableRef {
  getSelectedRows: () => ReportMetaData[];
  handleSelectAll: () => void;
}

interface ReportTableProps {
  rowData: ReportMetaData[];
  filteredData: ReportMetaData[];
  tableId: string;
  headerData: { key: string; header: string }[];
  toolbarActions: (options?: { isSharedReportSelected?: boolean }) => {
    renderIcon: CarbonIconType;
    onClick: (reports: CarbonSelectedRow[], callback?: () => void) => void;
    dataTestid: string;
    label: string;
  }[];
  rowStatus: RowStatus;
  isEditable?: boolean;
  title?: string;
  justUpdated?: JustUpdated;
  actionOptions: (options: {
    scheduledStatus?: string;
    isScheduleOptionDisabled: boolean;
    isAuthor: boolean;
    isFavourite: boolean;
  }) => ActionOption[];
  handleDelete?: (parameters: DeleteHandlerParameters) => void;
  hasId?: (rowId: string) => boolean;
  handleUpdateScheduleReport?: (row: CarbonSelectedRow) => void;
}

const ReportTable: FunctionComponent<ReportTableProps> = ({
  headerData,
  rowData,
  filteredData,
  tableId,
  toolbarActions,
  rowStatus,
  isEditable,
  justUpdated,
  actionOptions,
  hasId,
  handleDelete,
  handleUpdateScheduleReport,
}) => {
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const { bannerId, groupId, user } = useContext(AppContext);
  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const [searchParams, setSearchParams] = useSearchParams();
  const tableRef = useRef<TableRef | null>(null);

  const { updateModal } = useContext(ModalContext);

  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPage,
    setPagination,
  } = usePagination({
    initialPageSize: searchParams.get('page_size')
      ? Number(searchParams.get('page_size'))
      : undefined,
  });

  const handleRowClick = (row: TableRow) => {
    const statusIndex = row.cells.findIndex((cell) => cell.id.match('status'));
    const statusCell = row.cells[statusIndex];
    if (statusCell.value === REPORT_STATUS.COMPLETED) {
      const { url_route, user_group, section, report_name } =
        rowData.find((report) => report.run_id === row.id) || {};
      posthogEvent(tableRowClick, {
        reportType: url_route,
        section,
        user_group,
        report_name,
      });
      const params = Object.fromEntries(searchParams);
      if (bannerId && groupId) {
        navigate(getReportLink(row.id, bannerId, groupId), {
          state: {
            ...params,
          },
        });
      }
    } else if (statusCell.value === REPORT_STATUS.PROCESSING) {
      updateModal({
        type: 'info',
        title: 'Report in progress',
        body: 'You are unable to view this report as it is not currently available. Once the status has changed, you will be able to view it.',
      });
    } else if (statusCell.value === REPORT_STATUS.FAILED) {
      updateModal({
        type: 'error',
        title: 'Report failed',
        body: 'The report failed to execute. This could be due to the selections made. If the issue persists please contact our helpdesk.',
      });
    } else if (statusCell.value === REPORT_STATUS.NO_DATA) {
      updateModal({
        type: 'error',
        title: 'No data',
        body: 'This report failed to execute as your selection produced no results. Please amend and resubmit.',
      });
    } else if (statusCell.value === REPORT_STATUS.CANCELLED) {
      updateModal({
        type: 'error',
        title: 'Automatically Cancelled',
        body: 'This report has been cancelled as it has been processing for over 25 minutes. Please amend and resubmit.',
        primaryCTAText: 'OK',
      });
    }
  };

  const getRows = (
    rows,
    getRowProps,
    getSelectionProps,
    selectedRows,
    expandRow
  ): JSX.Element[] => {
    return rows.map((row) => {
      const data = rowData.find((r) => r.id === row.id);
      const isAuthor = data?.user_id === user?.id;
      const isFavourite = data?.is_favourite;
      return (
        <ReportTableRow
          key={row.id}
          row={row}
          isActive={activeRow === row.id}
          rowStatus={rowStatus}
          isOverflowMenuDisabled={
            selectedRows.length === 1
              ? !row.isSelected
              : selectedRows.length > 0
          }
          isAuthor={isAuthor}
          actionOptions={actionOptions}
          hasId={hasId}
          expandedRowLength={headerData.length + 3}
          rowProps={getRowProps({ row })}
          selectionProps={getSelectionProps({ row })}
          setActiveRow={(id) => setActiveRow(id)}
          handleRowClick={handleRowClick}
          expandRow={expandRow}
          handleDelete={handleDelete}
          isEditable={isEditable}
          justUpdatedStatus={justUpdated?.[row.id]}
          isFavourite={!!isFavourite}
          scrollbarRef={scrollbarRef}
          handleUpdateScheduleReport={handleUpdateScheduleReport}
        />
      );
    });
  };

  const { isSticky, scrollbarRef } = useStickyTableHeader({
    rowsLength: rowData?.length,
    tableId,
  });

  const filterParams = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, page_size, ...filters } = Object.fromEntries(searchParams);
    return new URLSearchParams(filters).toString();
  }, [searchParams]);

  useEffect(() => {
    changePaginationState({
      page: 1,
      pageSize,
    });
  }, [filterParams]);

  useEffect(() => {
    if (filteredData.length) {
      changePaginationState({
        page: Number(searchParams.get('page')) || 1,
        pageSize: Number(searchParams.get('page_size')) || pageSizes[0].value,
      });
    }
  }, [searchParams]);

  //This is a temporary solution until we handle the pagination on the backend
  const changePaginationState = (pageInfo: {
    page: number;
    pageSize: number;
  }) => {
    if (page != pageInfo.page) {
      setPage(pageInfo.page);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: String(pageInfo.page),
      });
    }
    if (pageSize != pageInfo.pageSize) {
      setPagination(pageInfo);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page_size: String(pageInfo.pageSize),
        page: '1',
      });
    }
  };

  useEffect(() => {
    if (tableRef.current && tableRef.current.getSelectedRows().length > 0) {
      tableRef.current.handleSelectAll();
    }
  }, [searchParams]);

  return (
    <div
      className={`report-table-wrapper ${
        filteredData.length === 0 ? 'hidden-table' : ''
      }`}
    >
      <div className="report-table-container" id={tableId}>
        <DataTable rows={filteredData} headers={headerData} ref={tableRef}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getBatchActionProps,
            getSelectionProps,
            getToolbarProps,
            selectedRows,
            expandRow,
            selectRow,
            sortBy,
          }) => {
            const rowStartIndex = (page - 1) * pageSize;

            const visibleRows = isShowAll
              ? rows
              : rows.slice(rowStartIndex, rowStartIndex + pageSize);

            const isSharedReportSelected = selectedRows.some(({ id }) => {
              const reportData = rowData.find((report) => report.run_id === id);
              return !!reportData && reportData.user_id !== user?.id;
            });

            return (
              <TableContainer>
                <TableToolbarContent>
                  <TableToolbar {...getToolbarProps()}>
                    <TableBatchActions
                      {...getBatchActionProps()}
                      className="toolbar-buttons"
                      data-testid="table-batch-actions"
                    >
                      {toolbarActions({ isSharedReportSelected }).map(
                        ({ label, renderIcon, onClick, dataTestid }) => (
                          <TableBatchAction
                            key={label}
                            renderIcon={renderIcon}
                            onClick={() => onClick(selectedRows)}
                            data-testid={dataTestid}
                          >
                            {label}
                          </TableBatchAction>
                        )
                      )}
                    </TableBatchActions>
                  </TableToolbar>
                </TableToolbarContent>
                <div className="data-table-container">
                  <Table {...getTableProps()} className="table-content">
                    <TableHead
                      data-testid="table-header"
                      className={`${
                        selectedRows.length === 0 ? 'rounded' : ''
                      }`}
                    >
                      <CarbonTableRow
                        className={
                          isSticky ? 'sticky-header' : 'visible-header'
                        }
                      >
                        <TableExpandHeader />
                        <TableSelectAll
                          {...getSelectionProps()}
                          onSelect={() => {
                            const selectedRowIds = getSelectedRowIds(
                              visibleRows,
                              selectedRows
                            );
                            selectedRowIds.forEach((id) => {
                              selectRow(id);
                            });
                          }}
                          className="header-checkbox"
                        />
                        {headers.map((header) => (
                          <TableHeader
                            key={header.key}
                            tabIndex={0}
                            {...getHeaderProps({ header })}
                            isSortable={
                              !['shared_with_users', 'schedule_data'].includes(
                                header.key
                              )
                            }
                            onClick={() => {
                              sortBy(header.key);
                              posthogEvent(reportsTableSortBy, {
                                sortedBy: header.header,
                              });
                            }}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader className="table-header context-menu-header">
                          Actions
                        </TableHeader>
                      </CarbonTableRow>
                    </TableHead>
                    <TableBody>
                      {getRows(
                        visibleRows,
                        getRowProps,
                        getSelectionProps,
                        selectedRows,
                        expandRow
                      )}
                    </TableBody>
                  </Table>
                </div>
              </TableContainer>
            );
          }}
        </DataTable>
      </div>
      <div
        className="data-grid-scrollbar-container"
        data-testid="data-grid-scrollbar-container"
        ref={scrollbarRef}
      >
        <div />
      </div>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={changePaginationState}
        page={page}
        pageSize={pageSize}
        pageSizes={pageSizes}
        itemRangeText={getItemRangeText}
        totalItems={filteredData.length}
      />
    </div>
  );
};

export default ReportTable;
