import type { FunctionComponent } from 'react';
import { Fragment, useContext, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import { Version } from '@carbon/icons-react';
import usePosthog from '../utils/posthog';
import { APP_SECTIONS, supportSection } from '../constants/metadata';
import { sidebarLinkClick } from '../constants/posthog';
import '../styles/components/sidebar.scss';
import { AppContext } from '../providers/AppProvider';
import { useActiveFeatureFlags } from '../context/posthog';

interface SidebarProps {
  productFruitsUserData: string | null;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
  productFruitsUserData,
}) => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const posthogEvent = usePosthog();
  const featureFlags = useActiveFeatureFlags();
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const handleAppSectionsClick = (navigateTo: string) => {
    posthogEvent(sidebarLinkClick, {
      sidebarLink: navigateTo,
    });
    const workspaceSearchParams = state
      ? createSearchParams(state)?.toString()
      : searchParams.toString();
    if (navigateTo === 'workspace' && pathname.includes('view-report')) {
      navigate({
        pathname: 'workspace/my-reports',
        search: workspaceSearchParams,
      });
    } else {
      navigate(`/${navigateTo}`);
    }
  };

  return (
    <SideNav
      aria-label="Side navigation"
      isRail
      isChildOfHeader={false}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className={
          isMouseOver ? 'sidebar-oneviu-logo--full' : 'sidebar-oneviu-logo'
        }
      />
      <SideNavItems>
        {APP_SECTIONS.filter(({ hide = false }) => {
          if (typeof hide === 'boolean') {
            return !hide;
          }

          return user ? hide({ user, featureFlags }) : false;
        }).map(({ name, navigateTo, icon, hasDivider }) => (
          <Fragment key={name}>
            {hasDivider && <div className="sidebar-divider" />}
            <SideNavLink
              data-testid={`sidebar-${navigateTo}`}
              renderIcon={icon}
              isActive={navigateTo && !!pathname.match(navigateTo)}
              onClick={() => handleAppSectionsClick(`${navigateTo}`)}
              large
            >
              {name}
            </SideNavLink>
          </Fragment>
        ))}
        <div className="sidebar-spacer" />
        {process.env.REACT_APP_VERSION && (
          <SideNavLink
            key="version"
            className="sidebar-version"
            target="_blank"
            renderIcon={Version}
            href={process.env.REACT_APP_RELEASE_NOTES}
          >
            {`Version: ${process.env.REACT_APP_VERSION}`}
          </SideNavLink>
        )}

        <div className="sidebar-divider" />
        <div className="support-section">
          {supportSection(productFruitsUserData)
            .filter((section) => !section.hide)
            .map((supportSection) => {
              return (
                <SideNavLink
                  key={supportSection.name}
                  href={supportSection.href}
                  rel={supportSection.rel}
                  target="_blank"
                  renderIcon={supportSection.icon}
                  large
                >
                  {supportSection.name}
                </SideNavLink>
              );
            })}
        </div>
      </SideNavItems>
    </SideNav>
  );
};

export default Sidebar;
