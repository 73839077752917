import { useContext } from 'react';
import '../../../styles/components/assortment.scss';
import { Button } from '@carbon/react';
import { Save } from '@carbon/icons-react';
import { AssortmentContext } from '../../../providers/AssortmentProvider';
import { AppContext } from '../../../providers/AppProvider';

const CDTLegend = () => {
  const { user } = useContext(AppContext);
  const { saveAssortmentData } = useContext(AssortmentContext);
  return (
    <div className="cdt-legend" data-testid="cdt-legend">
      Legend
      <div className="cdt-legend-item cdt-legend-item--blue"># SKUs</div>
      <div className="cdt-legend-item cdt-legend-item--green">Sales PPT%</div>
      {!!user?.show_extension_measure && (
        <div className="cdt-legend-item cdt-legend-item--orange">
          Profit NNP%
        </div>
      )}
      <Button
        data-testid="save-btn"
        kind="ghost"
        renderIcon={Save}
        onClick={saveAssortmentData}
        hasIconOnly
        iconDescription="Save changes"
      />
    </div>
  );
};

export default CDTLegend;
