import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { ChevronDown, ChevronRight } from '@carbon/icons-react';
import { hasMatchingDescendant, renderNodeLabel } from '../../../utils';
import '../../../styles/components/hierarchy.scss';
import CategoryHierarchyTree from './CategoryHierarchyTree';
import { HomeContext } from '../../../providers/HomeProvider';

interface HierarchyNodeProps {
  node: HierNode;
  searchTerm: string;
  error?: boolean;
  nodeLevel?: number;
  parentNodes?: HierNode[];
  selectedItemPath: string[];
  handleItemClick: (items: HierNode[]) => void;
}

const CategoryHierarchyNode: FunctionComponent<HierarchyNodeProps> = ({
  node,
  searchTerm,
  error,
  nodeLevel,
  parentNodes = [],
  selectedItemPath,
  handleItemClick,
}) => {
  const [filteredChildren, setFilteredChildren] = useState(node.children);
  const [expanded, setExpanded] = useState(false);
  const level = Number(node.level);

  const { selectedCategory } = useContext(HomeContext);

  useEffect(() => {
    setFilteredChildren(node.children);
  }, [node.children]);

  useEffect(() => {
    const shouldBeExpanded =
      selectedItemPath.includes(node.id) &&
      selectedItemPath.indexOf(node.id) !== selectedItemPath.length - 1;
    if (searchTerm.length === 0) {
      setFilteredChildren(node.children);
      setExpanded(shouldBeExpanded);
    } else if (searchTerm.length > 3) {
      setExpanded(hasMatchingDescendant(node, searchTerm) || shouldBeExpanded);

      const children =
        node.children &&
        node.children.filter((child) => {
          return hasMatchingDescendant(child, searchTerm);
        });

      setFilteredChildren(children);
    }
  }, [searchTerm, selectedItemPath]);

  const childrenFilteredOut = () => {
    return expanded && filteredChildren && filteredChildren.length === 0;
  };

  const classNames = [
    'CategoryHierarchy__node',
    selectedCategory?.id === node.id && 'selected',
    error ? 'error' : 'hoverable clickable',
    !expanded && !!node.children && 'collapsed',
  ].filter((s) => !!s);

  const getPadding = () => {
    switch (level) {
      case 1:
        return '16px';
      case 2:
        return '0px';
      default:
        return node.children
          ? 12 * (level - 1) + 'px'
          : 12 * (level - 1) + 22 + 'px';
    }
  };

  return (
    <>
      <div
        data-testid={`category-hierarchy-node-${node.id ?? node.label}`}
        className={classNames.join(' ')}
        style={{
          paddingLeft: getPadding(),
          marginRight: getPadding(),
        }}
        onClick={() => {
          handleItemClick([...parentNodes, node]);
        }}
      >
        {!!node.children && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
            className="CategoryHierarchy__expand-chevron"
            data-testid={`expand-node-${node.id ?? node.label}`}
          >
            {expanded ? <ChevronDown size={24} /> : <ChevronRight size={20} />}
          </div>
        )}
        <div className={level < 4 ? 'body-emphasis-02' : 'body-02'}>
          {renderNodeLabel(node.label)}
        </div>
      </div>
      {filteredChildren && expanded && (
        <CategoryHierarchyTree
          nodes={filteredChildren}
          searchTerm={searchTerm}
          nodeLevel={nodeLevel}
          parentNodes={[...parentNodes, node]}
          handleItemClick={handleItemClick}
          selectedItemPath={selectedItemPath}
        />
      )}

      {childrenFilteredOut() && (
        <CategoryHierarchyNode
          key={`error-${node.id}`}
          node={{
            id: `error-${node.id}`,
            label: 'No items match the current search',
            level: '1',
          }}
          error
          searchTerm=""
          handleItemClick={handleItemClick}
          selectedItemPath={selectedItemPath}
        />
      )}
    </>
  );
};

export default CategoryHierarchyNode;
