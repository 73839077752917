import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, ComposedModal, ModalHeader } from '@carbon/react';
import { CopyLink } from '@carbon/icons-react';
import errorIcon from '../assets/icons/errorIcon.svg';
import infoIcon from '../assets/icons/infoIcon.svg';
import successIcon from '../assets/icons/successIcon.svg';
import warningIcon from '../assets/icons/warningIcon.svg';
import { ModalContext } from '../providers/ModalProvider';
import '../styles/components/modal.scss';
import { createPortal } from 'react-dom';

export const MODAL_ID = 'oneviu-modal';

const Modal: FunctionComponent = () => {
  const { modal, component, open, containerClassName, toggleModal } =
    useContext(ModalContext);
  const [copied, setCopied] = useState(false);

  const {
    type,
    title,
    body,
    primaryCTAText,
    onPrimaryCTAClick,
    secondaryCTAText,
    copyToClipboard,
    onSecondaryCTAClick,
    className,
    isCloseIconVisible,
  } = modal;

  const renderType = (type) => {
    switch (type) {
      case 'info':
        return { icon: infoIcon, class: 'info' };
      case 'warning':
        return { icon: warningIcon, class: 'warning' };
      case 'error':
        return { icon: errorIcon, class: 'error' };
      case 'success':
        return { icon: successIcon, class: 'success' };
      default:
        return { icon: successIcon, class: 'success' };
    }
  };

  const primaryClick = onPrimaryCTAClick
    ? onPrimaryCTAClick
    : () => toggleModal(false);
  const secondaryClick = onSecondaryCTAClick
    ? onSecondaryCTAClick
    : () => toggleModal(false);

  const onCopy = () => {
    if (copyToClipboard) {
      navigator.clipboard.writeText(copyToClipboard);
      setCopied(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCopied(false);
    }, 3000);
    return () => clearInterval(interval);
  }, [copied]);

  const modalClassName = [className, component ? containerClassName : '']
    .filter(Boolean)
    .join(' ');

  return createPortal(
    <ComposedModal
      preventCloseOnClickOutside
      size={component ? 'md' : 'sm'}
      open={open}
      data-testid="modal-body"
      onClose={() => toggleModal(false)}
      id={MODAL_ID}
      className={modalClassName}
    >
      {component || (
        <div className={`modal-body ${renderType(type).class}`}>
          {isCloseIconVisible && (
            <ModalHeader buttonOnClick={() => toggleModal(false)} />
          )}
          <div data-testid="img-container" className="modal-img-container">
            <img src={renderType(type).icon} />
          </div>
          <div className="modal-body-container">
            <div className="modal-body-text">
              <div className="modal-header">{title}</div>
              <div className="modal-subheader">{body}</div>
            </div>
            {copyToClipboard && (
              <Button
                kind="ghost"
                onClick={onCopy}
                disabled={copied}
                renderIcon={copied ? undefined : CopyLink}
              >
                {copied ? 'Link copied' : 'Copy to clipboard'}
              </Button>
            )}
            <div className="modal-actions">
              {secondaryCTAText && (
                <Button kind="secondary" onClick={secondaryClick}>
                  {secondaryCTAText}
                </Button>
              )}
              <Button kind="primary" onClick={primaryClick}>
                {primaryCTAText || 'OK'}
              </Button>
            </div>
          </div>
        </div>
      )}
      <input className="cds--visually-hidden" />
    </ComposedModal>,
    document.body
  );
};

export default Modal;
