import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import hierarchyTreeReducer, {
  initialHierarchyTreeState,
} from '../reducers/HierarchyTreeReducer';

export type HierarchyTreeState = Record<string, boolean>;

export const HierarchyTreeContext = createContext<HierarchyTreeContextType>(
  {} as HierarchyTreeContextType
);
interface HierarchyTreeProviderProps {
  children?: ReactNode;
}

interface HierarchyTreeContextType {
  treeState: HierarchyTreeState;
  updateNodeState: (nodeId: string, expandState: boolean) => void;
  clearTreeState: () => void;
  getNodeState: (nodeId: string) => boolean;
}

const HierarchyTreeProvider: FunctionComponent<HierarchyTreeProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    hierarchyTreeReducer,
    initialHierarchyTreeState
  );

  const updateNodeState = (nodeId: string, expandState: boolean) => {
    dispatch({ type: 'UPDATE_NODE_STATE', nodeId, expandState });
  };

  const clearTreeState = () => {
    dispatch({ type: 'CLEAR_STATE' });
  };

  const getNodeState = (nodeId: string) => {
    return state[nodeId];
  };

  const hierarchyTreeContext: HierarchyTreeContextType = useMemo(
    () => ({
      treeState: state,
      getNodeState,
      updateNodeState,
      clearTreeState,
    }),
    [state]
  );

  return (
    <HierarchyTreeContext.Provider value={hierarchyTreeContext}>
      {children}
    </HierarchyTreeContext.Provider>
  );
};

export default HierarchyTreeProvider;
