import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { SidePanelState } from '../reducers/SidePanelReducer';
import sidePanelReducer, {
  initialSidePanelState,
} from '../reducers/SidePanelReducer';
import {
  RENDER_SIDE_PANEL_CONTENT,
  RESIZE_SIDE_PANEL,
  TOGGLE_SIDE_PANEL_EXPAND,
} from '../constants/reducers';
import '../styles/components/side-panel.scss';
import { SIDE_PANEL_WIDTH } from '../constants/localStorageKeys';
import { useWindowDimensions } from '../hooks';
import SidePanelHeader from '../components/SidePanel/SidePanelHeader';

interface SidePanelProviderProps {
  children?: ReactNode;
}

export interface SidePanelContextType extends SidePanelState {
  toggleSidePanelExpand: (expanded: boolean) => void;
  renderSidePanelContent: (
    content: ReactNode,
    header: { title?: string; subtitle?: string } | null,
    autoclose?: boolean
  ) => void;
}

export const SidePanelContext = createContext<SidePanelContextType>(
  {} as SidePanelContextType
);

const SidePanelProvider: FunctionComponent<SidePanelProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(sidePanelReducer, initialSidePanelState);
  const { documentWidth } = useWindowDimensions();

  const maxSidepanelWidth = documentWidth - 96;

  const toggleSidePanelExpand = (expanded: boolean) =>
    dispatch({ type: TOGGLE_SIDE_PANEL_EXPAND, expanded });

  const renderSidePanelContent = (
    content: ReactNode,
    header,
    autoclose = true
  ) =>
    dispatch({ type: RENDER_SIDE_PANEL_CONTENT, content, header, autoclose });

  const sidePanelRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const panelWidth = localStorage.getItem(SIDE_PANEL_WIDTH);
    if (panelWidth) {
      dispatch({
        type: RESIZE_SIDE_PANEL,
        width: Math.min(Number(panelWidth), maxSidepanelWidth),
      });
    }
  }, []);

  useEffect(() => {
    if (typeof state.width === 'number' && maxSidepanelWidth < state.width) {
      dispatch({ type: RESIZE_SIDE_PANEL, width: maxSidepanelWidth });
    }
  }, [maxSidepanelWidth]);

  useEffect(() => {
    if (state.expanded) {
      toggleSidePanelExpand(false);
    }
  }, [location]);

  const sidePanelContext: SidePanelContextType = useMemo(() => {
    return {
      ...state,
      toggleSidePanelExpand,
      renderSidePanelContent,
    };
  }, [state]);

  const shouldCloseOnOutsideClick = state.autoclose && state.expanded;

  const beginResize = () => {
    document.addEventListener('mousemove', onResize);
    document.addEventListener('mouseup', stopResize);
    const sidepanel = document.getElementById('side-panel');
    if (sidepanel) {
      sidepanel.style.userSelect = 'none';
    }
  };

  const onResize = (e) => {
    const totalWidth =
      document.getElementById('side-panel-overlay')?.clientWidth;
    const panel = document.getElementById('side-panel');

    if (!panel || typeof totalWidth !== 'number') {
      return;
    }
    const maxWidth = Math.min(totalWidth - e.x, maxSidepanelWidth);
    const minWidth = Math.max(maxWidth, 300);
    panel.style.width = `${minWidth}px`;
  };

  const stopResize = () => {
    document.removeEventListener('mousemove', onResize);
    document.removeEventListener('mouseup', stopResize);
    const sidepanel = document.getElementById('side-panel');
    if (sidepanel) {
      sidepanel.style.userSelect = 'default';
      localStorage.setItem(SIDE_PANEL_WIDTH, sidepanel.clientWidth.toString());
      dispatch({ type: RESIZE_SIDE_PANEL, width: sidepanel.clientWidth });
    }
  };
  const getSidePanelTopSpacing = () => {
    if (state.header) {
      return state.header.subtitle ? 'top-spacing' : 'top-spacing--title-only';
    } else {
      return '';
    }
  };
  return (
    <SidePanelContext.Provider value={sidePanelContext}>
      {children}
      <div
        onClick={() => toggleSidePanelExpand(false)}
        className={`SidePanel__overlay ${
          shouldCloseOnOutsideClick ? 'visible' : 'hidden'
        }`}
        id="side-panel-overlay"
        data-testid="side-panel-overlay"
      />
      <div
        ref={sidePanelRef}
        data-testid="side-panel"
        id="side-panel"
        className={`SidePanel__container ${state.expanded ? 'open' : 'closed'}`}
        style={{
          width: state.expanded ? state.width + 'px' : 0,
          transform: state.expanded ? 'unset' : 'translateX(100vw)',
        }}
      >
        <div className="SidePanel__wrapper">
          <div
            className="SidePanel__draggable-edge"
            data-testid="draggable-edge"
            onMouseDown={beginResize}
          />
          <SidePanelHeader
            title={state.header?.title}
            subtitle={state.header?.subtitle}
          />
          <div className={`SidePanel__content ${getSidePanelTopSpacing()}`}>
            {state.content}
          </div>
        </div>
      </div>
    </SidePanelContext.Provider>
  );
};

export default SidePanelProvider;
