import {
  UPDATE_ATTRIBUTE_VALUES_FILE_SPECS,
  UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA,
  UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA,
  RESET_ATTRIBUTE_VALUES_UPLOAD_DATA,
} from '../constants/reducers';
import type { AttributeValuesWebsocketStatus } from '../types/customAttribute';
import type { FileSpecs } from './CustomGroupsReducer';

export interface UploadMetadata {
  readonly type: string;
  readonly status: AttributeValuesWebsocketStatus;
  readonly process_id: string;
  readonly file_path: string;
}

export interface Product {
  readonly id: string;
  readonly label?: string;
  readonly is_exists: boolean;
}

export interface Attribute {
  readonly attribute: string;
  readonly attribute_values: Record<string, { value: string }>;
  readonly attribute_id?: string;
  readonly is_exists: boolean;
}

export interface UploadData {
  readonly products: Product[];
  readonly attributes: Attribute[];
}

export interface AttributeValuesState {
  uploadData: UploadData | null;
  fileSpecs: FileSpecs | null;
  uploadMetadata: UploadMetadata | null;
}

interface UpdateAttributeValuesFileSpecs {
  type: typeof UPDATE_ATTRIBUTE_VALUES_FILE_SPECS;
  fileSpecs: Partial<FileSpecs>;
}

interface UpdateAttributeValuesUploadData {
  type: typeof UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA;
  uploadData: UploadData;
}

interface UpdateAttributeValuesUploadMetadata {
  type: typeof UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA;
  uploadMetadata: UploadMetadata | null;
}

interface ResetAttributeValuesUploadData {
  type: typeof RESET_ATTRIBUTE_VALUES_UPLOAD_DATA;
}

export type AttributeValuesAction =
  | UpdateAttributeValuesFileSpecs
  | UpdateAttributeValuesUploadData
  | UpdateAttributeValuesUploadMetadata
  | ResetAttributeValuesUploadData;

export const initialAttributeValuesState: AttributeValuesState = {
  uploadData: null,
  fileSpecs: null,
  uploadMetadata: null,
};

export const attributeValuesReducer = (
  state: AttributeValuesState,
  action: AttributeValuesAction
): AttributeValuesState => {
  switch (action.type) {
    case UPDATE_ATTRIBUTE_VALUES_FILE_SPECS: {
      return {
        ...state,
        fileSpecs: {
          ...state.fileSpecs,
          ...action.fileSpecs,
        } as FileSpecs,
      };
    }
    case UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.uploadData,
      };
    case UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA:
      return {
        ...state,
        uploadMetadata: action.uploadMetadata,
      };

    case RESET_ATTRIBUTE_VALUES_UPLOAD_DATA:
      return initialAttributeValuesState;
  }
};
