export const countIsValid = (
  count: number,
  ruleCount: number | ((n: number) => boolean),
  operator: Operator
): boolean => {
  if (operator === 'function') {
    const ruleFunc = new Function('n', `return ${ruleCount}`);
    return ruleFunc(count);
  }

  if (operator === '>') {
    return count > (ruleCount as number);
  } else if (operator === '<') {
    return count < (ruleCount as number);
  } else {
    return count === ruleCount;
  }
};

export const isRuleSetValid = (
  ruleSet: RuleSet | null | undefined,
  parameters: ReportParameters,
  isValid?: boolean
): boolean => {
  if (isValid === false || !ruleSet) {
    return false;
  }

  const validRules = ruleSet?.rules.filter((rule: Rule) => {
    const ruleKey = rule.dimension + '_' + rule.parameterType;

    return (
      parameters[ruleKey] &&
      countIsValid(
        (parameters[ruleKey] as ReportParameter[]).length,
        rule.count as number,
        rule.operator
      )
    );
  });

  isValid = validRules.length > 0;

  if (ruleSet.extra) {
    isValid = isRuleSetValid(ruleSet.extra, parameters, isValid);
  }

  if (ruleSet.minDimensions) {
    const populatedDimensions = Object.keys(parameters).filter((key) => {
      const ruleKeys = ruleSet.rules.map(
        (r) => `${r.dimension}_${r.parameterType}`
      );

      return (
        (parameters[key] as ReportParameter[]).length > 0 &&
        ruleKeys.includes(key)
      );
    });

    isValid = isValid && populatedDimensions.length >= ruleSet.minDimensions;
  }

  return !!isValid;
};

export const isAdvancedSettingValid = (
  advancedSettings: AdvancedSettings[] | Setting[],
  analyticEngineParams: AnalyticEngineParams
): boolean => {
  const foundSetting = advancedSettings.find(
    (setting) => setting.restriction && setting.name
  );
  return (
    !foundSetting ||
    (!!foundSetting.restriction &&
      (analyticEngineParams[foundSetting.name] as number) <=
        foundSetting.restriction.max)
  );
};
