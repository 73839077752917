import { useEffect, useContext, useRef } from 'react';
import { WebsocketContext } from '../providers/WebsocketProvider';
import type { GenericWebsocketMessage } from '../types/websocket';

const useWebsocketListener = (
  onMessage: (data: GenericWebsocketMessage) => void
): void => {
  const onMessageRef = useRef(onMessage);
  const { broadcastChannel } = useContext(WebsocketContext);

  useEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    const controller = new AbortController();

    const messageListener = (event: MessageEvent): void => {
      try {
        const parsedData: GenericWebsocketMessage = JSON.parse(event.data);
        onMessageRef.current(parsedData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to parse websocket message', error);
      }
    };

    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', messageListener, {
        signal: controller.signal,
      });
    }

    return () => {
      controller.abort();
    };
  }, [broadcastChannel]);
};

export default useWebsocketListener;
