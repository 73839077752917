/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import {
  DatePicker,
  DatePickerInput,
  Dropdown,
  RadioButtonGroup,
  RadioButton,
  FormLabel,
  NumberInput,
} from '@carbon/react';
import { RecentlyViewed } from '@carbon/icons-react';
import {
  SlidingRecurrance,
  ExtendRecurrance,
} from '../../../assets/schedule-report';
import {
  EndsRecurrenceType,
  RepeatPeriod,
  repeatOptions,
} from '../../../constants/metadata';
import { DateTime } from 'luxon';
import { hasDecimals } from '../utils/reportBuilderUtils';

interface RecurrenceOptionsProps {
  state: ScheduleReportOptions;
  handleChangeOption: <T extends keyof ScheduleReportOptions>(
    option: T,
    value: ScheduleReportOptions[T]
  ) => void;
}

const RecurrenceOptions: FunctionComponent<RecurrenceOptionsProps> = ({
  state,
  handleChangeOption,
}) => {
  const {
    startDate,
    recurrenceType,
    recurrencePeriod,
    repeatEvery,
    recurrenceEnds,
    endDate,
    endsAfterOccurrences,
  } = state;
  const endDateRef = useRef<HTMLDivElement | null>(null);

  const [isRefReady, setIsRefReady] = useState(false);
  useLayoutEffect(() => {
    setIsRefReady(true);
  }, []);

  const minDate = startDate
    ? startDate.toISOString()
    : new Date(
        DateTime.now().plus({ days: 1 }).startOf('day').toJSDate()
      ).toISOString();

  const getInvalidText = (value: number): string => {
    switch (true) {
      case value < 0:
        return 'Please input a positive value';
      case hasDecimals(value):
        return 'Please input a non-decimal value';
      default:
        return 'Invalid input';
    }
  };

  return (
    <div className="RecurrenceOptions">
      <div className="option-row">
        <RadioButtonGroup
          legendText=""
          hideLabel
          name="recurrence-type"
          valueSelected={recurrenceType}
          onChange={(value) => handleChangeOption('recurrenceType', value)}
        >
          <RadioButton labelText="Slide" value="Slide" id="slide-type" />
          <RadioButton labelText="Extend" value="Extend" id="extend-type" />
        </RadioButtonGroup>
      </div>
      <div className="option-row recurrence-type">
        <div className="RecurrenceOptions__img">
          <FormLabel>Both Start and End dates advance.</FormLabel>
          <img src={SlidingRecurrance} alt="slide-image" />
        </div>
        <div className="RecurrenceOptions__img">
          <FormLabel>Only the End date advances.</FormLabel>
          <img src={ExtendRecurrance} alt="extend-image" />
        </div>
      </div>
      <div className="ScheduleReport__section-title">
        <RecentlyViewed />
        <span>Recurrence Time</span>
      </div>
      <div className="option-row">
        <div className="option-label">Repeats</div>
        <Dropdown
          id="repeats-pattern"
          data-testid="repeats-pattern-dropdown"
          label="Choose a period"
          items={repeatOptions}
          selectedItem={recurrencePeriod}
          size="sm"
          onChange={(e) =>
            handleChangeOption('recurrencePeriod', e.selectedItem)
          }
        />
      </div>
      <div className="option-row">
        {recurrencePeriod === RepeatPeriod.DAILY ? (
          <>
            <div className="option-label">Repeat Every</div>
            <NumberInput
              id="repeat-number"
              data-testid="repeat-number-input"
              className="repeats-number-input"
              size="sm"
              min={1}
              max={1000000}
              invalidText={getInvalidText(Number(repeatEvery))}
              invalid={
                typeof repeatEvery !== 'number' ||
                repeatEvery <= 0 ||
                hasDecimals(repeatEvery)
              }
              disableWheel
              value={repeatEvery}
              disabled={!recurrencePeriod}
              onChange={(e, { value }: { value: number | string }) =>
                handleChangeOption('repeatEvery', Number(value) || 0)
              }
            />
            <span>Days</span>
          </>
        ) : null}
      </div>
      <div className="option-row column">
        <div className="option-label">Ends</div>
        <RadioButtonGroup
          legendText=""
          hideLabel
          name="recurrence-ends-radio-group"
          orientation="vertical"
          valueSelected={recurrenceEnds}
          onChange={(value) => handleChangeOption('recurrenceEnds', value)}
        >
          <RadioButton
            labelText="Never"
            value={EndsRecurrenceType.NEVER}
            id="ends-never"
            data-testid="ends-never-radio-button"
          />
          <RadioButton
            value={EndsRecurrenceType.OCCURRENCES}
            id="ends-occurrences"
            data-testid="ends-occurrences-radio-button"
            labelText={
              <div className="radio-button-label">
                <span>After</span>
                <NumberInput
                  id="occurrences-number"
                  className="repeats-number-input"
                  data-testid="occurrences-number-input"
                  min={1}
                  max={1000000}
                  invalidText={getInvalidText(Number(endsAfterOccurrences))}
                  invalid={
                    typeof endsAfterOccurrences !== 'number' ||
                    endsAfterOccurrences <= 0 ||
                    hasDecimals(endsAfterOccurrences)
                  }
                  size="sm"
                  disableWheel
                  value={endsAfterOccurrences}
                  disabled={recurrenceEnds !== EndsRecurrenceType.OCCURRENCES}
                  onChange={(e, { value }: { value: number | string }) =>
                    value &&
                    handleChangeOption(
                      'endsAfterOccurrences',
                      Number(value) || 0
                    )
                  }
                />
                <span>Occurrences</span>
              </div>
            }
          />
          <RadioButton
            labelText="Custom Date"
            value={EndsRecurrenceType.CUSTOM_DATE}
            id="ends-custom"
            data-testid="ends-custom-radio-button"
          />
        </RadioButtonGroup>
      </div>

      <div className="ScheduleReport__date-picker-container" ref={endDateRef}>
        {isRefReady && recurrenceEnds === 'custom-date' && (
          <div className="ScheduleReport__date-picker">
            <DatePicker
              value={endDate}
              datePickerType="single"
              onChange={(date) => handleChangeOption('endDate', date[0])}
              appendTo={endDateRef.current}
              minDate={minDate}
              dateFormat="d/m/Y"
            >
              <DatePickerInput
                placeholder="dd/mm/yyyy"
                id="custom-end-date"
                data-testid="custom-end-datepicker"
                size="md"
                disabled={recurrenceEnds !== EndsRecurrenceType.CUSTOM_DATE}
                autoComplete="off"
              />
            </DatePicker>
          </div>
        )}
      </div>
      <div className="sidebar-divider" />
    </div>
  );
};

export default RecurrenceOptions;
