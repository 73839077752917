export enum AiQuestionStatus {
  Published = 'published',
  Draft = 'draft',
  Testing = 'testing',
}

export const aiAdminTabsLabels = {
  published: 'Published',
  unpublished: 'Unpublished',
};

export const aiQuestionStatusNames: Record<AiQuestionStatus, string> = {
  [AiQuestionStatus.Published]: 'Published',
  [AiQuestionStatus.Draft]: 'Draft',
  [AiQuestionStatus.Testing]: 'Testing',
};

export const aiAdminTableHeaders = [
  { header: 'Question', key: 'question' },
  { header: 'Created By', key: 'modified_by' },
  { header: 'Status', key: 'question_status' },
  { header: 'Date Modified', key: 'last_updated_timestamp' },
  { header: 'Actions', key: 'actions' },
];

export enum AiQuestionLinkedFiles {
  Product = 'kpi-tree-grid - Product',
  Location = 'kpi-tree-grid - Location',
  Sales = 'ckpi-chart - Sales Change',
  Units = 'ckpi-chart - Units Change',
}
