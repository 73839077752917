/* eslint-disable indent */
import Fetch from './Fetch';
import { InlineLoading, Tag, ToastNotification, Toggle } from '@carbon/react';
import { Notification } from '@carbon/icons-react';
import type { FunctionComponent } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import '../styles/components/notifications.scss';
import Gravatar from './Gravatar';
import { timeSince } from '../utils';
import { useIntersectionObserver } from '../hooks';
import { AppContext } from '../providers/AppProvider';
import { CACHE_KEY } from '../constants/api';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../api';
import { useNavigate } from 'react-router-dom';
import { WebsocketAction } from '../types/websocket';
import type { GenericWebsocketMessage } from '../types/websocket';
import { formatUTCDate } from '../utils/DateUtils';
import Confetti from '../assets/icons/confetti.svg';
import { ModalContext } from '../providers/ModalProvider';
import usePosthog from '../utils/posthog';
import {
  notificationClick,
  notificationIconClick,
  notificationMarkAllAsReadFailure,
  notificationMarkAllAsReadSuccess,
  notificationMarkAsReadFailure,
  notificationMarkAsReadSuccess,
  notificationMarkAsSeenFailure,
  notificationMarkAsSeenSuccess,
  notificationShowUnreadToggle,
  notificationToastCloseClick,
} from '../constants/posthog';
import Popover from './Popover';
import useWebsocketListener from '../hooks/useWebsocketListener';

interface NotifyMessage {
  readonly action: WebsocketAction.Notify;
  readonly data: {
    readonly message: string;
    readonly created_timestamp: string;
  };
}

interface Toast {
  message: string;
  time: string;
  kind: 'info' | 'error';
}

const getUpdatedNotifications = (
  current: NotificationType[],
  ids: string[],
  updatedField: { [key: string]: boolean }
): NotificationType[] => {
  return current.map((n: NotificationType) => {
    return ids.includes(n.notification_id)
      ? {
          ...n,
          ...updatedField,
        }
      : n;
  });
};

const isNotifyMessage = (
  message: GenericWebsocketMessage | NotifyMessage
): message is NotifyMessage => message.action === WebsocketAction.Notify;

const Notifications: FunctionComponent = () => {
  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(endOfListRef, {});
  const isVisible = !!entry?.isIntersecting;

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const {
    clearCacheForKey,
    initialiseAppData,
    bannerId,
    groupId,
    bannersAndUserGroups,
    user,
  } = useContext(AppContext);

  const { updateModal, toggleModal } = useContext(ModalContext);
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [limit, setLimit] = useState(10);
  const [endOfList, setEndOfList] = useState(false);
  const [toast, setToast] = useState<Toast | null>(null);
  const posthogEvent = usePosthog();

  useEffect(() => {
    if (isVisible && !endOfList && notifications.length === limit) {
      setLimit(limit + 10);
    }
  }, [isVisible, notifications]);

  useEffect(() => {
    if (!endOfList) {
      clearCacheForKey(CACHE_KEY.NOTIFICATIONS);
    }
  }, [limit]);

  useEffect(() => {
    setEndOfList(notifications.length < limit);
  }, [notifications]);

  const toggleNotifications = (show: boolean) => {
    posthogEvent(notificationIconClick, {
      show,
    });
    if (show) {
      markAsSeen(
        notifications.filter((n) => !n.is_seen).map((n) => n.notification_id)
      );
    }
  };

  const setErrorToast = () => {
    setToast({
      message: 'There was an issue updating your notifications.',
      kind: 'error',
      time: formatUTCDate(new Date().toString()),
    });
  };

  const markAllAsRead = async () => {
    const token = await getAccessTokenSilently();
    const notification_ids = notifications.map((n) => n.notification_id);
    try {
      apiRequest('/notifications/read', 'POST', token, {
        notification_ids,
      });

      const updatedNotifications: NotificationType[] = getUpdatedNotifications(
        notifications,
        notification_ids,
        { is_read: true }
      );
      posthogEvent(notificationMarkAllAsReadSuccess, {
        notificationIds: updatedNotifications.map((n) => n.notification_id),
      });
      setNotifications(updatedNotifications);
    } catch {
      posthogEvent(notificationMarkAllAsReadFailure);
      setErrorToast();
    }
  };

  const markAsSeen = async (notification_ids: string[]) => {
    if (notification_ids.length > 0) {
      const token = await getAccessTokenSilently();
      try {
        apiRequest('/notifications/mark-seen', 'POST', token, {
          notification_ids,
        });

        const updatedNotifications: NotificationType[] =
          getUpdatedNotifications(notifications, notification_ids, {
            is_seen: true,
          });
        posthogEvent(notificationMarkAsSeenSuccess, {
          notificationIds: updatedNotifications.map((n) => n.notification_id),
        });
        setNotifications(updatedNotifications);
      } catch {
        posthogEvent(notificationMarkAsSeenFailure);
        setErrorToast();
      }
    }
  };

  const markAsRead = async (notification_id: string, is_read: boolean) => {
    const token = await getAccessTokenSilently();
    try {
      apiRequest(`/notifications/${notification_id}/read`, 'POST', token);

      const updatedNotifications: NotificationType[] = getUpdatedNotifications(
        notifications,
        [notification_id],
        { is_read }
      );
      posthogEvent(notificationMarkAsReadSuccess, {
        notificationIds: updatedNotifications.map((n) => n.notification_id),
      });
      setNotifications(updatedNotifications);
    } catch {
      posthogEvent(notificationMarkAsReadFailure);
      setErrorToast();
    }
  };

  const handleNotificationClick = (
    notification_id: string,
    banner_id: number,
    user_group_id: number,
    run_id: string
  ) => {
    posthogEvent(notificationClick, {
      notification_id,
    });
    if (bannerId !== banner_id || user_group_id !== groupId) {
      updateModal({
        type: 'info',
        title: 'Your selected banner is about to be switched',
        body: 'By opening this notification, you will be redirected to a different banner selection.',
        onPrimaryCTAClick: () => {
          markAsRead(notification_id, true);
          if (user) {
            initialiseAppData(
              user,
              bannersAndUserGroups,
              banner_id,
              user_group_id
            );
            navigate(
              `/workspace/view-report/${run_id}?b=${banner_id}&ug=${user_group_id}`
            );
            toggleModal(false);
          }
        },
        secondaryCTAText: 'Cancel',
      });
    } else {
      markAsRead(notification_id, true);
      navigate(
        `/workspace/view-report/${run_id}?b=${banner_id}&ug=${user_group_id}`
      );
    }
  };

  const onMessage = useCallback(
    (message: GenericWebsocketMessage | NotifyMessage) => {
      if (!isNotifyMessage(message)) {
        return;
      }

      const { message: notificationText, created_timestamp: createdTimestamp } =
        message.data;

      setToast({
        message: notificationText.replaceAll(/<.[u|b]?>/g, ''),
        time: formatUTCDate(createdTimestamp),
        kind: 'info',
      });

      clearCacheForKey(CACHE_KEY.NOTIFICATIONS);
    },
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setToast(null);
    }, 6000);

    return () => clearInterval(interval);
  }, [toast]);

  useWebsocketListener(onMessage);

  const filteredNotifications = notifications.filter(
    (notification: NotificationType) => {
      return !unreadOnly || !notification.is_read;
    }
  );

  return (
    <>
      <Popover
        className="notifications-popover"
        align="bottom-right"
        caret={false}
        data-testid="notifications-popover"
        target={
          <div
            className={`notifications-icon hoverable ${
              notifications.some((n) => !n.is_seen) ? 'has-unseen' : 'all-seen'
            }`}
            data-testid="notifications-icon"
          >
            <Notification />
          </div>
        }
        onOpenChange={toggleNotifications}
      >
        <div
          className="notifications-content"
          data-testid="notifications-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="notifications-header">
            <div className="flex icon-title">
              <Notification size="14pt" />
              <div className="notifications-title">Notifications</div>
            </div>
            <div>
              <Toggle
                id="unread-toggle"
                data-testid="unread-toggle"
                size="sm"
                labelText="Only show unread"
                hideLabel
                onToggle={(toggled) => {
                  posthogEvent(notificationShowUnreadToggle, {
                    toggled,
                  });
                  setUnreadOnly(toggled);
                }}
              />
              {filteredNotifications.length > 0 && (
                <div className="mark-as-read" onClick={markAllAsRead}>
                  Mark all as read
                </div>
              )}
            </div>
          </div>

          <div className="notifications-list">
            {filteredNotifications.map((notification: NotificationType) => {
              const {
                notification_id,
                message,
                is_read,
                created_timestamp,
                notification_params,
              } = notification;
              const {
                origin,
                banner_id,
                user_group_id,
                run_id,
                redirect,
                user_group,
              } = notification_params || {};
              return (
                <div
                  key={notification_id}
                  className={`notifications-item ${
                    redirect ? 'with-redirect' : ''
                  }`.trim()}
                  data-testid="notification-item"
                  onClick={() =>
                    !!redirect &&
                    run_id &&
                    handleNotificationClick(
                      notification_id,
                      Number(banner_id),
                      Number(user_group_id),
                      run_id
                    )
                  }
                >
                  <div className="flex align-start">
                    {origin && <Gravatar name={origin} showName={false} />}
                  </div>

                  <div data-testid={`notification-item-${notification_id}`}>
                    <span
                      className="notification-message"
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                    <div className="flex align-center notification-description">
                      <div className="description">
                        {timeSince(new Date(Date.parse(created_timestamp)))}
                      </div>
                      {!!banner_id && bannersAndUserGroups.length > 1 && (
                        <Tag
                          className="notification-info-tag"
                          size="sm"
                          id={`tag-${notification_id}`}
                        >
                          {
                            bannersAndUserGroups.find(
                              (b) => b.id === Number(banner_id)
                            )?.name
                          }
                        </Tag>
                      )}
                      {!!banner_id && user_group && (
                        <Tag
                          className="notification-info-tag notification-info-tag__alt"
                          size="sm"
                          data-testid={`tag-group-${notification_id}`}
                        >
                          {user_group}
                        </Tag>
                      )}
                    </div>
                  </div>

                  <div
                    className="notifications-read-status"
                    data-testid={`is-read-${is_read}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      markAsRead(notification_id, !is_read);
                    }}
                  >
                    {!is_read && <div className="notifications-unread-blip" />}
                    <div className="notifications-tooltip">
                      Mark as {is_read ? 'unread' : 'read'}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="notifications-fetch">
              <Fetch
                apiUrl={`/notifications?limit=${limit}`}
                initialData={null}
                onReceiveData={(data) => {
                  if (data) {
                    setNotifications(data);
                  }
                }}
                cacheKey={CACHE_KEY.NOTIFICATIONS}
                loadingComponent={
                  <InlineLoading
                    className="flex justify-center align-center"
                    description="Loading..."
                  />
                }
              >
                <div ref={endOfListRef}>
                  {endOfList && (
                    <div className="flex direction-row justify-center align-center notifications-list-end">
                      <img src={Confetti} />
                      <div>
                        You&apos;ve seen all notifications from the past 30
                        days!
                      </div>
                    </div>
                  )}
                </div>
              </Fetch>
            </div>
          </div>
        </div>
      </Popover>
      {toast && (
        <ToastNotification
          className="notifications-toast"
          data-testid="notifications-toast"
          title={toast.message}
          caption={toast.time}
          size="sm"
          lowContrast
          kind={toast.kind}
          onClose={() => posthogEvent(notificationToastCloseClick)}
        />
      )}
    </>
  );
};

export default Notifications;
