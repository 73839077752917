import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import appReducer, { initialAppState } from '../reducers/AppReducer';
import {
  UPDATE_CALENDAR_DATA,
  UPDATE_DIMENSIONS_DATA,
  UPDATE_HIERARCHY_DATA,
  UPDATE_QUERY_CACHE,
  UPDATE_REPORT_MODULES,
  UPDATE_USER,
  EXPAND_SIDEBAR,
  UPDATE_REPORT_PARAMETERS,
  UPDATE_USER_GROUP_ID,
  INITIALISE_APP_DATA,
  UPDATE_FAVOURITE_GROUPS,
} from '../constants/reducers';
import { USER_ROLE } from '../constants/metadata';
import type { DynamicCacheKey } from '../constants/api';

export const AppContext = createContext<GlobalContext>({} as GlobalContext);

interface AppProviderProps {
  children?: ReactNode;
}

export interface GlobalContext extends GlobalState {
  showAdminControls: boolean;
  initialiseAppData: (
    user: User,
    bannersAndUserGroups: Banner[],
    bannerId: number,
    groupId: number
  ) => void;
  updateUser: (user: User) => void;
  updateUserGroupId: (groupId: number) => void;
  updateDimensionsData: (dimensions: Dimension[]) => void;
  updateReportParameters: (reportParameters: ReportParameters) => void;
  updateHierarchyData: (
    hiearchyId: string,
    hierarchy: HierarchyResponse
  ) => void;
  updateCalendarData: (calendarId: string, dates: FormattedDate[]) => void;
  updateQueryCache: (cacheKey: DynamicCacheKey, bannerId: number) => void;
  updateReportModules: (reportModules: ReportSection[]) => void;
  expandSidebar: (expandSidebar: boolean) => void;
  clearCacheForKey: (cacheKey: DynamicCacheKey) => void;
  updateFavouriteGroups: (favouriteGroups: FavouriteGroup[]) => void;
}

const AppProvider: FunctionComponent<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  const showAdminControls = useMemo(
    () => state.user?.user_role === USER_ROLE.SUPER_ADMIN,
    [state.user?.user_role]
  );

  const initialiseAppData = (
    user: User,
    bannersAndUserGroups: Banner[],
    bannerId: number,
    groupId: number
  ) =>
    dispatch({
      type: INITIALISE_APP_DATA,
      user,
      bannersAndUserGroups,
      bannerId,
      groupId,
    });

  const updateDimensionsData = (dimensions: Dimension[]) =>
    dispatch({ type: UPDATE_DIMENSIONS_DATA, dimensions });

  const updateReportParameters = (reportParameters: ReportParameters) =>
    dispatch({ type: UPDATE_REPORT_PARAMETERS, reportParameters });

  const updateHierarchyData = (
    hierarchyId: string,
    hierarchy: HierarchyResponse
  ) => dispatch({ type: UPDATE_HIERARCHY_DATA, hierarchyId, hierarchy });

  const updateFavouriteGroups = (favouriteGroups: FavouriteGroup[]) =>
    dispatch({ type: UPDATE_FAVOURITE_GROUPS, favouriteGroups });

  const updateCalendarData = (calendarId: string, dates: FormattedDate[]) => {
    dispatch({ type: UPDATE_CALENDAR_DATA, calendarId, dates });
  };

  const updateReportModules = (reportModules: ReportSection[]) =>
    dispatch({ type: UPDATE_REPORT_MODULES, reportModules });

  const updateUser = (user: User) => dispatch({ type: UPDATE_USER, user });

  const updateUserGroupId = (groupId: number) =>
    dispatch({ type: UPDATE_USER_GROUP_ID, groupId });

  const updateQueryCache = (cacheKey: DynamicCacheKey, bannerId: number) => {
    dispatch({ type: UPDATE_QUERY_CACHE, cacheKey, bannerId });
  };

  const clearCacheForKey = (cacheKey: DynamicCacheKey) => {
    dispatch({ type: UPDATE_QUERY_CACHE, cacheKey, bannerId: undefined });
  };

  const expandSidebar = (expandSidebar: boolean) =>
    dispatch({ type: EXPAND_SIDEBAR, expandSidebar });

  const context: GlobalContext = useMemo(
    () => ({
      dimensions: state.dimensions,
      hierarchies: state.hierarchies,
      reportParameters: state.reportParameters,
      calendars: state.calendars,
      reportModules: state.reportModules,
      user: state.user,
      bannersAndUserGroups: state.bannersAndUserGroups,
      bannerId: state.bannerId,
      groupId: state.groupId,
      queryCache: state.queryCache,
      expandedSidebar: state.expandedSidebar,
      favouriteGroups: state.favouriteGroups,
      showAdminControls,
      initialiseAppData,
      updateDimensionsData,
      updateReportParameters,
      updateHierarchyData,
      updateCalendarData,
      updateReportModules,
      updateUser,
      updateUserGroupId,
      updateQueryCache,
      expandSidebar,
      clearCacheForKey,
      updateFavouriteGroups,
    }),
    [state]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppProvider;
