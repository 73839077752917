import { FormLabel } from '@carbon/react';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { getSettingsType } from './getSettingsType';

export interface ReportSettingsProps {
  advancedSettings: AdvancedSettings[] | Setting[];
  analyticEngineParams: AnalyticEngineParams | null;
  setAnalyticEngineParams: (
    updateSelections:
      | AnalyticEngineParams
      | ((prevSelections?: AnalyticEngineParams | null) => AnalyticEngineParams)
  ) => void;
}
interface CheckboxSelection {
  selection: string;
  checked: boolean;
  defaultValue: string | undefined;
}
export type SelectionValue = string | number | CheckboxSelection;

const ReportSettings: FunctionComponent<ReportSettingsProps> = ({
  advancedSettings,
  analyticEngineParams,
  setAnalyticEngineParams,
}) => {
  if (!analyticEngineParams) {
    return null;
  }

  const handleOnChange = (
    selectionValue: SelectionValue,
    name: string,
    type?: string
  ) => {
    if (type === 'checkbox') {
      const { selection, checked, defaultValue } =
        selectionValue as CheckboxSelection;
      const currentItems = (analyticEngineParams[name] as string[]) || [];
      const updatedItems = checked
        ? [...currentItems, selection]
        : currentItems.filter((item) => item !== selection);

      const defaultItems = defaultValue ? [defaultValue] : [];
      setAnalyticEngineParams({
        ...analyticEngineParams,
        [name]: updatedItems.length > 0 ? updatedItems : defaultItems,
      });
    } else {
      setAnalyticEngineParams({
        ...analyticEngineParams,
        [name]: selectionValue as string | number,
      });
    }
  };

  return (
    <div className="ReportSettings">
      <FormLabel className="body-emphasis-01">Advanced Settings</FormLabel>
      {advancedSettings.map((setting) => (
        <Fragment key={setting.name}>
          {getSettingsType(setting, analyticEngineParams, handleOnChange)}
        </Fragment>
      ))}
    </div>
  );
};

export default ReportSettings;
