import { useContext } from 'react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import { RadioButton, RadioButtonGroup } from '@carbon/react';
import { OptimiserStrategy } from '../../reducers/OptimiserFormReducer';

const RangeStrategy = () => {
  const { formData, updateStrategy } = useContext(OptimiserFormContext);
  const { strategy } = formData;

  return (
    <RadioButtonGroup
      className="optimiser-radios"
      legendText="Select strategy"
      name="optimiser-strategy"
      onChange={updateStrategy}
    >
      <RadioButton
        labelText="Breadth"
        checked={strategy === OptimiserStrategy.Breadth}
        value={OptimiserStrategy.Breadth}
      />
      <RadioButton
        labelText="Depth"
        checked={strategy === OptimiserStrategy.Depth}
        value={OptimiserStrategy.Depth}
      />
    </RadioButtonGroup>
  );
};

export default RangeStrategy;
