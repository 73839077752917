import type { FunctionComponent } from 'react';
import { Button, InlineNotification } from '@carbon/react';
import Fetch from '../Fetch';
import { CACHE_KEY, type ReportConfigCacheKey } from '../../constants/api';
import Gravatar from '../Gravatar';
import type { SharedUser } from './ShareReport';
interface SharedUserProps {
  bannerId: number;
  groupId: number;
  run_id: string;
  sharedWith: SharedUser[];
  removedUsers: SharedUser[];
  selectionButtons?: { primary: string; secondary: string };
  crossOrCheck?: 'cross' | 'check';
  handleSharedUserClick: (user: SharedUser) => void;
  updateSharedWithData: (data: { [key: string]: string }) => void;
}

const SharedUsers: FunctionComponent<SharedUserProps> = ({
  bannerId,
  groupId,
  run_id,
  sharedWith,
  removedUsers,
  selectionButtons = { primary: 'Add', secondary: 'Remove' },
  crossOrCheck = 'cross',
  handleSharedUserClick,
  updateSharedWithData,
}) => {
  const SHARED_WITH_CACHE_KEY: ReportConfigCacheKey = `${CACHE_KEY.REPORTS_SHARED}-${run_id}`;

  return (
    <Fetch
      apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?run_id=${run_id}`}
      initialData={null}
      loadingMessage="Loading report..."
      cacheKey={SHARED_WITH_CACHE_KEY}
      alwaysFetchOnMount
      onReceiveData={(data) => {
        if (data?.[0]?.shared_with_users) {
          updateSharedWithData(data[0].shared_with_users);
        }
      }}
    >
      {sharedWith.length === 0 ? (
        <InlineNotification
          kind="info"
          lowContrast
          title="This report is not currently shared with anyone."
          hideCloseButton
        />
      ) : (
        <>
          {sharedWith.map((user) => (
            <div className="flex justify-space-between" key={user.user_id}>
              <Gravatar
                name={user.name}
                crossed={
                  crossOrCheck === 'cross' && removedUsers.includes(user)
                }
                checked={
                  crossOrCheck === 'check' && removedUsers.includes(user)
                }
              />
              <Button
                kind="ghost"
                size="sm"
                onClick={() => handleSharedUserClick(user)}
              >
                {removedUsers.includes(user)
                  ? selectionButtons.primary
                  : selectionButtons.secondary}
              </Button>
            </div>
          ))}
        </>
      )}
    </Fetch>
  );
};
export default SharedUsers;
