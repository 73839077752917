/* eslint-disable indent */
import type {
  Chart,
  CSSObject,
  PointLabelObject,
  SeriesColumnOptions,
  SeriesScatterOptions,
  SeriesXrangeOptions,
  YAxisOptions,
} from 'highcharts';
import { formatNumber } from './reportUtils';
import {
  FULLSCREEN_CHART_HEIGHT_BUFFER,
  FULLSCREEN_CHART_WIDTH_BUFFER,
} from '../constants/values';
import type {
  ScatterData,
  ScatterInfo,
} from '../components/Charts/ScatterWithSummary';
import { renderToStaticMarkup } from 'react-dom/server';
import TreeNode from '../components/Charts/TreeNode';
import { getCSSColor, getYAxes } from '.';
import type { Visual, VisualData } from '../reducers/ReportReducer';
import * as d3 from 'd3';
import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { DateTime } from 'luxon';

export const customLabel = (
  chart: Chart,
  label: string,
  x: number,
  y: number,
  customStyles?: CSSObject
) => {
  return chart.renderer
    .text(label, x, y)
    .css({
      ...customStyles,
      fontSize: '11px',
      color: '#666666',
    })
    .add();
};

export const getMinMaxFromArray = (array: number[]): [number, number] => {
  return array.reduce(
    (prev, curr: number) => {
      return [Math.min(prev[0], curr), Math.max(prev[1], curr)];
    },
    [Infinity, -Infinity]
  );
};

export const getMergedOptions = (
  responseOptions: Highcharts.Options,
  customOptions: Highcharts.Options,
  fullscreenVisual: boolean,
  height: number,
  isSecondaryAxisActive: boolean
): Highcharts.Options => {
  const axisLabelStyles = {
    labels: {
      style: { fontSize: '9px' },
    },
  };

  const series =
    customOptions.series && customOptions.series.length > 0
      ? customOptions.series
      : responseOptions.series;

  const currentYAxisRange = (
    (responseOptions.series as SeriesColumnOptions[]) || []
  ).reduce((prev, curr) => {
    return getMinMaxFromArray([...(curr.data as number[]), ...prev]);
  }, [] as number[]);
  const secondarySeriesStartIndex =
    customOptions.series && customOptions.series?.length / 2;
  const secondaryYAxisRange = (
    (customOptions.series as SeriesColumnOptions[])?.slice(
      secondarySeriesStartIndex
    ) || []
  ).reduce((prev, curr) => {
    return getMinMaxFromArray([...(curr.data as number[]), ...prev]);
  }, [] as number[]);

  const currentAxisTickAmount = Math.min(
    currentYAxisRange[1] - currentYAxisRange[0],
    5
  );
  const secondaryAxisTickAmount = Math.min(
    secondaryYAxisRange[1] - secondaryYAxisRange[0],
    5
  );

  const yAxis =
    (customOptions.yAxis as YAxisOptions[])?.length > 0
      ? customOptions.yAxis
      : responseOptions.yAxis;

  const xAxis = customOptions.xAxis || responseOptions?.xAxis;

  return {
    series,
    chart: {
      ...responseOptions.chart,
      ...customOptions.chart,
      height: fullscreenVisual
        ? screen.height - FULLSCREEN_CHART_HEIGHT_BUFFER
        : responseOptions.chart?.height || height,
      width: fullscreenVisual
        ? screen.width - FULLSCREEN_CHART_WIDTH_BUFFER
        : null,
    },
    xAxis: Array.isArray(xAxis)
      ? xAxis.map((item) => ({
          ...item,
          ...axisLabelStyles,
        }))
      : {
          ...xAxis,
          labels: {
            ...xAxis?.labels,
            ...axisLabelStyles.labels,
          },
        },
    yAxis: Array.isArray(yAxis)
      ? yAxis.map((y, index) => {
          const tickAmount =
            isSecondaryAxisActive && index === 1
              ? secondaryAxisTickAmount
              : currentAxisTickAmount;
          return {
            ...y,
            minRange: tickAmount,
            tickInterval: tickAmount < 5 && tickAmount > 1 ? 1 : undefined,
          };
        })
      : {
          ...responseOptions.yAxis,
          ...customOptions.yAxis,
          minRange: currentAxisTickAmount,
          tickInterval:
            currentAxisTickAmount < 5 && currentAxisTickAmount > 1
              ? 1
              : undefined,
        },
    plotOptions: {
      ...responseOptions.plotOptions,
      ...customOptions.plotOptions,
    },
    tooltip: {
      ...responseOptions.tooltip,
      ...customOptions.tooltip,
    },
    legend: responseOptions.legend || {},
  };
};

export const getFormat = ({
  point,
  measure,
  secondaryMeasure,
  measureFormats,
}: {
  point: PointLabelObject;
  measure: Measure | null | undefined;
  secondaryMeasure?: Measure | null;
  measureFormats: VisualData['measureFormats'];
}) => {
  const axisMeasure = getMeasure({
    point,
    measure,
    secondaryMeasure,
    measureFormats,
  });
  return formatNumber(
    {
      value: point.y as number,
      format: axisMeasure?.format as NumberFormat,
      currency: axisMeasure?.currency,
    },
    { notation: 'compact' }
  );
};

export const getTooltipKey = (
  x: number | string,
  y: number | string
): string => {
  return `${x}-${y}`;
};

export const getScatterData = (data: ScatterData[]): ScatterInfo => {
  const quadrants = {};
  const tooltipMap = {};
  const series: SeriesScatterOptions[] = [];

  data.forEach((point) => {
    if (!point.rank_quad.colour) {
      return;
    }
    if (!quadrants[point.rank_quad.colour]) {
      quadrants[point.rank_quad.colour] = {
        data: [],
      };
    }

    quadrants[point.rank_quad.colour].data.push([
      point.comp_rank1.value,
      point.comp_rank2.value,
    ]);

    const tooltipKey =
      typeof point.comp_rank1.value === 'number' &&
      typeof point.comp_rank2.value === 'number'
        ? getTooltipKey(point.comp_rank1.value, point.comp_rank2.value)
        : null;

    if (tooltipKey) {
      tooltipMap[tooltipKey] = point;
    }
  });

  Object.keys(quadrants).forEach((key) => {
    series.push({
      data: quadrants[key].data,
      color: key,
      marker: {
        symbol: 'circle',
      },
    } as SeriesScatterOptions);
  });

  return {
    series,
    tooltipMap,
  };
};

export const getTreeNode = (node: TreeChartData, type: TreeType) => {
  switch (type) {
    case 'old':
      return `<div style="font-family: 'Inter', Helvetica, Arial, Verdana, Tahoma, sans-serif; height: 100%;" data-testid="tree-node">
              <div style="display: flex; flex-direction: row-reverse; background-color: #2C248F; border-top-right-radius: 4px; border-top-left-radius: 4px; padding: 16px; text-align: left; height: 60%;">
              ${
                node.bmk_ind
                  ? `<div style="height: 16px;width: 16px; border-radius: 8px; background-color: ${getCSSColor(
                      node.bmk_ind,
                      1
                    )}; border: 1px solid ${getCSSColor(
                      node.bmk_ind,
                      1
                    )}"></div>`
                  : ''
              }
                <div style="width: 100%;">
                  <div style="font-size: ${
                    node.node_id_name && node.node_id_name.length > 20
                      ? '24px'
                      : '32px'
                  };color: #FFFFFF; font-weight: normal;"> 
                  ${node.node_id_name}
                </div>
                <div style="font-weight: bold;">
                  <div style="color:#FFFFFF; font-size:32px;"> 
                    ${formatNumber(node.p2)}</div>
                  <div style="font-size:32px; margin-top:4px; color:${getCSSColor(
                    node.pct_chg.value,
                    1
                  )}">
                  </div>    
                </div>
               </div>
            </div>
           <div style="padding: 16px;height: 40%;background-color: ${getCSSColor(
             node.pct_chg.value,
             2
           )}; color: ${getCSSColor(
        node.pct_chg.value,
        1
      )}; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; font-size: 24px; font-weight: bold; text-align: center; display: flex; justify-content: space-between;">
              <div>${formatNumber(node.contrib)}</div> 
              <div>(${formatNumber(node.pct_chg)})</div>
            </div>  
          </div>
        </div>`;
    case 'new':
      return renderToStaticMarkup(<TreeNode node={node} />);
  }
};

export const getChartHeight = (
  initialHeight: number,
  visualsToDisplay: Visual[],
  id: string
) => {
  let newHeight = initialHeight;
  if (
    screen.height > 1080 &&
    newHeight < screen.height - FULLSCREEN_CHART_HEIGHT_BUFFER &&
    visualsToDisplay.length < 2
  ) {
    newHeight = screen.height - FULLSCREEN_CHART_HEIGHT_BUFFER;
  } else {
    const { height: chartHeight } =
      document
        .querySelector(`#${id} .highcharts-plot-background`)
        ?.getBoundingClientRect() ?? {};
    const expectedHeight = Math.floor(0.75 * initialHeight);
    if (chartHeight && chartHeight < expectedHeight) {
      newHeight += expectedHeight - chartHeight;
    }
  }
  return newHeight;
};

export const getYAxesData = ({
  data,
  secondaryAxis,
  dropdownKey,
  measure,
  isSecondaryAxisVisible,
  isReportSplit,
}: {
  data: VisualData;
  secondaryAxis: {
    selection?: DropdownOptions;
    isActive: boolean;
  };
  dropdownKey: string;
  measure: Measure | null | undefined;
  isReportSplit: boolean;
  isSecondaryAxisVisible: boolean;
}) => {
  const visualData = structuredClone(data);
  const seriesData = visualData.seriesData?.[
    dropdownKey
  ] as Highcharts.SeriesOptionsType[];
  const yAxisWithMeasure: YAxisOptions = getYAxes({
    visualData,
    measure,
    dropdownKey,
    isReportSplit,
  }) as YAxisOptions;
  const series: Highcharts.SeriesOptionsType[] = [...seriesData];
  const yAxesWithMeasure: YAxisOptions[] = [];
  if (isSecondaryAxisVisible && secondaryAxis?.isActive) {
    const keys = dropdownKey.split('_');
    const { selection } = secondaryAxis;
    const secondaryDropdownKey = [`${selection?.key}`, ...keys.slice(1)].join(
      '_'
    );
    yAxesWithMeasure.push(yAxisWithMeasure, {
      ...getYAxes({
        visualData,
        measure: selection?.numberFormat
          ? visualData.measureFormats?.[selection.numberFormat]
          : null,
        dropdownKey: secondaryDropdownKey,
        isReportSplit,
      }),
      opposite: true,
      title: {
        text: `<span class="axis-edit-icon-wrapper"><span class="axis-edit-icon" id="axis-edit-icon"></span> ${selection?.label}</span>`,
        useHTML: true,
      },
    });
    const currentData = visualData.seriesData?.[dropdownKey].map((data) => ({
      ...data,
      yAxis: 0,
      type: 'column',
    }));
    const secondaryData = visualData.seriesData?.[secondaryDropdownKey].map(
      (data) => ({
        ...data,
        yAxis: 1,
        name: `${data.name} - ${secondaryAxis.selection?.label}`,
        colorIndex: data.colorIndex + currentData.length,
        type: 'line',
      })
    );
    series.splice(0, series.length, ...currentData, ...secondaryData);
  }

  return {
    series:
      isSecondaryAxisVisible && secondaryAxis?.isActive
        ? series.filter(
            ({ custom }) =>
              isSecondaryAxisVisible &&
              secondaryAxis?.isActive &&
              !custom?.disableDualAxis
          )
        : series,
    yAxesData:
      yAxesWithMeasure.length > 0 ? yAxesWithMeasure : yAxisWithMeasure,
  };
};

type D3Node = d3.BaseType & {
  __on: {
    listener: (event: WheelEvent, ...rest) => void;
    type: string;
    name: string;
    value: () => void;
  };
};

export const disableZoomingForWheelEvent = (containerRef: HTMLElement) => {
  const listener = (d3.select(containerRef).select('svg').node() as D3Node)
    .__on?.[0].listener;
  d3.select(containerRef)
    .select('svg')
    .on('wheel.zoom', function (...args) {
      if (args[0].ctrlKey || args[0].altKey) {
        listener.call(this, ...args);
      }
    });
};

export const sharedDropdownWrapper = (
  children: ReactNode,
  ref: HTMLDivElement,
  className?: string
) => {
  return createPortal(<div className={className}>{children}</div>, ref);
};

export const getMeasure = ({
  point,
  measure,
  secondaryMeasure,
  measureFormats,
}: {
  point: PointLabelObject;
  measure: Measure | null | undefined;
  secondaryMeasure: Measure | null | undefined;
  measureFormats: VisualData['measureFormats'];
}): Measure | undefined => {
  const pointMeasure = point.series.yAxis?.userOptions?.opposite
    ? secondaryMeasure
    : measure;
  if (pointMeasure) {
    return pointMeasure;
  } else {
    return measureFormats && point.series.options.custom?.format
      ? measureFormats[point.series.options.custom.format]
      : undefined;
  }
};

export const getXRangeSeries = (
  seriesData: {
    data: {
      x: string;
      x2: string;
      y: number;
    }[];
  }[]
): SeriesXrangeOptions[] =>
  seriesData.map((series) => ({
    ...series,
    type: 'xrange',
    data: series.data.map((point) => ({
      ...point,
      x: DateTime.fromISO(point.x, { zone: 'utc' }).toMillis(),
      x2: DateTime.fromISO(point.x2, { zone: 'utc' }).toMillis(),
    })),
  }));
