import { Dropdown, Toggle } from '@carbon/react';
import { useContext, useEffect, useState } from 'react';
import { usePrevious } from '../../hooks';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { SUBSTITUTE_HIGHLIGHT_KEY } from '../Charts/AssortmentGrid';

const COLLAPSED_ITEM_LIMIT = 4;
const HighlightControls = () => {
  const { highlightLegend, highlight, updateHighlight, clearHighlight } =
    useContext(AssortmentContext);

  const [expanded, setExpanded] = useState(false);
  const showDropdown = highlight && highlight.key !== SUBSTITUTE_HIGHLIGHT_KEY;
  const showLegend = Boolean(highlight);

  const previousHighlight = usePrevious(highlight);

  useEffect(() => {
    if (highlight !== previousHighlight && highlight?.type === 'index') {
      setExpanded(true);
    }
  }, [highlight, previousHighlight]);

  return (
    <>
      <div className="HighlightToggle">
        <span className="body-02">Highlight Attributes</span>
        <Toggle
          size="sm"
          labelA="Off"
          labelB="On"
          labelText=""
          id="highlight-assortment-atts"
          toggled={showDropdown}
          onToggle={(checked: boolean) => {
            if (!checked) {
              clearHighlight();
              return;
            }

            if (highlightLegend?.[0]) {
              updateHighlight({ by: highlightLegend[0].key });
            }
          }}
        />
      </div>
      <div className="HighlightControls" data-testid="highlight-controls">
        {showDropdown && (
          <Dropdown
            id="highlight-dropdown"
            data-testid="highlight-dropdown"
            label="Highlight by"
            hideLabel
            items={highlightLegend?.filter((item) => !item.hidden)}
            selectedItem={highlight}
            itemToString={(item) => item.label}
            onChange={({ selectedItem }) => {
              updateHighlight({ by: selectedItem.key });
            }}
          />
        )}
        <div className="HighlightControls__legend-anchor">
          {showLegend && (
            <div
              className={[
                'HighlightControls__legend',
                highlight?.type
                  ? `HighlightControls__legend--type-${highlight.type}`
                  : '',
              ].join(' ')}
            >
              {highlight && 'title' in highlight && highlight.title && (
                <div className="HighlightControls__legend-title">
                  {highlight.title}
                </div>
              )}

              {highlight?.items
                .filter((_, index) => expanded || index <= COLLAPSED_ITEM_LIMIT)
                .map(({ label, colour, info, showLabel = true }) => (
                  <div key={label} className="HighlightControls__legend-item">
                    <div
                      className={`HighlightControls__legend-item__label${
                        showLabel
                          ? ''
                          : ' HighlightControls__legend-item__label--hidden'
                      }`}
                    >
                      {label}
                    </div>
                    {info && (
                      <div className="HighlightControls__legend-item__info">
                        {info}
                      </div>
                    )}
                    <div
                      className="HighlightControls__legend-item__key"
                      style={{ backgroundColor: colour }}
                    />
                  </div>
                ))}
              <div
                className="HighlightControls__expand-btn"
                onClick={() => setExpanded(!expanded)}
                style={{
                  display:
                    highlight &&
                    highlight.items.length <= COLLAPSED_ITEM_LIMIT + 1
                      ? 'none'
                      : 'inline-block',
                }}
              >
                {expanded ? 'Collapse Legend' : 'Expand Legend'}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HighlightControls;
