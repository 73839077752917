import type { RowDataDefaultType } from '../components/Charts/DataGrid';
import {
  UPDATE_NPD_ROW_FILE_DATA,
  UPDATE_NPD_ROWS,
} from '../constants/reducers';
import { getNPDProductCards } from '../utils/reportUtils';
import type { VisualData } from './ReportReducer';

export enum NPDProductSelection {
  UNSELECTED = 'unselected',
  FOCUS = 'focus',
  BENCHMARK = 'bench',
  EXCLUDED = 'excl',
}

export interface NPDCard {
  focusProducts: number;
  benchmarkProducts: number;
}

export interface NPDGridRowDataType extends RowDataDefaultType {
  SELECTION: { type: NPDProductSelection; value: string };
}

export interface NPDState {
  readonly rows: {
    [key: string]: NPDGridRowDataType[];
  };
  readonly cards: {
    [key: string]: NPDCard;
  };
}

interface UpdateNPDRowData {
  type: typeof UPDATE_NPD_ROW_FILE_DATA;
  rows: NPDGridRowDataType[];
  dropdownKey: string;
}

interface UpdateNPDRows {
  type: typeof UPDATE_NPD_ROWS;
  rowData: NonNullable<VisualData<NPDGridRowDataType>['rows']>;
}

export type NPDAction = UpdateNPDRowData | UpdateNPDRows;

export const initialNPDState: NPDState = {
  rows: {},
  cards: {},
};

const NPDReducer = (state: NPDState, action: NPDAction): NPDState => {
  switch (action.type) {
    case UPDATE_NPD_ROW_FILE_DATA: {
      const cards = getNPDProductCards({ [action.dropdownKey]: action.rows });
      return {
        ...state,
        rows: {
          ...state.rows,
          [action.dropdownKey]: action.rows,
        },
        cards: {
          ...state.cards,
          ...cards,
        },
      };
    }

    case UPDATE_NPD_ROWS: {
      const cards = getNPDProductCards(action.rowData);
      return {
        ...state,
        rows: {
          ...action.rowData,
          ...state.rows,
        },
        cards: {
          ...cards,
          ...state.cards,
        },
      };
    }

    default:
      return state;
  }
};

export default NPDReducer;
