import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { Grid } from '@carbon/react';
import type { GlobalSelection } from '../ReportContent';
import { ReportContext } from '../../../providers/ReportProvider';
import type { Visual } from '../../../reducers/ReportReducer';
import SharedDropdowns from '../Dropdowns/SharedDropdowns';
import ConditionalWrapper from '../../ConditionalWrapper';
import { sharedDropdownWrapper } from '../../../utils/chartUtils';
import VisualContainer from './VisualContainer';

interface VisualsListContainerProps {
  readonly visuals: Visual[];
  readonly reportType: ReportType | undefined;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly globalSelections: GlobalSelection[];
  readonly headerPrefix?: string;
  readonly excludedSharedDropdownIds?: number[];
  readonly hideVisualHeader?: boolean;
}

const VisualsListContainer: FunctionComponent<VisualsListContainerProps> = ({
  visuals,
  reportType,
  reportTemplateId,
  reportTemplateIndex,
  globalSelections,
  headerPrefix = '',
  excludedSharedDropdownIds = [],
  hideVisualHeader,
}) => {
  const {
    visualsData,
    tabIndex,
    sharedDropdowns,
    fullscreenVisual,
    visualControlsRef,
  } = useContext(ReportContext);
  const controlsContainerRef = visualControlsRef[visuals[0].id];

  const containerSharedDropdowns = visualsData[
    visuals[0].id
  ]?.dropdownConfig?.filter(
    (dropdown) =>
      dropdown.align !== 'right' &&
      !sharedDropdowns[tabIndex]?.some((sdd) => sdd.id === dropdown.id) &&
      !globalSelections?.some(({ label }) => dropdown.label === label) &&
      !excludedSharedDropdownIds.includes(dropdown.id)
  );

  const excludedDropdownIds = containerSharedDropdowns?.map(({ id }) => id);

  return (
    <>
      <ConditionalWrapper
        wrapper={(children) =>
          sharedDropdownWrapper(
            children,
            controlsContainerRef.current as HTMLDivElement,
            !hideVisualHeader ? 'VisualsListContainer__dropdowns-wrapper' : ''
          )
        }
        condition={
          !!visualsData[visuals[0].id]?.visualHeader &&
          fullscreenVisual === null &&
          controlsContainerRef?.current
        }
      >
        <div className="VisualsListContainer__shared-dropdowns">
          <SharedDropdowns
            tab={tabIndex}
            visualIds={visuals.map(({ id }) => id)}
            globalSelections={globalSelections}
            optionalDropdowns={containerSharedDropdowns}
            hideLabels={!!visualsData[visuals[0].id]?.visualHeader}
            size="sm"
          />
        </div>
      </ConditionalWrapper>
      <Grid>
        {visuals.map((vis) => {
          const { dropdownConfig, sharedDropdown } = visualsData[vis.id] ?? {};
          const hideDropdowns =
            visualsData[vis.id] &&
            dropdownConfig?.length === sharedDropdown?.length &&
            dropdownConfig?.every(({ id }) =>
              sharedDropdown?.find(({ id: dropdownId }) => id === dropdownId)
            );

          return (
            <VisualContainer
              key={vis.id}
              visual={vis}
              reportType={reportType}
              reportTemplateId={reportTemplateId}
              reportTemplateIndex={reportTemplateIndex}
              globalSelections={globalSelections}
              withoutBackground
              useDropdownsWrapper
              hideDropdowns={hideDropdowns}
              headerPrefix={headerPrefix}
              excludedSharedDropdownIds={excludedDropdownIds}
              hideVisualHeader={hideVisualHeader}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default VisualsListContainer;
