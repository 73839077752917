import type { FunctionComponent } from 'react';
import '../../styles/components/summaryCards.scss';
import { formatNumber } from '../../utils/reportUtils';
import IconTitle from '../IconTitle';
import { CurrencyDollar } from '@carbon/icons-react';

interface CardData extends NumberPoint {
  order?: number;
  index?: 'positive' | 'negative' | 'neutral';
}

export interface SummaryCard {
  title: string;
  data: CardData;
  children: CardData[];
}

interface SummaryCardsProps {
  cards: SummaryCard[];
}

const SummaryCards: FunctionComponent<SummaryCardsProps> = ({ cards }) => {
  return (
    <div
      className="SummaryCards"
      data-testid="summary-cards"
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${cards.length}, minmax(160px, 260px))`,
      }}
    >
      {cards.map(({ title, data, children }) => {
        return (
          <div key={title} className="SummaryCard">
            <div className="SummaryCard--title">
              <IconTitle
                icon={<CurrencyDollar size={14} />}
                title={title}
                kind="primary"
                titleSize="01"
                highlightIcon
              />
            </div>
            <div className="SummaryCard--content">
              <div className="SummaryCard--content-title">Total</div>
              <div className="SummaryCard--content-value">
                {formatNumber(data)}
              </div>
              <div className="SummaryCard--content-footer">
                {[...children]
                  .sort((a, b) => {
                    if (
                      typeof a.order !== 'number' ||
                      typeof b.order !== 'number'
                    ) {
                      return -1;
                    }
                    return a.order - b.order;
                  })
                  .map(({ value, format, order, index }) => {
                    let className = 'SummaryCard--content-footer-value';
                    className += index
                      ? ` SummaryCard--content-footer-value__${index}`
                      : '';
                    return (
                      <div key={order} className={className}>
                        {index && typeof value === 'number' && value >= 0
                          ? '+'
                          : ''}
                        {formatNumber({ value, format })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SummaryCards;
