/* eslint-disable indent */
import { DateTime } from 'luxon';
import { REPORT_STATUS } from '../components/Workspace';
import type { FilterState } from '../reducers/ReportFilterReducer';

export const getSelectedTabs = (
  index: number,
  options: string[],
  selectedTabs: number[],
  selectAllIndex: number
) => {
  const defaultSelection: number[] = selectedTabs.includes(selectAllIndex)
    ? [index]
    : [...selectedTabs, index];

  const oneOrMoreSelections: number[] =
    selectedTabs.length === 1
      ? [selectAllIndex]
      : selectedTabs.filter((tab) => tab !== index);

  const allSelectionsExceptFirst: number[] =
    selectedTabs.length === options.length - 2 &&
    !selectedTabs.includes(selectAllIndex)
      ? [selectAllIndex]
      : defaultSelection;

  if (index === selectAllIndex) {
    return [selectAllIndex];
  } else {
    if (selectedTabs.includes(index)) {
      return oneOrMoreSelections;
    } else {
      return allSelectionsExceptFirst;
    }
  }
};

export const getReportTypes = (reportData: ReportMetaData[]) => {
  const reportTypes: ReportType[] = reportData
    .map((row) => row.report_type)
    .filter((type) => !!type);

  return [...Array.from(new Set(reportTypes))];
};

export const isRowMatchCondition = (
  row: ReportMetaData,
  state: FilterState,
  userId: User['id'] | undefined
) => {
  const typeCondition =
    state.report_type.length > 0 && row.report_type
      ? state.report_type.includes(row.report_type)
      : true;

  const nameCondition = row.report_name
    .toLowerCase()
    .includes(state.report_name.toLowerCase());

  const dateCondition = () => {
    const submittedDate = new Date(row.end_time_formatted as string);
    if (state.end_date.length === 0 && state.start_date.length === 0) {
      return true;
    }
    if (state.start_date.length > 0 && state.end_date.length === 0) {
      return submittedDate >= state.start_date[0];
    }
    const endDate = new Date(state.end_date[0]);
    endDate.setHours(23, 59, 59);
    if (state.start_date.length === 0 && state.end_date.length > 0) {
      return submittedDate <= endDate;
    }
    return submittedDate >= state.start_date[0] && submittedDate <= endDate;
  };

  const userNameCondition =
    state.user_name.length > 0 ? state.user_name.includes(row.user_name) : true;

  const statusCondition = () => {
    if (state.status.length === 0) {
      return true;
    }
    switch (row.status) {
      case REPORT_STATUS.FAILED:
      case REPORT_STATUS.CANCELLED:
      case REPORT_STATUS.NO_DATA:
        return state.status.includes(REPORT_STATUS.FAILED);
      default:
        return state.status.includes(row.status);
    }
  };
  const statusColumnCondition = statusCondition();

  const sharingCondition =
    !state.sharing ||
    (state.sharing === 'my-shares' && row.user_id === userId) ||
    (state.sharing === 'shared-with-me' && row.user_id !== userId);

  return (
    nameCondition &&
    typeCondition &&
    dateCondition() &&
    userNameCondition &&
    statusColumnCondition &&
    sharingCondition
  );
};

export const getFilterParam = (option, value) => {
  if (option === 'report_name') {
    return value;
  }

  if (option === 'end_date' || option === 'start_date') {
    const [date] = value as Date[];

    return DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');
  }

  return (Array.isArray(value) ? value.join(',') : value) || undefined;
};
