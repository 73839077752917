import type { FunctionComponent } from 'react';
import '../../styles/components/productSummary.scss';
import IconTitle from '../IconTitle';
import { Product } from '@carbon/icons-react';

interface ProductSummaryProps {
  summary: ProductCount;
}
const ProductSummary: FunctionComponent<ProductSummaryProps> = ({
  summary,
}) => {
  const { current, optimised } = summary;
  return (
    <div className="ProductSummary" data-testid="product-summary">
      <IconTitle
        icon={<Product size={14} />}
        title="Product Count"
        kind="primary"
        titleSize="01"
        highlightIcon
      />
      <div className="ProductSummary__content">
        <div className="ProductSummary__block">
          <div className="ProductSummary__block-title">Total</div>
          <div className="ProductSummary__block-content">
            <div className="ProductSummary__block-row" />
            <div className="ProductSummary__block-row">
              <div>{current.total}</div>
            </div>
            <div className="ProductSummary__block-row">
              <div>
                <strong>{optimised.total}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="ProductSummary__block">
          <div className="ProductSummary__block-title">Existing</div>
          <div className="ProductSummary__block-content">
            <div className="ProductSummary__block-row">
              <div className="ProductSummary__block-content--subtitle">In</div>
              <div className="ProductSummary__block-content--subtitle">Out</div>
            </div>
            <div className="ProductSummary__block-row">
              <div>{current.existing.in}</div>
              <div>{current.existing.out}</div>
            </div>
            <div className="ProductSummary__block-row">
              <div>
                <strong>{optimised.existing.in}</strong>
              </div>
              <div>
                <strong>{optimised.existing.out}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="ProductSummary__block">
          <div className="ProductSummary__block-title">New</div>
          <div className="ProductSummary__block-content">
            <div className="ProductSummary__block-row">
              <div className="ProductSummary__block-content--subtitle">In</div>
              <div className="ProductSummary__block-content--subtitle">Out</div>
            </div>
            <div className="ProductSummary__block-row">
              <div>{current.new.in}</div>
              <div>{current.new.out}</div>
            </div>
            <div className="ProductSummary__block-row">
              <div>
                <strong>{optimised.new.in}</strong>
              </div>
              <div>
                <strong>{optimised.new.out}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
