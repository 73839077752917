/* eslint-disable indent */
import ChartProvider from '../providers/ChartProvider';
import type {
  ReportConfig,
  TimePeriodData,
  VisualData,
} from '../reducers/ReportReducer';
import { VisualType } from '../reducers/ReportReducer';
import { Grid, Column } from '@carbon/react';
import { DataError } from '@carbon/icons-react';
import { getPlotOptions, getXAxis, getYAxes } from '.';
import Chart from '../components/Charts/Chart';
import ChartContainer from '../components/Charts/ChartContainer';
import type {
  CustomAction,
  RowDataDefaultType,
} from '../components/Charts/DataGrid';
import DataGrid from '../components/Charts/DataGrid';
import Dendrogram from '../components/Charts/Dendrogram';
import ScatterWithSummary from '../components/Charts/ScatterWithSummary';
import TreeChart from '../components/Charts/TreeChart';
import Venn from '../components/Charts/Venn';
import {
  stackedColumnTooltip,
  columnChartTooltip,
  legendFormatter,
  customChartTooltip,
} from '../components/Report/TooltipFormatters';
import AssortmentGrid from '../components/Charts/AssortmentGrid';
import AssortmentDendrogram from '../components/Charts/AssortmentDendrogram';
import OptimiserGrid from '../components/Charts/OptimiserGrid';
import NoDataPlaceholder from '../components/ReportTable/NoDataPlaceholder';
import { checkGetVisualData } from './reportUtils';
import ProductSummary from '../components/Optimise/ProductSummary';
import type { SummaryCard } from '../components/Optimise/SummaryCards';
import SummaryCards from '../components/Optimise/SummaryCards';
import ReportMetricsCard from '../components/Cards/ReportMetricsCard';
import ReportCard from '../components/Cards/ReportCard';
import { getXRangeSeries } from './chartUtils';
import type { GlobalSelection } from '../components/Report/ReportContent';
import NPDGrid from '../components/Charts/NPDGrid';
import type { NPDGridRowDataType } from '../reducers/NPDReducer';

interface VisualOptions {
  measure?: Measure | null;
  reportConfig: ReportConfig;
  isAuthor?: boolean;
  isSupplier?: boolean;
  isSkuSelected?: boolean;
  isSkuGridReady?: boolean;
  reportTemplateId: string;
  reportTemplateIndex: number;
  visualDropdownSelection?: string;
  visualHeader?: VisualHeader;
  gridCustomToolbarActions?: CustomAction[];
  gridCustomTableActions?: CustomAction[];
  tab: number | string;
  showExport?: boolean;
  exportAllCharts?: boolean;
  isVisible?: boolean;
  hasChildGrid?: boolean;
  isSecondaryAxisVisible?: boolean;
  secondaryDropdownSelection?: {
    selection?: DropdownOptions;
    isActive: boolean;
  };
  isHomepage?: boolean;
  globalSelections?: GlobalSelection[];
  timePeriods?: TimePeriodData;
  toggleSkuGrid?: () => void;
  patchVisualsData?: (id: number | string, data: Partial<VisualData>) => void;
  handleGridServerExport?: (name: string) => Promise<void>;
}
export const getVisual = (
  id: number,
  type: VisualType,
  data: VisualData | { [key: string]: DendrogramNode },
  dropdownKey: string,
  options: VisualOptions
) => {
  const {
    measure,
    reportConfig,
    isAuthor,
    isSupplier,
    isSkuSelected,
    isSkuGridReady,
    reportTemplateId,
    reportTemplateIndex,
    visualDropdownSelection,
    visualHeader,
    gridCustomToolbarActions,
    gridCustomTableActions,
    tab,
    showExport,
    exportAllCharts,
    isVisible,
    hasChildGrid,
    isSecondaryAxisVisible,
    secondaryDropdownSelection,
    isHomepage,
    globalSelections,
    timePeriods,
    toggleSkuGrid,
    patchVisualsData,
    handleGridServerExport,
  } = options;
  const visualData = data as VisualData;

  const isReportSplit = reportConfig?.report_version === 'v2';

  if (!checkGetVisualData(visualData, dropdownKey, type)) {
    return (
      <div className="getVisual__no-data-wrapper">
        <NoDataPlaceholder
          title="No Data"
          icon={DataError}
          description={{
            info: (
              <div className="getVisual__no-data-info">
                The selected input combination does not have any associated
                data.
                <br />
                Try altering your selection to obtain updated results.
              </div>
            ),
          }}
        />
      </div>
    );
  }

  switch (type) {
    case VisualType.SUMMARY_GRID:
    case VisualType.CELL_GRID: {
      const dataGridVisualData = visualData as VisualData<RowDataDefaultType>;
      const headerData = dataGridVisualData.headers?.[dropdownKey] as Header[];
      const rowData = dataGridVisualData.rows?.[
        dropdownKey
      ] as RowDataDefaultType[];
      return (
        <div
          data-testid="data-grid"
          key={`${id}-${visualDropdownSelection}-${dropdownKey}`}
        >
          <DataGrid
            headerData={headerData}
            rowData={rowData}
            stickyColumns={dataGridVisualData.stickyColumns}
            visibleColumns={dataGridVisualData.isVisible}
            searchable
            searchableColumns={dataGridVisualData.isSearchable}
            exportedFileName={reportConfig.report_name}
            visualData={dataGridVisualData}
            columnWidths={dataGridVisualData.columnWidths}
            id={id}
            resizable
            reportConfig={reportConfig}
            dropdownKey={dropdownKey}
            isAuthor={isAuthor}
            isSkuSelected={isSkuSelected}
            isSkuGridReady={isSkuGridReady}
            patchVisualsData={patchVisualsData}
            toggleSkuGrid={toggleSkuGrid}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            visualHeader={visualHeader}
            customToolbarActions={gridCustomToolbarActions}
            customTableActions={gridCustomTableActions}
            handleGridServerExport={handleGridServerExport}
            showPagination={type !== VisualType.SUMMARY_GRID}
            hasChildGrid={hasChildGrid}
            persistHeaders={isHomepage}
            globalSelections={globalSelections}
          />
        </div>
      );
    }

    case VisualType.OPTIMISER_GRID:
    case VisualType.OPTIMISER_PLAN_GRID:
      return (
        <div data-testid="optimiser-grid" className="hide-in-export">
          <OptimiserGrid
            isAuthor={isAuthor}
            isSupplier={isSupplier}
            dropdownKey={dropdownKey}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
          />
        </div>
      );

    case VisualType.DENDRO_GRID: {
      const dataGridVisualData = visualData as VisualData<RowDataDefaultType>;
      const rowData = dataGridVisualData.rows?.[
        dropdownKey
      ] as RowDataDefaultType[];
      const headerData = visualData.headers?.[dropdownKey] as Header[];
      return (
        <div data-testid="dendro-sticky-grid">
          <DataGrid
            headerData={headerData}
            rowData={rowData}
            stickyColumns={visualData.stickyColumns}
            visibleColumns={visualData.isVisible}
            collapsed
            showExport={false}
            isSortable={false}
            showPagination={false}
            exportedFileName={reportConfig?.report_name}
            columnWidths={visualData.columnWidths}
            visualData={visualData}
            id={1}
            resizable
            isAuthor={isAuthor}
            reportConfig={reportConfig}
            reportTemplateIndex={reportTemplateIndex}
            reportTemplateId={reportTemplateId}
            globalSelections={globalSelections}
            patchVisualsData={patchVisualsData}
          />
        </div>
      );
    }

    case VisualType.CDT_GRID:
      return (
        <div data-testid="assortment-grid">
          <AssortmentGrid
            dropdownKey={dropdownKey}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
          />
        </div>
      );

    case VisualType.DENDROGRAM:
    case VisualType.CDT_DENDROGRAM: {
      const dendroData = data[0] as DendrogramNode;
      const numSkus = dendroData?.numSkus as number;
      return (
        <div data-testid="dendrogram" className="dendrogram-container">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              showExpand={false}
              showLabelsToggle={false}
              useServerExportService
              visualHeader={visualHeader}
              tab={tab}
              measureFormats={visualData.measureFormats}
            >
              {type === VisualType.DENDROGRAM ? (
                <Dendrogram data={dendroData} numSkus={numSkus} />
              ) : (
                <AssortmentDendrogram data={dendroData} numSkus={numSkus} />
              )}
            </ChartContainer>
          </ChartProvider>
        </div>
      );
    }

    case VisualType.COLUMN: {
      const xAxis = visualDropdownSelection
        ? getXAxis(visualData, dropdownKey, visualDropdownSelection)
        : undefined;
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            visualHeader={visualHeader}
            tab={tab}
            isSecondaryAxisVisible={isSecondaryAxisVisible}
            measureFormats={visualData.measureFormats}
            xAxis={xAxis}
          >
            <Chart
              data-testid="column-chart"
              key={`${dropdownKey}_${visualDropdownSelection}`}
              id={`chart-${id}`}
              reportTemplateId={reportTemplateId}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
              isVisible={isVisible}
              options={{
                series: visualData.seriesData?.[
                  dropdownKey
                ] as Highcharts.SeriesOptionsType[],
                chart: visualData.chart,
                plotOptions: getPlotOptions(visualData),
                xAxis: xAxis,
                yAxis: getYAxes({
                  visualData,
                  measure,
                  dropdownKey,
                  isReportSplit,
                }),
                tooltip: {
                  formatter: function () {
                    return columnChartTooltip({
                      measure,
                      point: this,
                      title: this.x ?? '',
                      measureFormats: visualData.measureFormats,
                    });
                  },
                  useHTML: true,
                  className: 'chart-tooltip',
                  backgroundColor: undefined,
                  borderWidth: 0,
                  shadow: false,
                },
                legend: {
                  ...visualData.legend,
                  labelFormatter: function () {
                    return legendFormatter(this.name);
                  },
                  useHTML: true,
                },
              }}
            />
          </ChartContainer>
        </ChartProvider>
      );
    }

    case VisualType.STACKED_COLUMN: {
      const xAxis = visualDropdownSelection
        ? getXAxis(visualData, dropdownKey, visualDropdownSelection)
        : undefined;
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            showExport={showExport}
            visualHeader={visualHeader}
            tab={tab}
            isSecondaryAxisVisible={isSecondaryAxisVisible}
            measureFormats={visualData.measureFormats}
            xAxis={xAxis}
          >
            <Chart
              key={`${dropdownKey}_${visualDropdownSelection}`}
              id={`chart-${id}`}
              reportTemplateId={reportTemplateId}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
              isVisible={isVisible}
              options={{
                series: visualData.seriesData?.[
                  dropdownKey
                ] as Highcharts.SeriesOptionsType[],
                xAxis,
                chart: visualData.chart,
                yAxis: getYAxes({
                  visualData,
                  measure,
                  dropdownKey,
                  isReportSplit,
                }),
                plotOptions: getPlotOptions(visualData),
                tooltip: {
                  formatter: function () {
                    return stackedColumnTooltip({
                      measure,
                      points: this.points,
                      title: this.x ?? '',
                      measureFormats: visualData.measureFormats,
                    });
                  },
                  shared: true,
                  useHTML: true,
                  className: 'chart-tooltip',
                  backgroundColor: undefined,
                  borderWidth: 0,
                  shadow: false,
                  distance: 20,
                },
                legend: {
                  ...visualData.legend,
                  labelFormatter: function () {
                    return legendFormatter(this.name);
                  },
                  useHTML: true,
                },
              }}
            />
          </ChartContainer>
        </ChartProvider>
      );
    }

    case VisualType.KPI_TREE:
      return (
        <div data-testid="tree-old">
          <TreeChart
            key={`${dropdownKey}_${visualDropdownSelection}`}
            treeId={id}
            treeData={visualData.seriesData?.[dropdownKey] as TreeChartData[]}
            layout={visualData.treeLayout}
            treeType="old"
            tab={tab}
            exportAllCharts={exportAllCharts}
            visualHeader={visualHeader}
          />
        </div>
      );

    case VisualType.TREE:
      return (
        <div data-testid="tree">
          <TreeChart
            key={`${dropdownKey}_${visualDropdownSelection}`}
            treeId={id}
            treeData={visualData.seriesData?.[dropdownKey] as TreeChartData[]}
            layout={visualData.treeLayout}
            visualHeader={visualHeader}
            treeType="new"
            tab={tab}
            exportAllCharts={exportAllCharts}
            timePeriods={timePeriods}
          />
        </div>
      );

    case VisualType.VENN:
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            showLabelsToggle={false}
            visualHeader={visualHeader}
            tab={tab}
            measureFormats={visualData.measureFormats}
          >
            <Venn
              vennData={visualData.vennData?.[dropdownKey] as number[]}
              vennLegend={visualData.vennLegendItems ?? []}
              vennSections={visualData.vennSections ?? []}
            />
          </ChartContainer>
        </ChartProvider>
      );

    case VisualType.SCATTER:
      return (
        <div data-testid="scatter">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              showLabelsToggle={false}
              visualHeader={visualHeader}
              tab={tab}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
              measureFormats={visualData.measureFormats}
            >
              <ScatterWithSummary
                visualData={visualData}
                reportTemplateId={reportTemplateId}
              />
            </ChartContainer>
          </ChartProvider>
        </div>
      );

    case VisualType.CUSTOM: {
      const xAxis = visualDropdownSelection
        ? getXAxis(visualData, dropdownKey, visualDropdownSelection)
        : undefined;
      return (
        <div data-testid="custom-visual">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              activeMeasure={measure}
              visualData={visualData}
              dropdownKey={dropdownKey}
              visualHeader={visualHeader}
              tab={tab}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
              measureFormats={visualData.measureFormats}
              xAxis={xAxis}
            >
              <Chart
                key={`${dropdownKey}_${visualDropdownSelection}_${
                  secondaryDropdownSelection?.selection?.key ?? ''
                }`}
                id={`chart-${id}`}
                reportTemplateId={reportTemplateId}
                isVisible={isVisible}
                isSecondaryAxisVisible={isSecondaryAxisVisible}
                options={{
                  series: visualData.seriesData?.[
                    dropdownKey
                  ] as Highcharts.SeriesOptionsType[],
                  plotOptions: getPlotOptions(visualData),
                  chart: visualData.chart,
                  xAxis: xAxis,
                  yAxis: visualData.measureDropdownId
                    ? getYAxes({
                        visualData,
                        measure,
                        dropdownKey,
                        isReportSplit,
                      })
                    : visualData.yAxis,
                  tooltip: {
                    ...visualData.tooltip,
                    formatter: function () {
                      return customChartTooltip({
                        tooltipType: visualData.tooltipType,
                        measure,
                        context: this,
                        measureFormats: visualData.measureFormats,
                      });
                    },
                    useHTML: true,
                    className: 'chart-tooltip',
                    backgroundColor: undefined,
                    borderWidth: 0,
                    shadow: false,
                  },
                  legend: {
                    ...visualData.legend,
                    labelFormatter: function () {
                      return legendFormatter(this.name);
                    },
                    useHTML: true,
                  },
                }}
              />
            </ChartContainer>
          </ChartProvider>
        </div>
      );
    }

    case VisualType.XRANGE: {
      return (
        <div data-testid="xrange-chart">
          <ChartProvider>
            <ChartContainer id={id} visualType={type}>
              <Chart
                key={`${dropdownKey}_${visualDropdownSelection}`}
                id={`chart-${id}`}
                reportTemplateId={reportTemplateId}
                isVisible={isVisible}
                options={{
                  series: getXRangeSeries(visualData.seriesData?.[dropdownKey]),
                  plotOptions: getPlotOptions(visualData),
                  chart: visualData.chart,
                  xAxis: visualData.xAxis,
                  yAxis: visualData.yAxis,
                  tooltip: {
                    xDateFormat: '%A, %d %b %Y',
                  },
                }}
              />
            </ChartContainer>
          </ChartProvider>
        </div>
      );
    }

    case VisualType.CARDS:
      return reportConfig?.report_type === 'Delist Transfer Analysis' ? (
        <Grid data-testid="card-grid">
          {(visualData.cards?.[dropdownKey] as Card[]).map((card) => {
            return (
              <Column key={card.title} lg={4} md={4} sm={4}>
                <ReportCard {...card} />
              </Column>
            );
          })}
        </Grid>
      ) : (
        <ReportMetricsCard
          items={visualData.cards?.[dropdownKey] as Card[]}
          visualHeader={visualHeader}
        />
      );

    case VisualType.PRODUCT_SUMMARY:
      return <ProductSummary summary={visualData.summary?.[dropdownKey][0]} />;

    case VisualType.SUMMARY_CARDS:
      return (
        <SummaryCards
          cards={visualData.cards?.[dropdownKey] as SummaryCard[]}
        />
      );

    case VisualType.SELECTION_GRID: {
      const dataGridVisualData = visualData as VisualData<NPDGridRowDataType>;
      return (
        <NPDGrid
          visualId={id}
          visualData={dataGridVisualData}
          dropdownKey={dropdownKey}
          reportTemplateId={reportTemplateId}
          reportTemplateIndex={reportTemplateIndex}
          isAuthor={!!isAuthor}
          isSupplier={!!isSupplier}
        />
      );
    }
  }
};
