import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@carbon/react';
import { CustomGroupsContext } from '../../../../providers/CustomGroupsProvider';
import CustomGroupSuccess from '../../../../assets/custom-groups/custom_group_success.svg';
import { AppContext } from '../../../../providers/AppProvider';
import { capitalizeFirstLetter } from '../../../../utils/reportUtils';
import usePosthog from '../../../../utils/posthog';
import {
  custGroupsCreateNewGroupBtnClick,
  custGroupsViewMyGroupsBtnClick,
} from '../../../../constants/posthog';

const CustomGroupsUploadSuccess: FunctionComponent = () => {
  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const { selectedDimGroup, resetUploadData, updateSelectedDimGroup } =
    useContext(CustomGroupsContext);
  const { dimensions } = useContext(AppContext);
  return (
    <div className="CustomGroupsUploadSuccess">
      <img src={CustomGroupSuccess} alt="Custom Group Success" />
      <div className="CustomGroupsUploadSuccess__success-title">Success!</div>
      <div className="CustomGroupsUploadSuccess__success-info">
        {`Your ${selectedDimGroup} Group has been created.`}
      </div>
      <div className="CustomGroupsUploadSuccess__success-btn-wrapper">
        <Button
          kind="secondary"
          onClick={() => {
            posthogEvent(custGroupsCreateNewGroupBtnClick);
            const firstDimWithCustomHierarchy = dimensions.find(
              (dim: Dimension) => dim.has_custom_hierarchy
            )?.dimension;
            resetUploadData();
            updateSelectedDimGroup(
              capitalizeFirstLetter(firstDimWithCustomHierarchy)
            );
            navigate('/custom-groups/upload');
          }}
          data-testid="upload-create-new-btn"
          size="md"
        >
          Create New Group
        </Button>
        <Button
          kind="primary"
          onClick={() => {
            posthogEvent(custGroupsViewMyGroupsBtnClick);
            resetUploadData();
            navigate('/custom-groups');
          }}
          data-testid="upload-view-groups-btn"
          size="md"
        >
          View My Groups
        </Button>
      </div>
    </div>
  );
};

export default CustomGroupsUploadSuccess;
