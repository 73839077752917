/*eslint-disable indent*/

import CellNotes from '../components/Charts/Cells/CellNotes';
import type { TableRow } from '../components/ReportTable/ReportTable';
import type { MergedHeader } from './reportUtils';
import CellDropdown from '../components/Charts/Cells/CellDropdown';
import {
  ArrowDown,
  ArrowUp,
  Money,
  ThumbsUp,
  ThumbsDown,
  Star,
  Group,
  CloseOutline,
} from '@carbon/icons-react';
import type { UploadData } from '../reducers/CustomGroupsReducer';

// This function provides backwards compatibility with the previous string
// option - where the colours were hardcoded into the frontend
function getLegacyValue(selectedItem: string): EditableDropdownOption {
  return selectedItem.match('In')
    ? {
        value: 'Force In',
        background_colour: '#e6f4e9',
        colour: '#1a7333',
      }
    : {
        value: 'Force Out',
        background_colour: '#ffebee',
        colour: '#bb1717',
      };
}

export const getEditableCell = ({
  editable,
  cell,
  rowId,
  onEditCell,
  isAuthor,
  isSupplier,
}: {
  editable: Editable;
  cell;
  rowId: string | number;
  onEditCell: (data: {
    value: string | EditableDropdownOption;
    cellKey: string;
    rowId: string | number;
  }) => void;
  isAuthor: boolean;
  isSupplier: boolean;
}) => {
  const { header: cellKey } = cell.info;

  switch (editable.type) {
    case 'dropdown':
      return (
        <CellDropdown
          key={`cell-dropdown-${rowId}-${cell.value.value}`}
          data-testid={`cell-dropdown-${rowId}`}
          selectedValue={cell.value.value}
          options={editable.options}
          editable={isAuthor && !isSupplier}
          aria-label="Force in out dropdown"
          onSelectValue={(value) =>
            onEditCell({
              value: typeof value === 'string' ? getLegacyValue(value) : value,
              cellKey,
              rowId,
            })
          }
        />
      );

    case 'text': {
      return (
        <CellNotes
          id={cell.id}
          value={cell.value}
          disabled={!isAuthor || isSupplier}
          onUpdate={(value) => {
            onEditCell({ value, cellKey, rowId });
          }}
        />
      );
    }
  }
};

export const getIndexesBetween = (startIndex: number, endIndex: number) => {
  const start = startIndex < endIndex ? startIndex : endIndex;
  const end = endIndex > startIndex ? endIndex : startIndex;

  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

export const getSelectedRowIds = (
  currentPageRows: TableRow[],
  selectedRows: TableRow[]
): string[] => {
  const filteredRows = selectedRows.length > 0 ? selectedRows : currentPageRows;
  return filteredRows.map(({ id }) => id);
};

export const getRowsToExport = (
  headerData: Header[] | MergedHeader[],
  rowData: object[]
) => {
  const headerKeys = headerData.map((h) => h.key);
  return rowData.map((row) => {
    const filteredRow = {};
    Object.keys(row).forEach((key) => {
      if (headerKeys.includes(key)) {
        filteredRow[key] = row[key];
      }
    });
    return filteredRow;
  });
};

export const sortHeaders = (
  a: Header,
  b: Header,
  visibleColumns?: number[]
): number => {
  return visibleColumns
    ? visibleColumns.indexOf(a.id) - visibleColumns.indexOf(b.id)
    : 0;
};

export const getCellIcon = (
  iconName: string,
  colour?: string
): JSX.Element | null => {
  const iconProps = {
    size: '14',
    className: 'table-cell-icon',
    style: { color: colour },
  } as const;

  switch (iconName) {
    case 'arrow_up':
      return <ArrowUp {...iconProps} data-testid="table-cell-arrow-up" />;

    case 'arrow_down':
      return <ArrowDown {...iconProps} data-testid="table-cell-arrow-down" />;

    case 'thumbs_up':
      return <ThumbsUp {...iconProps} data-testid="table-cell-thumbs-up" />;

    case 'thumbs_down':
      return <ThumbsDown {...iconProps} data-testid="table-cell-thumbs-down" />;

    case 'star':
      return <Star {...iconProps} data-testid="table-cell-star" />;

    case 'money':
      return <Money {...iconProps} data-testid="table-cell-money" />;

    case 'group':
      return <Group {...iconProps} data-testid="table-cell-group" />;

    case 'close_outline':
      return (
        <CloseOutline {...iconProps} data-testid="table-cell-close-outline" />
      );

    default:
      return null;
  }
};

export const sortUploadDataByInvalidEntities = (
  visibleRows: CarbonSelectedRow[],
  uploadData: UploadData[]
) => {
  const nonexistentEntities = uploadData
    .filter((gr) => !gr.is_exists)
    .map((gr) => gr.entity_no);

  const sortedRows = visibleRows.reduce((acc, curr) => {
    const entityCell = curr.cells.find((cell) => cell.id.includes('entity_no'));
    if (nonexistentEntities.includes(entityCell?.value)) {
      return [curr, ...acc];
    } else {
      return [...acc, curr];
    }
  }, [] as CarbonSelectedRow[]);

  return sortedRows;
};
