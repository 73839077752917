import type { FunctionComponent } from 'react';
import { InlineLoading, Tag } from '@carbon/react';
import { Checkmark } from '../../assets/icons/checkmark';
import { Crossmark } from '../../assets/icons/crossmark';
import Tooltip from '../Tooltip';
import type { JustUpdatedStatus } from './MyReportsTable';
import { getStatusBadge } from '../../utils/reportTableUtils';
import type { REPORT_STATUS } from '../Workspace';

interface StatusCellProps {
  row: CarbonSelectedRow;
  cell: CarbonCell<REPORT_STATUS>;
  justUpdatedStatus?: JustUpdatedStatus;
  handleStatusTooltip?: (label: string) => string | undefined;
  hasId?: (id: string) => boolean;
  getStatusText: () => string;
}

export const StatusCell: FunctionComponent<StatusCellProps> = ({
  row,
  cell,
  justUpdatedStatus,
  handleStatusTooltip,
  hasId,
  getStatusText,
}) => {
  const status = getStatusBadge(cell.value as REPORT_STATUS);

  return (
    <>
      {hasId?.(row.id) && getStatusText() ? (
        <InlineLoading description={getStatusText()} />
      ) : (
        <div className="statuses-container">
          <Tooltip
            label={
              status?.tooltipKey && handleStatusTooltip?.(status?.tooltipKey)
            }
            align="left"
            useButtonWrapper
          >
            <div className="table-status-wrapper">
              <Tag
                size="sm"
                className={`table-status ${status?.value.replace(/ /g, '_')}`}
              >
                {status?.value}
              </Tag>
              {justUpdatedStatus === 'Completed' && <Checkmark />}
              {justUpdatedStatus === 'Failed' && <Crossmark />}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};
