/* eslint-disable indent */

import type { FunctionComponent, ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { InlineLoading } from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../providers/AppProvider';
import Refresh from './Refresh';
import apiRequest from '../api';
import { timeSince } from '../utils';
import type { DynamicCacheKey } from '../constants/api';
import type { AxiosResponse } from 'axios';

export interface ResponsePayload<D> {
  readonly responseURL?: string;
  readonly data: D;
}

type MultiApi = string[] | Record<string, string>;

interface RefreshState {
  refreshable?: boolean;
  autoRefresh?: boolean;
}
interface FetchProps {
  apiUrl?: string;
  apiUrls?: MultiApi;
  multiRequest?: boolean;
  loadingComponent?: JSX.Element;
  loadingMessage?: string;
  alwaysFetchOnMount?: boolean;
  cacheKey?: DynamicCacheKey;
  initialData: unknown;
  hideChildrenUntilFetched?: boolean;
  onReceiveData: (data) => void;
  children?: ReactNode;
  refresh?: RefreshState;
  requestCanBeAborted?: boolean;
}

const Fetch: FunctionComponent<FetchProps> = ({
  apiUrl,
  apiUrls,
  multiRequest,
  loadingComponent,
  alwaysFetchOnMount,
  loadingMessage,
  cacheKey,
  initialData,
  hideChildrenUntilFetched = true,
  onReceiveData,
  children,
  refresh,
  requestCanBeAborted,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { bannerId, queryCache, updateQueryCache } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lastRefresh, setLastRefresh] = useState('');
  const [executed, setExecuted] = useState<Date | null>(null);
  const [appFocused, setAppFocused] = useState(true);

  const onFocus = () => {
    setAppFocused(true);
  };

  const onBlur = () => {
    setAppFocused(false);
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const getData = async (controller: AbortController | undefined) => {
    if (!apiUrl) {
      return;
    }
    setLoading(true);
    if (lastRefresh) {
      setLastRefresh('');
    }
    if (error) {
      setError(false);
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<AxiosResponse>(
        apiUrl,
        'GET',
        token,
        null,
        controller?.signal
      );
      onReceiveData(response.data);
      if (cacheKey && bannerId) {
        updateQueryCache(cacheKey, bannerId);
      }
      if (refresh && (refresh.refreshable || refresh.autoRefresh)) {
        setExecuted(new Date());
        setLastRefresh(timeSince(new Date()));
      }
    } catch (error) {
      if (controller?.signal.aborted === undefined) {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    if (refresh && refresh.refreshable && executed) {
      interval = setInterval(() => setLastRefresh(timeSince(executed)), 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [executed]);

  useEffect(() => {
    let interval;
    const controller = requestCanBeAborted ? new AbortController() : null;
    if (refresh && refresh.autoRefresh && appFocused && controller) {
      interval = setInterval(() => getData(controller), 10000);
    }

    return () => {
      clearInterval(interval);
      controller?.abort();
    };
  }, [executed, appFocused]);

  const makeMultipleRequests = async (
    controller: AbortController | undefined
  ) => {
    if (!apiUrls) {
      return;
    }
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const urls = Array.isArray(apiUrls)
        ? apiUrls
        : Object.entries(apiUrls).map(([key, url]) => ({ key, url }));

      const promises = urls.map(
        async (urlParam: string | { key: string; url: string }) => {
          const { key = undefined, url } =
            typeof urlParam === 'string' ? { url: urlParam } : urlParam;

          const { request, data } = await apiRequest<AxiosResponse>(
            url,
            'GET',
            token,
            null,
            controller?.signal
          );

          return {
            responseURL: request?.responseURL,
            data,
            ...(typeof key === 'string' && { key }),
          };
        }
      );

      const responses = await Promise.all(promises);

      onReceiveData(
        Array.isArray(apiUrls)
          ? responses
          : Object.fromEntries(responses.map(({ key, ...item }) => [key, item]))
      );
      if (cacheKey && bannerId) {
        updateQueryCache(cacheKey, bannerId);
      }
    } catch {
      if (controller?.signal.aborted === undefined) {
        setError(true);
      }
    }
    setLoading(false);
  };

  const makeRequest = (controller?: AbortController) =>
    multiRequest ? makeMultipleRequests(controller) : getData(controller);
  const currentQueryCache = cacheKey && queryCache[cacheKey];

  useEffect(() => {
    const controller = requestCanBeAborted ? new AbortController() : undefined;
    if (currentQueryCache !== bannerId || (alwaysFetchOnMount && !loading)) {
      onReceiveData(initialData);
      makeRequest(controller);
    }
    return () => {
      setLoading(false);
      controller?.abort();
    };
  }, [currentQueryCache, cacheKey]);

  const showChildren = () => {
    if (hideChildrenUntilFetched) {
      return !loading && !error;
    } else {
      return true;
    }
  };

  const renderLoadingIcon = () => {
    if (loading) {
      if (currentQueryCache && loadingMessage) {
        return <InlineLoading description={loadingMessage} />;
      } else {
        return (
          loadingComponent || <InlineLoading description={loadingMessage} />
        );
      }
    }
  };

  return (
    <>
      <div>{renderLoadingIcon()}</div>
      <div className="refresh-wrapper">
        <div>
          {!loading && refresh && refresh.refreshable && lastRefresh && (
            <Refresh
              message={`Last refreshed: ${lastRefresh}`}
              subtle
              onRetry={() => makeRequest()}
            />
          )}
          {error && (
            <Refresh
              message="Something went wrong, please try again."
              onRetry={() => makeRequest()}
            />
          )}
        </div>
      </div>

      {showChildren() && children}
    </>
  );
};

export default Fetch;
