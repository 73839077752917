import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { OrgChart } from 'd3-org-chart';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import { ReportContext } from '../../providers/ReportProvider';
import TreeChartControls from '../Report/TreeChartControls';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import * as d3 from 'd3';
import CDTNodeInfo from '../Report/Assortment/CDTNodeInfo';
import CDTLegend from '../Report/Assortment/CDTLegend';
import type { CDTNode } from '../../reducers/AssortmentReducer';
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  drawNodePath,
  getNodeY,
  nodeBodyStyle,
  nodeHeaderStyle,
  salesStyle,
  skusStyle,
  profitStyle,
} from '../../utils/CDTUtils';
import { Button } from '@carbon/react';
import { Number_1, Number_2, Number_3 } from '@carbon/icons-react';
import { VISUAL_CONTAINER_KEY } from '../../constants/values';
import { AppContext } from '../../providers/AppProvider';
import usePosthog from '../../utils/posthog';
import {
  cdtNodeClick,
  treeChartExpandLevelClick,
} from '../../constants/posthog';
import { VisualType } from '../../reducers/ReportReducer';
import Fetch from '../Fetch';
import type { DynamicCacheKey } from '../../constants/api';

const CDT = () => {
  const CDTId = 100;

  const { user } = useContext(AppContext);
  const { renderSidePanelContent, toggleSidePanelExpand } =
    useContext(SidePanelContext);
  const { cdtData, assortmentData, updateAssortmentData, updateCDTRootName } =
    useContext(AssortmentContext);
  const { visualRefs, reportConfig } = useContext(ReportContext);
  const [activeNode, setActiveNode] = useState<CDTNode | null>(null);
  const d3Container = useRef(null);

  const { fullscreenVisual, treeCharts, updateTreeCharts } =
    useContext(ReportContext);
  const posthogEvent = usePosthog();
  const chart: OrgChart = treeCharts?.[CDTId];
  const deselectNode = () => setActiveNode(null);

  useEffect(() => {
    activeNode &&
      renderSidePanelContent(
        <CDTNodeInfo
          key={activeNode.id}
          node={activeNode}
          cdtData={cdtData}
          assortmentData={assortmentData}
          isAuthor={user?.id === reportConfig.user_id}
          updateAssortmentData={updateAssortmentData}
          updateCDTRootName={updateCDTRootName}
          toggleSidePanelExpand={toggleSidePanelExpand}
          deselectNode={deselectNode}
        />,
        null,
        true
      );
  }, [cdtData, activeNode, assortmentData]);

  const drawCDT = () => {
    chart
      ?.data(cdtData)
      .container('.cdt-container')
      .svgHeight(
        fullscreenVisual !== null
          ? window.innerHeight
          : window.innerHeight - 200
      )
      .nodeHeight(() => NODE_HEIGHT)
      .nodeWidth(() => NODE_WIDTH)
      .duration(100)
      .setActiveNodeCentered(false)
      .linkUpdate(function (this: Element) {
        d3.select<d3.BaseType, CDTNode>(this)
          .attr('stroke', '#1b71d5')
          .attr('stroke-width', (d: CDTNode) => Number(d.depth !== 3));
      })
      .onNodeClick(function (this: OrgChart, nodeId: string | number) {
        const node = this.allNodes.find((n: CDTNode) => n.id === nodeId);
        setActiveNode(node);
        posthogEvent(cdtNodeClick, {
          nodeId: node.data.id,
          children: node.children?.map((node) => node.data.id),
        });
      })
      .siblingsMargin(() => 200)
      .compact(false)
      .nodeUpdate(function (this: Element, node: CDTNode) {
        if (node.depth === 3) {
          const d3node = d3.select(this) as unknown as d3.Selection<
            Element,
            CDTNode,
            Element,
            CDTNode
          >;
          d3node
            .select('.node-foreign-object')
            .attr('x', () => node.width - 114)
            .attr('y', () => getNodeY(node));

          d3node
            .select('.node-rect')
            .attr('x', () => node.width - 114)
            .attr('y', () => getNodeY(node));

          const nodePath = d3node.select('path').node()
            ? d3node.select('path')
            : d3node.append('path');

          drawNodePath(
            nodePath as d3.Selection<Element, CDTNode, Element, CDTNode>
          );
        }
      })
      .nodeContent(function (d: CDTNode) {
        const active = activeNode && activeNode.id === d.id;
        return `<div style="background: ${
          active ? '#f0f5f8' : '#fff'
        }; border: ${active ? 2 : 1}px solid #1b71d5; height: 100%; font-size: 16px; border-radius: 8px;">
    <div style="${nodeHeaderStyle}">${d.data.name}</div>
    <div style="${nodeBodyStyle}">
    <div style="${skusStyle}">${d.data.skus}</div>
    <div style="${salesStyle}">${d.data.sales}</div>
    ${
      user?.show_extension_measure
        ? `<div style="${profitStyle}">${d.data.profit}</div>`
        : ''
    }
    </div>
    </div>`;
      })
      .layoutBindings({
        top: {
          ...chart?.getChartState().layoutBindings.top,
          nodeFlexSize: ({
            height,
            width,
            siblingsMargin,
            childrenMargin,
            node,
          }) => {
            return [
              node.depth === 3 ? 0 : width + siblingsMargin,
              height + childrenMargin,
            ];
          },
        },
      })
      .render()
      .expandAll();
  };

  useLayoutEffect(() => {
    if (assortmentData.length) {
      drawCDT();
    }
  }, [assortmentData, d3Container.current, cdtData, activeNode]);

  useEffect(() => {
    updateTreeCharts(CDTId, new OrgChart());
  }, []);

  useEffect(() => {
    if (chart) {
      chart.resetToDefault = () => {
        chart.expandAll();
        chart.fit();
      };

      chart.expandToLevel = (level: number) => {
        const { allNodes } = chart.getChartState();
        allNodes?.forEach((d) => {
          d.data._expanded = d.data.level === level;
        });
        chart.render();
      };
    }
  }, [chart, assortmentData, cdtData]);

  const handleChartExpand = (level: number) => {
    chart.expandToLevel(level);
    posthogEvent(treeChartExpandLevelClick, {
      expandLevel: level,
      reportType: reportConfig.url_route,
    });
  };
  return (
    <div
      className={`cdt-container fullscreen-${fullscreenVisual === CDTId}`}
      data-testid="cdt-container"
      ref={(el) => {
        if (visualRefs?.current && el) {
          visualRefs.current[`${VISUAL_CONTAINER_KEY}-${CDTId}`] = el;
        }
      }}
    >
      <CDTLegend />
      {chart && (
        <TreeChartControls
          treeId={CDTId}
          treeChart={chart}
          extras={[
            <Button
              kind="ghost"
              key="expand_1"
              renderIcon={() => <Number_1 size="18" />}
              hasIconOnly
              iconDescription="Expand to level 1"
              onClick={() => handleChartExpand(1)}
              tooltipPosition="bottom"
            />,
            <Button
              kind="ghost"
              key="expand_2"
              renderIcon={() => <Number_2 size="18" />}
              hasIconOnly
              iconDescription="Expand to level 2"
              onClick={() => handleChartExpand(2)}
              tooltipPosition="bottom"
            />,
            <Button
              kind="ghost"
              key="expand_3"
              iconDescription="Expand to level 3"
              renderIcon={() => <Number_3 size="18" />}
              hasIconOnly
              onClick={() => handleChartExpand(3)}
              tooltipPosition="bottom"
            />,
          ]}
        />
      )}
      <div ref={d3Container} />
    </div>
  );
};

const CDTVisual = () => {
  const {
    updateVisualsData,
    reportConfig: {
      configuration: { visuals },
      parameters: { template_id: templateId },
    },
  } = useContext(ReportContext);

  const reportTemplateIndex = 0;
  const reportTemplateId = `${templateId}_${reportTemplateIndex}`;

  const { id, apiUrl } =
    visuals[reportTemplateId].find(
      ({ type }) => type === VisualType.CDT_GRID
    ) ?? {};

  return (
    <Fetch
      cacheKey={`${id}-/reports${apiUrl}` as DynamicCacheKey}
      initialData={null}
      loadingMessage="Loading report config..."
      apiUrl={`/reports${apiUrl}`}
      alwaysFetchOnMount={false}
      requestCanBeAborted
      onReceiveData={(response) => {
        if (response && typeof id === 'number') {
          updateVisualsData(id, response);
        }
      }}
    >
      <CDT />
    </Fetch>
  );
};

export default CDTVisual;
