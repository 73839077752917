import {
  Button,
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableHeader,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
  TableCell,
  TableRow,
  TextInput,
} from '@carbon/react';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import type {
  AssortmentData,
  CDTNode,
  CDTNodeData,
} from '../../../reducers/AssortmentReducer';
import '../../../styles/components/assortment.scss';
import {
  getCdKey,
  reorderAssortmentData,
  updateCDForIds,
} from '../../../utils/CDTUtils';
import { formatNumber } from '../../../utils/reportUtils';
import CDTReorder from './CDTReorder';
import {
  cdtExpandMetricsRowClick,
  cdtRenameGroupInputClick,
} from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
interface NodeInfoProps {
  node: CDTNode;
  cdtData: CDTNodeData[];
  assortmentData: AssortmentData[];
  isAuthor: boolean;
  updateAssortmentData: (data: AssortmentData[]) => void;
  updateCDTRootName: (cdtRootName: string) => void;
  toggleSidePanelExpand: (expand: boolean) => void;
  deselectNode: () => void;
}

export const assortmentInfoHeaders = [
  { key: 'PRODUCT_ID', header: 'Surrogate ID' },
  { key: 'PRODUCT_SKU', header: 'Product/Sku' },
  { key: 'PRODUCT', header: 'Product information' },
];

const CDTNodeInfo: FunctionComponent<NodeInfoProps> = ({
  node,
  cdtData,
  assortmentData,
  isAuthor,
  updateAssortmentData,
  updateCDTRootName,
  toggleSidePanelExpand,
  deselectNode,
}) => {
  const [name, setName] = useState<string>(
    cdtData.find((n) => n.id === node.id)?.name ?? ''
  );
  const posthogEvent = usePosthog();
  const siblingNodes = cdtData.filter(
    (n) => n.parentNodeId === node.data.parentNodeId
  );

  const cdKey = getCdKey(node.depth);

  const filteredData =
    node.id === 'ROOT'
      ? assortmentData
      : assortmentData.filter((row) => cdKey && row[cdKey].id === node.id);

  const updateData = () => {
    if (node.id === 'ROOT') {
      updateCDTRootName(name);
    } else {
      const filteredIds = filteredData.map((row) => row.PRODUCT_ID);

      if (cdKey) {
        const newAssortmentData = updateCDForIds(
          filteredIds,
          cdKey,
          name,
          assortmentData
        );

        updateAssortmentData(newAssortmentData);
      }
    }
    deselectNode();
    toggleSidePanelExpand(false);
  };

  const updateOrder = (newOrder: CDTNodeData[]) => {
    if (!cdKey) {
      return;
    }
    const ids = newOrder.map((n) => n.id);

    const newAssortmentData = reorderAssortmentData(assortmentData, cdKey, ids);

    updateAssortmentData(newAssortmentData);
  };

  return (
    <div>
      <TextInput
        id="cdt-node-group-name-input"
        className="assortment-group-name-input"
        data-testid="group-name-input"
        type="text"
        invalid={name?.trim().length === 0}
        labelText="Group name"
        value={name}
        placeholder="Group name"
        onChange={(e) => setName(e.target.value)}
        disabled={!isAuthor}
        onClick={() => {
          posthogEvent(cdtRenameGroupInputClick);
        }}
      />

      {node.id !== 'ROOT' && (
        <>
          <div className="node-info-title">Order</div>
          <CDTReorder
            nodeId={node.id}
            cdtData={siblingNodes}
            isAuthor={isAuthor}
            updateOrder={updateOrder}
          />
        </>
      )}
      <div className="node-info-title">Metrics</div>
      <DataTable headers={assortmentInfoHeaders} rows={filteredData}>
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getTableContainerProps,
          getRowProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader
                    onClick={() => {
                      posthogEvent(cdtExpandMetricsRowClick);
                    }}
                  />
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      style={
                        header.key === 'PRODUCT_ID' ? { display: 'none' } : {}
                      }
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const allInfo = filteredData.find(
                    (r) => r.PRODUCT_ID === row.cells[0].value
                  );
                  return (
                    <Fragment key={row.id}>
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          return (
                            <TableCell
                              key={cell.id}
                              style={
                                cell.id.match('PRODUCT_ID')
                                  ? { display: 'none' }
                                  : {}
                              }
                            >
                              {cell.value}
                            </TableCell>
                          );
                        })}
                      </TableExpandRow>
                      {allInfo && (
                        <TableExpandedRow colSpan={headers.length + 3}>
                          <div className="assortment-node-metrics">
                            <div>
                              <strong>Sales</strong>
                            </div>
                            <div>{formatNumber(allInfo.spend)}</div>
                            <div>
                              <strong>Units</strong>
                            </div>
                            <div>{formatNumber(allInfo.units)}</div>
                          </div>
                        </TableExpandedRow>
                      )}
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      {isAuthor && (
        <div className="assortment-side-panel-controls">
          <Button
            data-testid="update-assortment-button"
            disabled={name.replace(/ /g, '').length === 0}
            onClick={() => {
              updateData();
            }}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};

export default CDTNodeInfo;
