import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer } from 'react';
import posthog from 'posthog-js';
import type { FeatureFlag, PosthogState } from '../reducers/PosthogReducer';
import posthogReducer, {
  initialPosthogState,
} from '../reducers/PosthogReducer';
import '../styles/components/posthogProvider.scss';
export interface PosthogContextType extends PosthogState {
  updateFeatureFlags: (featureFlags: FeatureFlag[]) => void;
}

export const PosthogContext = createContext<PosthogContextType>(
  {} as PosthogContextType
);

interface PosthogProviderProps {
  children?: ReactNode;
}

const PosthogProvider: FunctionComponent<PosthogProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(posthogReducer, initialPosthogState);
  const updateFeatureFlags = (featureFlags: FeatureFlag[]) =>
    dispatch({ type: 'UPDATE_FEATURE_FLAGS', featureFlags });

  useEffect(() => {
    posthog.onFeatureFlags((flags) => {
      const payload = flags.map((flag) => {
        const flagPayload = posthog.getFeatureFlagPayload(flag);
        return {
          name: flag,
          ...(flagPayload && { payload: flagPayload }),
        };
      });
      updateFeatureFlags(payload as FeatureFlag[]);
    });
  }, []);

  const posthogContext: PosthogContextType = useMemo(
    () => ({
      featureFlags: state.featureFlags,
      updateFeatureFlags,
    }),
    [state]
  );

  return (
    <PosthogContext.Provider value={posthogContext}>
      {children}
    </PosthogContext.Provider>
  );
};

export default PosthogProvider;
