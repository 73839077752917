import type { CarbonIconType } from '@carbon/icons-react';
import { Download } from '@carbon/icons-react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { handleExport } from '../../utils/reportUtils';
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import Tooltip from '../Tooltip';
import { AppContext } from '../../providers/AppProvider';
import { ReportContext } from '../../providers/ReportProvider';

interface ExportOverflowProps {
  className?: string;
  icon?: CarbonIconType;
  elements: HTMLDivElement[] | null;
  report_name: string;
  alignTooltip?: 'top' | 'left' | 'bottom' | 'right';
  size?: 'sm' | 'md' | 'lg';
  tooltipText?: string;
  disabled?: boolean;
}
const ExportOverflow: FunctionComponent<ExportOverflowProps> = ({
  className,
  icon,
  elements,
  report_name,
  alignTooltip = 'bottom',
  size = 'md',
  tooltipText = 'Download chart',
  disabled,
}) => {
  const { user, groupId } = useContext(AppContext);
  const {
    reportConfig: { url_route },
  } = useContext(ReportContext);

  if (!elements) {
    return null;
  }

  return (
    <Tooltip label={tooltipText} align={alignTooltip}>
      <OverflowMenu
        ariaLabel={tooltipText}
        size={size}
        className={className ? className : undefined}
        renderIcon={icon ? icon : Download}
        data-testid="download-chart"
        iconDescription={tooltipText}
        disabled={disabled}
        flipped
        data-floating-menu-container
        focusTrap={false}
      >
        <OverflowMenuItem
          itemText="As .png"
          onClick={() => {
            if (user && groupId) {
              handleExport(
                elements,
                report_name,
                'png',
                user.id,
                groupId,
                url_route
              );
            }
          }}
        />
        <OverflowMenuItem
          itemText="As .svg"
          onClick={() => {
            if (user && groupId) {
              handleExport(
                elements,
                report_name,
                'svg',
                user?.id,
                groupId,
                url_route
              );
            }
          }}
        />
      </OverflowMenu>
    </Tooltip>
  );
};

export default ExportOverflow;
